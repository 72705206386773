/* eslint-disable */
import React, { useState, useEffect, FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Modal, Button } from 'react-bootstrap';
import { OrgaoEmissorType } from '../../api/model/OrgaoEmissorType';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { MunicipiosTypes } from '../../api/Types/municipiosTypes';
import checaCPF from '../../util/checaCpf';
import { cpfMask, validaNome, validaRG } from '../../util/Validacoes/Validacoes';
import { maskTel, maskCep, soLetras } from '../../util/Validacoes/Validacoes';

import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import Upload from '../../components/Upload';
import FileList from '../../components/FileList';
import { Content } from './styles';
import { FileProvider } from '../../context/files';
import {
  dataMaxParaMaioridadeFormatada,
  dataMinParaDataNascimentoFormatada,
  dataMinParaMaioridade,
  dataAtualFormatoAnoMesDia,
  dataConverter,
  toZoned,
} from '../../util/date';
import { AjudanteType } from '../../api/model/AjudanteType';
import { useToast } from '../../hooks/Toast';
import { nowToZoned } from './../../util/date';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  loadDadosAjudanteIdNome: () => void;
};

const ModalCadastroAjudantePorSM: FC<Props> = ({ show, setShow, loadDadosAjudanteIdNome }) => {
  const history = useHistory();
  const { fromSMCadastro }: any = useParams();
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const { addToast } = useToast();

  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [rg, setRg] = useState('');
  const [statusApto, setStatusApto] = useState(false);
  const [dataEmissao, setDataEmissao] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState<number>();
  const [logradouroComplemento, setLogradouroComplemento] = useState('');
  const [cidade, setCidade] = useState(0);
  const [dataNascimento, setDataNascimento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidadeNascimento, setCidadeNascimento] = useState(0);
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [referenciaNome, setReferenciaNome] = useState('');
  const [referenciaTelefone, setReferenciaTelefone] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nextelContato, setNextelContato] = useState('');
  const [grauParentesco, setGrauParentesco] = useState('');
  const [atualizarPesquisa, setAtualizarPesquisa] = useState(false);
  const [ativo, setAtivo] = useState(true);
  const [tipo, setTipo] = useState('');
  const [sexo, setSexo] = useState('');
  const [orgaosEmissores, setOrgaosEmissores] = useState<number | undefined>();
  const [idUfEmissor, setIdUfEmissor] = useState<number>();
  const [idUfEndereco, setIdUfEndereco] = useState<number | undefined>();
  const [idUfNascimento, setIdUfNascimento] = useState<number>();
  const [dataCadastro, setDataCadastro] = useState('');
  const [foto, setFoto] = useState('');

  const [isValid, setIsValid] = useState(false);
  const [isNomeValid, setIsNomeValid] = useState(false);
  const [isNomeMaeValid, setIsNomeMaeValid] = useState(false);
  const [isNomePaiValid, setIsNomePaiValid] = useState(false);
  const [error, setError] = useState(false);

  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [erroDataEmissaoRg, setErroDataEmissaoRg] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [isGrauParentValid, setIsGrauParentValid] = useState(true);
  const [municipioFilter, setMunicipioFilter] = useState<MunicipiosType[] | null>(null);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const [isDialogVisibleRecuperaDados, setIsDialogVisibleRecuperaDados] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [isDialogVisibleCpfBlacklist, setIsDialogVisibleCpfBlacklist] = useState(false);
  const [dadosRecuperados, setDadosRecuperados] = useState(false);

  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [cidadesNascimentoPorEstado, setCidadesNascimentoPorEstado] = useState<MunicipiosTypes[]>(
    []
  );
  const [ufSiglas, setUfSiglas] = useState<UfIbgeType[]>([]);

  const [wasCadastred, setWasCadastred] = useState(false);
  //lista de orgaos emissores vinda do banco de dados
  const [orgaoEmissor, setOrgaoEmissor] = useState<OrgaoEmissorType[]>([]);
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [nomeUfEmissor, setNomeUfEmissor] = useState('');
  const [nomeUfEndereco, setNomeUfEndereco] = useState('');
  const [nomeUfNascimento, setNomeUfNascimento] = useState('');
  const [qtdeViagens, setQtdeViagens] = useState(0);
  const [tempoCadastro, setTempoCadastro] = useState('');
  const [valorMedioTransportado, setValorMedioTransportado] = useState(0);
  const [bloqueado, setBloqueado] = useState(false);
  const [validacaoCpf, setValidacaoCpf] = useState(false);
  const [validacaoRg, setValidacaoRg] = useState(false);
  const [cpfErro, setCpfErro] = useState(false);
  const [cpfInativo, setCpfInativo] = useState(false);

  const [dataEmissaoMudou, setDataEmissaoMudou] = useState(true);
  const [dataNascimentoMudou, setDataNascimentoMudou] = useState(true);

  const [isRgFocused, setIsRgFocused] = useState(false);

  const [model, setModel] = useState<AjudanteType>({
    id: 0,
    cpf: '',
    nome: '',
    foto: '',
    tipo: '',
    sexo: '',
    rg: '',
    cep: '',
    logradouro: '',
    logradouroNumero: 0,
    logradouroComplemento: '',
    bairro: '',
    cidade: '',
    nomeCidade: '',
    dataNascimento: `${dataNascimento}T00:00:00`,
    cidadeNascimento: '',
    nomeCidadeNascimento: '',
    nomePai: '',
    nomeMae: '',
    nextelContato: '',
    telefone: '',
    referenciaTelefone: '',
    referenciaNome: '',
    grauParentesco: '',
    ativo: false,
    idUfEndereco: '',
    nomeUfEndereco: '',
    idOrgaoEmissor: 0,
    idUfEmissor: 0,
    nomeUfEmissor: '',
    idUfNascimento: 0,
    nomeUfNascimento: '',
    valorMedioTransportado: 0,
    tempoCadastro: '',
    qtdeViagens: '',
    dataEmissao: `${dataEmissao}T00:00:00`,
    siglaIbge: '',
    clienteId: { id: clienteId },
    atualizarPesquisa: false,
  });

  function updateModel(response: any) {
    setModel({
      id: response.id,
      cpf: response.cpf,
      nome: response.nome,
      foto: response.foto,
      tipo: response.tipo,
      sexo: response.sexo,
      rg: response.rg,
      cep: response.cep,
      logradouro: response.logradouro,
      logradouroNumero: response.logradouroNumero,
      logradouroComplemento: response.logradouroComplemento,
      bairro: response.bairro,
      cidade: response.cidade,
      nomeCidade: response.nomeCidade,
      dataNascimento: response.dataNascimento,
      cidadeNascimento: response.cidadeNascimento,
      nomeCidadeNascimento: response.nomeCidadeNascimento,
      nomePai: response.nomePai,
      nomeMae: response.nomeMae,
      nextelContato: response.nextelContato,
      telefone: response.telefone,
      referenciaTelefone: response.referenciaTelefone,
      referenciaNome: response.referenciaNome,
      grauParentesco: response.grauParentesco,
      ativo: response.ativo,
      idUfEndereco: response.idUfEndereco,
      nomeUfEndereco: response.nomeUfEndereco,
      idOrgaoEmissor: response.idOrgaoEmissor,
      idUfEmissor: response.idUfEmissor,
      nomeUfEmissor: response.nomeUfEmissor,
      idUfNascimento: response.idUfNascimento,
      nomeUfNascimento: response.nomeUfNascimento,
      valorMedioTransportado: response.valorMedioTransportado,
      tempoCadastro: response.tempoCadastro,
      qtdeViagens: response.qtdeViagens,
      dataEmissao: response.dataEmissao,
      siglaIbge: '',
      clienteId: { id: clienteId },
      atualizarPesquisa: response.atualizarPesquisa,
    });
  }

  useEffect(() => {
    const recuperarDadosPeloCpf = async () => {
      const response = await axios.get(
        `/ajudante-funcionario/reutilizarAjudantePorCpf?clienteId=${clienteId}&cpf=${cpf}`
      );
      if (response.data.cpf) {
        setIsDialogVisibleRecuperaDados(true);
        updateModel(response.data);
      } else {
        setIsDialogVisibleRecuperaDados(false);
      }
    };
    if (show) {
      recuperarDadosPeloCpf();
    }
  }, [cpf]); //Recuperar Dados Pelo CPF

  useEffect(() => {
    const recuperaDadosPeloRg = async () => {
      const response2 = await axios.get(
        `/ajudante-funcionario/reutilizarAjudantePorRg?clienteId=${clienteId}&rg=${rg}`
      );

      if (response2.data.rg) {
        setIsDialogVisibleRecuperaDados(true);
        updateModel(response2.data);
      } else {
        setIsDialogVisibleRecuperaDados(false);
      }
    };
    if (show) {
      recuperaDadosPeloRg();
    }
  }, [rg]); //Recuperar Dados Pelo RG

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/orgao-emissor/getIdNome');
      setOrgaoEmissor(response.data);
    };
    if (show) {
      fetchData();
    }
  }, [show]); //setOrgaoEmissor

  const verificaCpfBlackList = async () => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });

      if (response.data) {
        setCpfErro(true);
      } else {
        setCpfErro(false);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `/ajudante-funcionario/verificarCpfAtivo/${clienteId}?keyword=${cpf}`
      );
      if (response.data === false) {
        setCpfInativo(true);
      } else {
        setCpfInativo(false);
      }
    };
    if (show) {
      fetchData();
    }
  }, [cpf]); //Verifica CPF Ativo

  useEffect(() => {
    if (cpfInativo) {
      return;
    }
    if (cpf.length === 14) {
      const validaCpfExistente = async () => {
        const data = {
          cpfAjudante: cpf,
        };
        console.log(cpf);
        try {
          const response = await axios.post(`/ajudante-funcionario/existeCPF/${clienteId}`, data);
          setValidacaoCpf(response.data);
          if (response.data === true) {
            // const alerta = alert('O CPF deste ajudante já está cadastrado');
            addToast({
              title: 'Erro!',
              description: 'O CPF deste ajudante já está cadastrado.',
              type: 'error',
            });
            return;
          } else {
            setCpf(cpf);
          }
        } catch (err: any) {
          console.log(err);
        }
      };
      if (show) {
        validaCpfExistente();
      }
    }
  }, [cpf]); //Valida CPF Existente

  const validaRgExistente = async () => {
    const data = {
      rgAjudante: rg,
    };
    try {
      const response = await axios.post(`/ajudante-funcionario/existeRG/${clienteId}`, data);
      setValidacaoRg(response.data);
      if (response.data === true) {
        // const alerta = alert('O RG deste ajudante já está cadastrado');
        addToast({
          title: 'Erro!',
          description: 'O RG deste ajudante já está cadastrado.',
          type: 'error',
        });
        return;
      } else {
        setRg(rg);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const buscarCep = () => {
      if (cep.length < 9) {
        setCidade(0);
        setLogradouro('');
        setBairro('');
        return;
      }
      setError(false);
      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setCidade(parseInt(data.ibge));
          setViaCepCidade(data.localidade);
          setIdUfEndereco(ufSiglas.find((each) => each.sigla === data.uf)?.id);
          loadMunicipiosPorEstado(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
          //setCep(data.cep)
        })
        .catch((erro) => {
          console.log('error', erro);
          setError(true);
        });
    };
    if (show) {
      buscarCep();
    }
  }, [cep]); //buscarCep

  useEffect(() => {
    if (show) {
      axios.get('/uf/ListarIdSiglaEstadoPais/1').then((response: any) => {
        setUfSiglas(response.data);
      });
    }
  }, [show]); //setUfSiglas

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadCidadesNascimentoPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setCidadesNascimentoPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const data = new Date();

    const day = data.getDate();
    const month = data.getMonth() + 1;
    const year = data.getFullYear();

    const hour = data.getHours();
    const minute = data.getMinutes();
    const second = data.getSeconds();

    setDataCadastro(`${day}/${month}/${year} ${hour}:${minute}:${second}`);
  }, []); //setDataCadastro

  const handleRegister = async (event: any) => {
    event.preventDefault();
    if (dadosRecuperados === false) {
      if (
        !cpf ||
        !nome ||
        !sexo ||
        !rg ||
        // !orgaosEmissores ||
        !idUfEmissor ||
        !cep ||
        !logradouro ||
        !bairro ||
        !cidade ||
        logradouroNumero === null ||
        !idUfEndereco ||
        !dataNascimento ||
        !idUfNascimento ||
        !cidadeNascimento ||
        !nomePai ||
        !nomeMae ||
        !isValid ||
        !isNomePaiValid ||
        !isNomeValid ||
        !isNomeMaeValid ||
        !isGrauParentValid ||
        erroDataNascimento
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios.',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    } else {
      if (
        !model.cpf ||
        !model.nome ||
        !model.sexo ||
        !model.rg ||
        !model.idUfEmissor ||
        !model.cep ||
        !model.logradouro ||
        !model.bairro ||
        !model.cidade ||
        model.logradouroNumero === null ||
        !model.idUfEndereco ||
        !model.dataNascimento ||
        !model.idUfNascimento ||
        !model.cidadeNascimento ||
        !model.nomePai ||
        !model.nomeMae
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    if (validacaoRg === true) {
      addToast({
        title: 'Erro',
        description: 'O RG desse ajudante já existe!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    const data = {
      cpf: cpf ? cpf : model.cpf,
      nome: nome ? nome : model.nome,
      tipo: tipo ? tipo : model.tipo,
      sexo: sexo ? sexo : model.sexo,
      rg: rg ? rg : model.rg,
      idOrgaoEmissor:
        model.idOrgaoEmissor === 0
          ? model.idOrgaoEmissor.toString().replace('0', '')
          : orgaosEmissores
          ? orgaosEmissores
          : model.idOrgaoEmissor,
      idUfEmissor: idUfEmissor ? idUfEmissor : model.idUfEmissor,
      dataEmissao: dataEmissaoMudou ? toZoned(dataEmissao) : toZoned(model.dataEmissao),
      cep: cep ? cep : model.cep,
      logradouro: logradouro ? logradouro : model.logradouro,
      logradouroNumero: logradouroNumero ? logradouroNumero : model.logradouroNumero,
      logradouroComplemento: logradouroComplemento
        ? logradouroComplemento
        : model.logradouroComplemento,
      cidade: cidade ? cidade : model.cidade,
      idUfEndereco: idUfEndereco ? idUfEndereco : model.idUfEndereco,
      dataNascimento: dataNascimentoMudou ? toZoned(dataNascimento) : toZoned(model.dataNascimento),
      bairro: bairro ? bairro : model.bairro,
      cidadeNascimento: cidadeNascimento ? cidadeNascimento : model.cidadeNascimento,
      nomeMae: nomeMae ? nomeMae : model.nomeMae,
      nomePai: nomePai ? nomePai : model.nomePai,
      referenciaNome: referenciaNome ? referenciaNome : model.referenciaNome,
      referenciaTelefone: referenciaTelefone
        ? referenciaTelefone.replace('(', '').replace(')', '').replace('-', '')
        : model.referenciaTelefone
        ? model.referenciaTelefone
        : '',
      telefone: telefone
        ? telefone.replace('(', '').replace(')', '').replace('-', '')
        : model.telefone,
      nextelContato: nextelContato
        ? nextelContato.replace('(', '').replace(')', '').replace('-', '')
        : model.nextelContato
        ? model.nextelContato
        : '',
      grauParentesco: grauParentesco ? grauParentesco : model.grauParentesco,
      atualizarPesquisa: atualizarPesquisa ? atualizarPesquisa : model.atualizarPesquisa,
      ativo: dadosRecuperados ? true : ativo,
      idUfNascimento: idUfNascimento ? idUfNascimento : model.idUfNascimento,
      foto: foto ? foto : model.foto,
      idCliente: clienteId,
      //CRIAR CAMPOS NO FORM PARA PREENCHER ESSES PARAMETROS
      bloqueado: bloqueado,
      dataCadastro: nowToZoned(),
      dataUltimaAlteracao: nowToZoned(),
      idSM: null,
      nomeUfEmissor: nomeUfEmissor ? nomeUfEmissor : model.nomeUfEmissor,
      nomeUfEndereco: nomeUfEndereco ? nomeUfEndereco : model.nomeUfEndereco,
      nomeUfNascimento: nomeUfNascimento ? nomeUfNascimento : model.nomeUfNascimento,
      qtdeViagens: qtdeViagens ? qtdeViagens : model.qtdeViagens,
      tempoCadastro: tempoCadastro ? tempoCadastro : model.tempoCadastro,
      valorMedioTransportado: valorMedioTransportado
        ? valorMedioTransportado
        : model.valorMedioTransportado,
    };

    try {
      if (dadosRecuperados) {
        await axios.put(`/ajudante-funcionario/atualizar/${model.id}`, data);
        setIsDialogVisibleCadEfetuado(true);
        setTimeout(() => {
          setShow(false);
          limparFormulario();
          loadDadosAjudanteIdNome();
          setIsDialogVisibleCadEfetuado(false);
          setIsFormInvalid(false);
        }, 1000);
      } else {
        await axios.post('/ajudante-funcionario', data);
        setIsDialogVisibleCadEfetuado(true);
        setTimeout(() => {
          setShow(false);
          limparFormulario();
          loadDadosAjudanteIdNome();
          setIsDialogVisibleCadEfetuado(false);
          setIsFormInvalid(false);
        }, 1000);
      }
      setWasCadastred(true);
    } catch (err: any) {
      console.log(err.response.status);
      if (err.response.status === 409 && cpfInativo === false) {
        // alert('Ajudante já cadastrado.');
        addToast({
          title: 'Erro!',
          description: 'Ajudante já cadastrado.',
          type: 'error',
        });
        return;
      }
      if (err.response.data.message === 'O CPF informado está cadastrado na BlackList') {
        setIsDialogVisibleCpfBlacklist(true);
        return;
      } else {
        // alert('Erro ao cadastrar');
        addToast({
          title: 'Erro!',
          description: 'Erro ao cadastrar.',
          type: 'error',
        });
        return;
      }
    }
  };

  const limparFormulario = () => {
    setCpf('');
    setNome('');
    setTipo('');
    setSexo('');
    setRg('');

    setIdUfEmissor(0);
    setDataEmissao('');
    setCep('');
    setLogradouro('');
    setLogradouroNumero(0);
    setLogradouroComplemento('');

    setCidade(0);
    setIdUfEndereco(0);
    setDataNascimento('');
    setBairro('');
    setCidadeNascimento(0);
    setNomeMae('');
    setNomePai('');
    setReferenciaNome('');
    setReferenciaTelefone('');

    setTelefone('');

    setNextelContato('');

    setGrauParentesco('');
    setAtualizarPesquisa(false);
    setAtivo(true);
    setIdUfNascimento(0);
    setFoto('');

    setBloqueado(false);
    setDataCadastro('');
    setNomeUfEmissor('');
    setNomeUfEndereco('');
    setNomeUfNascimento('');
    setQtdeViagens(0);
    setTempoCadastro('');
    setValorMedioTransportado(0);
  };

  const validaDataNascimento = (value: any) => {
    const nasc = new Date(value);
    if (nasc.getTime() < dataMinParaMaioridade().getTime()) {
      setDataNascimento(value);
      setErroDataNascimento(false);
    } else {
      setDataNascimento(value);
      setErroDataNascimento(true);
    }
  };

  const validaDataMin = (value: any) => {
    const dataAtual = new Date();
    if (dataAtual.getTime() <= new Date(value).getTime()) {
      setDataEmissao(value);
      setErroDataEmissaoRg(true);
    } else {
      setDataEmissao(value);
      setErroDataEmissaoRg(false);
    }
  };

  function handleRgFocus() {
    setIsRgFocused(true);
  }

  function handleRgBlur() {
    setIsRgFocused(false);
    validaRgExistente();
  }

  useEffect(() => {
    if (!idUfNascimento || !municipios) {
      return;
    }

    const estado = ufs.filter((each) => each.id == idUfNascimento);

    const municipio = municipios.filter((each) => each.idCodigoIbge === estado[0].codigoIbge);
    setMunicipioFilter(municipio);
  }, [idUfNascimento, municipios]); //setMunicipioFilter

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cadastrar Ajudante</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <Dialog
            header="Ajudante foi cadastrado com sucesso!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadEfetuado(false)}>Ok</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />

          <Dialog
            header="Favor entrar em contato com a Golden Service (21) 3644-7000."
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCpfBlacklist(false)}>Ok</Button>
              </>
            }
            visible={isDialogVisibleCpfBlacklist}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCpfBlacklist(false)}
          />

          <Form noValidate validated={isFormInvalid} onSubmit={handleRegister} className="form-sm">
            <Form.Group>
              <Form.Check
                checked={atualizarPesquisa}
                onChange={(event) => {
                  setAtualizarPesquisa(!atualizarPesquisa);
                }}
                name="atualizarPesquisa"
                type="switch"
                id="custom-switch1"
                label="Atualizar Pesquisa automaticamente"
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Data de cadastro</Form.Label>
                <Form.Control value={dataCadastro} id="dataCadastro" name="dataCadastro" disabled />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">CPF</Form.Label>
                <Form.Control
                  value={cpf ? cpf : dadosRecuperados ? model.cpf : cpf}
                  onChange={(event: any) => {
                    if (checaCPF(event.target.value) === false) {
                      setCpf(cpfMask(event.target.value));
                      setIsValid(false);
                    } else {
                      setCpf(cpfMask(event.target.value));
                      setIsValid(true);
                    }

                    if (dadosRecuperados) {
                      setModel({ ...model, cpf: event.target.value });
                    }
                  }}
                  name="cpf"
                  required
                  title="Cpf inválido"
                  maxLength={14}
                  minLength={14}
                  onBlur={verificaCpfBlackList}
                />
                {cpfErro && (
                  <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
                    Favor entrar em contato com a Golden Service (21) 3644-7000.
                  </p>
                )}
                {!isValid && cpf.length > 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome</Form.Label>
                <Form.Control
                  value={nome ? nome : dadosRecuperados ? model.nome : nome}
                  onChange={(event) => {
                    event.preventDefault();
                    setNome(soLetras(event.target.value));
                    if (validaNome(soLetras(event.target.value))) {
                      setIsNomeValid(true);
                    } else {
                      setIsNomeValid(false);
                    }
                  }}
                  name="nome"
                  maxLength={256}
                  required
                  type="text"
                  title="Preencha com dois nomes"
                />
                {!isNomeValid && nome.length > 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Nome inválido, inserir nome e sobrenome.
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Tipo</Form.Label>
                <Form.Control
                  value={tipo ? tipo : dadosRecuperados ? model.tipo : tipo}
                  onChange={(event: any) => {
                    setTipo(event.target.value);
                  }}
                  id="tipo"
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">Agregado</option>
                  <option value="2">Autônomo</option>
                  <option value="3">Funcionário</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Sexo</Form.Label>
                <Form.Control
                  value={sexo ? sexo : dadosRecuperados ? model.sexo : sexo}
                  onChange={(event: any) => {
                    setSexo(event.target.value);
                  }}
                  id="sexo"
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="F">Feminino</option>
                  <option value="M">Masculino</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">RG</Form.Label>
                <Form.Control
                  value={rg ? rg : dadosRecuperados ? model.rg : rg}
                  onChange={(event) => {
                    setRg(validaRG(event.target.value));

                    if (dadosRecuperados) {
                      setModel({ ...model, rg: event.target.value });
                    }
                  }}
                  id="rg"
                  name="rg"
                  type="text"
                  required
                  maxLength={15}
                  onFocus={handleRgFocus}
                  onBlur={handleRgBlur}
                />
                {isRgFocused && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Inserir apenas letras e números.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Orgão Emissor</Form.Label>
                <Form.Control
                  value={
                    orgaosEmissores
                      ? orgaosEmissores
                      : dadosRecuperados
                      ? model.idOrgaoEmissor
                      : orgaosEmissores
                  }
                  onChange={(event: any) => {
                    setOrgaosEmissores(parseInt(event.target.value));
                  }}
                  as="select"
                >
                  <option>Selecione uma opção</option>
                  {orgaoEmissor.map((oE, index) => (
                    <option value={oE.id} key={index}>
                      {oE.nome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">UF Emissor</Form.Label>
                <Form.Control
                  value={
                    idUfEmissor ? idUfEmissor : dadosRecuperados ? model.idUfEmissor : idUfEmissor
                  }
                  onChange={(event: any) => {
                    setIdUfEmissor(parseInt(event.target.value));
                  }}
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {ufSiglas.map((uf, index) => (
                    <option value={uf.id} key={index}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Data Emissão</Form.Label>
                <Form.Control
                  onChange={(event: any) => {
                    setDataEmissaoMudou(true);
                    validaDataMin(event.target.value);
                  }}
                  id="dataEmissao"
                  name="dataEmissao"
                  type="date"
                  min={dataMinParaDataNascimentoFormatada()}
                  max={dataAtualFormatoAnoMesDia()}
                  value={
                    dataEmissaoMudou
                      ? dataEmissao
                      : dadosRecuperados && model.dataEmissao
                      ? model.dataEmissao.split('T')[0]
                      : dataEmissao
                  }
                />
                {erroDataEmissaoRg && (
                  <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                    Data de Emissão não pode ser maior do que a data atual
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Content>
              <FileProvider foto={foto} setFoto={setFoto} name={cpf}>
                <Upload />
                <FileList />
              </FileProvider>
            </Content>

            <Form.Row>
              <Form.Group as={Col} md={6} className=" ml-2">
                <Form.Label>Status</Form.Label>
                <Form.Check
                  checked={statusApto}
                  onChange={() => {
                    setStatusApto(!statusApto);
                  }}
                  name="statusApto"
                  type="switch"
                  id="custom-switch2"
                  label="Apto para viagem"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
                <hr />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="requiredField">CEP</Form.Label>
                <Form.Control
                  value={cep ? cep : dadosRecuperados ? model.cep : cep}
                  onChange={(event) => {
                    setCep(maskCep(event.target.value));
                  }}
                  name="cep"
                  type="text"
                  required
                  maxLength={9}
                  minLength={9}
                  style={{ width: '40%' }}
                />
                {error && <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">UF</Form.Label>
                <Form.Control
                  required
                  value={
                    idUfEndereco
                      ? idUfEndereco
                      : dadosRecuperados
                      ? model.idUfEndereco
                      : idUfEndereco
                  }
                  onChange={(event: any) => {
                    setIdUfEndereco(parseInt(event.target.value));
                    loadMunicipiosPorEstado(parseInt(event.target.value));
                  }}
                  as="select"
                >
                  <option value="">Selecione uma opção</option>
                  {ufSiglas.map((uf, index) => (
                    <option value={uf.id} key={index}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Cidade</Form.Label>
                <Form.Control
                  value={cidade ? cidade : dadosRecuperados ? model.nomeCidade : cidade}
                  onChange={(event: any) => {
                    setCidade(event.target.value);
                  }}
                  name="cidade"
                  required
                  as="select"
                >
                  {model.nomeCidade && dadosRecuperados && (
                    <option value={model.cidade}>{model.nomeCidade}</option>
                  )}
                  <option value="">Selecione uma opção</option>
                  {municipiosPorEstado.map((filteredMunicipio, index) => (
                    <option key={index} value={filteredMunicipio.id}>
                      {filteredMunicipio.nomeMunicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Bairro</Form.Label>
                <Form.Control
                  value={bairro ? bairro : dadosRecuperados ? model.bairro : bairro}
                  onChange={(event) => {
                    setBairro(event.target.value);
                  }}
                  name="bairro"
                  type="text"
                  required
                  maxLength={56}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Logradouro</Form.Label>
                <Form.Control
                  value={logradouro ? logradouro : dadosRecuperados ? model.logradouro : logradouro}
                  onChange={(event) => {
                    setLogradouro(event.target.value);
                  }}
                  name="logradouro"
                  type="text"
                  required
                  maxLength={64}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Número Logradouro</Form.Label>
                <Form.Control
                  value={
                    logradouroNumero
                      ? logradouroNumero
                      : model.logradouroNumero === 0
                      ? ''
                      : dadosRecuperados
                      ? model.logradouroNumero
                      : logradouroNumero
                  }
                  onChange={(event: any) => {
                    setLogradouroNumero(parseInt(event.target.value));
                  }}
                  id="logradouroNumero"
                  name="logradouroNumero"
                  required
                  maxLength={8}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  value={
                    logradouroComplemento
                      ? logradouroComplemento
                      : dadosRecuperados
                      ? model.logradouroComplemento
                      : logradouroComplemento
                  }
                  onChange={(event) => {
                    setLogradouroComplemento(event.target.value);
                  }}
                  id="logradouroComplemento"
                  name="logradouroComplemento"
                  type="text"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
                <hr />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Data Nascimento</Form.Label>
                <br />
                <Form.Control
                  value={
                    dataNascimentoMudou
                      ? dataNascimento
                      : dadosRecuperados && model.dataNascimento
                      ? model.dataNascimento.split('T')[0]
                      : dataNascimento
                  }
                  onChange={(event: any) => {
                    setDataNascimentoMudou(true);
                    validaDataNascimento(event.target.value);
                  }}
                  id="dataNascimento"
                  name="dataNascimento"
                  type="date"
                  max={dataMaxParaMaioridadeFormatada()}
                  min={dataMinParaDataNascimentoFormatada()}
                  required
                  style={{ width: '20%' }}
                />
                {erroDataNascimento && (
                  <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                    Não é possivel cadastrar um ajudante com idade menor a 18 anos.
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">UF</Form.Label>
                <Form.Control
                  value={
                    idUfNascimento
                      ? idUfNascimento
                      : dadosRecuperados
                      ? model.idUfNascimento
                      : idUfNascimento
                  }
                  onChange={(event: any) => {
                    setIdUfNascimento(parseInt(event.target.value));
                    loadCidadesNascimentoPorEstado(parseInt(event.target.value));
                  }}
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {ufSiglas.map((uf, index) => (
                    <option value={uf.id} key={index}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Cidade Nascimento</Form.Label>
                <Form.Control
                  as="select"
                  disabled={municipioFilter ? false : true}
                  value={
                    cidadeNascimento
                      ? cidadeNascimento
                      : dadosRecuperados
                      ? model.nomeCidadeNascimento
                      : cidadeNascimento
                  }
                  name="cidadeNascimento"
                  onChange={(event: any) => {
                    setCidadeNascimento(parseInt(event.target.value));
                  }}
                  required
                >
                  {model.nomeCidadeNascimento && dadosRecuperados && (
                    <option value={model.cidadeNascimento}>{model.nomeCidadeNascimento}</option>
                  )}
                  <option value="">Selecione uma opção</option>
                  {cidadesNascimentoPorEstado.map((municipio, index) => (
                    <option key={index} value={municipio.id}>
                      {municipio.nomeMunicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome Mãe</Form.Label>
                <Form.Control
                  value={nomeMae ? nomeMae : dadosRecuperados ? model.nomeMae : nomeMae}
                  onChange={(event) => {
                    event.preventDefault();
                    setNomeMae(soLetras(event.target.value));
                    if (validaNome(soLetras(event.target.value))) {
                      setIsNomeMaeValid(true);
                    } else {
                      setIsNomeMaeValid(false);
                    }
                  }}
                  id="nomeMae"
                  name="nomeMae"
                  type="text"
                  maxLength={256}
                  required
                  title="Preencha com dois nomes"
                />{' '}
                {!isNomeMaeValid && nomeMae.length > 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Nome inválido, inserir nome e sobrenome.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome Pai</Form.Label>
                <Form.Control
                  value={nomePai ? nomePai : dadosRecuperados ? model.nomePai : nomePai}
                  onChange={(event) => {
                    event.preventDefault();
                    setNomePai(soLetras(event.target.value));
                    if (validaNome(soLetras(event.target.value))) {
                      setIsNomePaiValid(true);
                    } else {
                      setIsNomePaiValid(false);
                    }
                  }}
                  id="nomePai"
                  maxLength={256}
                  name="nomePai"
                  type="text"
                  title="Preencha com dois nomes"
                  required
                />{' '}
                {!isNomePaiValid && nomePai.length > 1 && (
                  <>
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Nome inválido, inserir nome e sobrenome. Caso não seja declarado, preencher
                      como "Não Declarado".
                    </p>
                    {/* <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Caso não seja declarado, preencher como "Não Declarado".
                    </p> */}
                  </>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
                <hr />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Telefone</Form.Label>
                <Form.Control
                  value={telefone ? telefone : dadosRecuperados ? model.telefone : telefone}
                  onChange={(event) => {
                    setTelefone(maskTel(event.target.value));
                  }}
                  id="telefone"
                  name="telefone"
                  type="text"
                  title="Telefone inválido"
                  maxLength={14}
                  minLength={13}
                  required
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Segundo Telefone</Form.Label>
                <Form.Control
                  value={
                    nextelContato
                      ? nextelContato
                      : dadosRecuperados
                      ? model.nextelContato
                      : nextelContato
                  }
                  onChange={(event) => {
                    setNextelContato(maskTel(event.target.value));
                  }}
                  id="nextelContato"
                  name="nextelContato"
                  type="text"
                  minLength={14}
                  maxLength={14}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Referência Nome</Form.Label>
                <Form.Control
                  value={
                    referenciaNome
                      ? referenciaNome
                      : dadosRecuperados
                      ? model.referenciaNome
                      : referenciaNome
                  }
                  onChange={(event) => {
                    setReferenciaNome(soLetras(event.target.value));
                  }}
                  maxLength={64}
                  id="referenciaNome"
                  name="referenciaNome"
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Referência Telefone</Form.Label>
                <Form.Control
                  value={
                    referenciaTelefone
                      ? referenciaTelefone
                      : dadosRecuperados
                      ? model.referenciaTelefone
                      : referenciaTelefone
                  }
                  onChange={(event) => {
                    setReferenciaTelefone(maskTel(event.target.value));
                  }}
                  id="referenciaTelefone"
                  name="referenciaTelefone"
                  type="text"
                  title="Telefone inválido"
                  maxLength={14}
                  minLength={13}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Grau Parentesco</Form.Label>
                <Form.Control
                  value={
                    grauParentesco
                      ? grauParentesco
                      : dadosRecuperados
                      ? model.grauParentesco
                      : grauParentesco
                  }
                  onChange={(event) => {
                    event.preventDefault();
                    setGrauParentesco(soLetras(event.target.value));
                    if (soLetras(event.target.value)) {
                      setIsGrauParentValid(true);
                    } else {
                      setIsGrauParentValid(false);
                    }
                  }}
                  maxLength={32}
                  id="grauParentesco"
                  name="grauParentesco"
                  type="text"
                />
                {!isGrauParentValid && grauParentesco.length >= 1 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Grau de Parentesco inválido
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
                <hr />
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={handleRegister}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCadastroAjudantePorSM;
