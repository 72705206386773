/* eslint-disable */
import { useState, useEffect } from 'react';
import { Form, Col, Spinner, Row } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import { getCookieSessionData } from '../../services/cookieService';
import { IoCheckmarkSharp } from 'react-icons/io5';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { FormatTimezoneToGrid } from '../../util/Validacoes/Validacoes';
import { orderBy, uniqBy } from 'lodash';
import { getFullDateWithHourAndMinutes, getFullTime } from '../../util/format';
import { PontoType } from '../../api/model/PontoType';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

type ChecklistEmbarcadorType = {
  dataInclusao: string;
  dataValidade: string;
  idChecklist: number;
  marcaVeiculo: string;
  modeloVeiculo: string;
  placaVeiculo: string;
  statusChecklist: boolean;
  transportador: string;
};

const ListagemPortariaEmbarcador = () => {
  const { addToast } = useToast();
  const [checklists, setChecklists] = useState<any[]>([]);
  const [pontos, setPontos] = useState<PontoType[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [transportadores, setTransportadores] = useState<any[]>([]);
  const [pontoSelecionado, setPontoSelecionado] = useState<PontoType>();
  const [searchTerm, setSearchTerm] = useState('');
  const [selecionado, setSelecionado] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dadosPerPage, setDadosPerPage] = useState(10);
  const indexOfLastPost = currentPage * dadosPerPage;
  const indexOfFirstPost = indexOfLastPost - dadosPerPage;
  const current = (searchTerm.trim() != '' ? searchResults : checklists).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const [itensPerPage, setItensPerPage] = useState('10');
  const history = useHistory();

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  useEffect(() => {
    if (searchTerm.trim()) {
      setCurrentPage(1);
      const results = checklists?.filter(
        (dados) =>
          dados.checklist?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.idSm?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.marca?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.modelo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.placa?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          dados.transportador?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (dados.previsaoSm &&
            FormatTimezoneToGrid(dados.previsaoSm)?.includes(searchTerm.toLowerCase()))
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`/ponto/cliente?clienteId=${clienteId}`)
      .then((response: any) => {
        setPontos(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  async function loadChecklists(ponto: PontoType) {
    if (ponto) {
      setLoading(true);
      setPontoSelecionado(ponto);
      const response = await axios.get(`/tecnologia-rastreamento/portaria?idPonto=${ponto.id}`);
      setChecklists(response.data);
      setLoading(false);
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Portaria</h2>
          <h6 className="subtitulo">{'Portaria > Listagem'}</h6>
        </div>
        <div className="content-container" style={{ width: '100%' }}>
          <div className="painel">
            <Form.Label>CD Origem</Form.Label>
            <Form.Row>
              <Form.Group as={Col}>
                <Dropdown
                  value={pontoSelecionado}
                  options={pontos}
                  optionLabel="descricao"
                  filter
                  showClear
                  filterBy="descricao"
                  placeholder="Selecione"
                  onChange={(e: any) => {
                    loadChecklists(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Row>
            {pontoSelecionado ? (
              <>
                <div className="table-responsive" style={{ flex: 1 }}>
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="warning"
                      style={{
                        display: 'flex',
                        marginLeft: '47.5%',
                        marginTop: '5%',
                        marginBottom: '5%',
                      }}
                    />
                  ) : (
                    <>
                      <div className="search-create mb-4">
                        <span className="p-input-icon-left">
                          <input
                            type="text"
                            placeholder="Pesquise"
                            className="p-inputtext p-component"
                            id="search-input"
                            style={{ width: '50vh' }}
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                          <i className="pi pi-search"></i>
                        </span>
                      </div>
                      <table className="table">
                        <thead className="thead">
                          <tr>
                            <th>Checklist</th>
                            <th>Placa</th>
                            <th>Marca</th>
                            <th>Modelo</th>
                            <th>Nome Condutor</th>
                            <th>CPF Condutor</th>
                            <th>Transportador</th>
                            <th>S.M</th>
                            <th>Previsão Início</th>
                          </tr>
                        </thead>
                        <tbody>
                          {current.map((each, index) => (
                            <tr key={index}>
                              <td>
                                <Button
                                  tooltip={
                                    each.checklistValidade.includes('OK') ||
                                    each.checklistValidade.includes('Inexistente')
                                      ? each.checklistValidade
                                      : each.listaDispositivo.filter((disp: any) =>
                                          disp.situacaoDispositivo.includes('2')
                                        ).length > 0 &&
                                        each.listaDispositivo.filter((disp: any) =>
                                          disp.situacaoDispositivo.includes('3')
                                        ).length <= 0
                                      ? each.checklistValidade
                                      : `Dispositivos pendentes de manutenção: (${each.listaDispositivo
                                          .filter((fil: any) => fil.situacaoDispositivo === '3')
                                          .map((each: any) => each.nomeDispositivo)}
                                  )`
                                  }
                                  tooltipOptions={{ position: 'bottom' }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'transparent',
                                    padding: 0,
                                    paddingRight: 5,
                                    marginBottom: 2,
                                  }}
                                >
                                  <IoCheckmarkSharp
                                    size={22}
                                    color={
                                      each.checklistValidade.includes('OK') ? '#3AE963' : '#E93A3A'
                                    }
                                  />
                                </Button>
                              </td>
                              <td>{each.placa}</td>
                              <td>{each.marca}</td>
                              <td>{each.modelo}</td>
                              <td>{each.nomeCondutor}</td>
                              <td>{each.cpfCondutor}</td>
                              <td>{each.transportador}</td>
                              <td>{each.idSm}</td>
                              <td>{getFullDateWithHourAndMinutes(each.previsaoSm, true)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}

                  <Pagination
                    itensPerPage={itensPerPage}
                    setItensPerPage={setItensPerPage}
                    totalPosts={searchTerm.trim() != '' ? searchResults.length : checklists.length}
                    paginate={paginate}
                    currentPage={currentPage.toString()}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListagemPortariaEmbarcador;
