/* eslint-disable */
import React, { useEffect, useState } from 'react';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { IoCheckmarkCircle } from 'react-icons/io5';
import './styles.css';
import ModalAssocia from './ModalAssocia';
import ModalDesassocia from './ModalDesassocia';
import instanceMaquina from '../../config/axiosMaquina';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import { Button } from 'primereact/button';
import { orderBy } from 'lodash';
import { removerSpecials } from '../../util/format';
import { Spinner } from '../../components/Spinner';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { useToast } from '../../hooks/Toast';

type DataType = {
  id: number;
  usuarioId: number;
  razaoSocial: string;
  nomeTecnologia: string;
  pisoMinimo: number;
  tetoMaximo: number;
  idAssociacaoTecnologia: number | null;
  idAssociacao: number;
  idTecnologia: number;
  idCliente: number;
  clienteEmbarcador?: string;
  idClienteEmbarcador?: number;
};

type UsuarioType = {
  usuCdId: number;
  nome: string;
};

type TecnologiaType = {
  idTecnologia: number;
  nomeTecnologia: string;
};

type ClienteType = {
  idCliente: number;
  razaoSocial: string;
};

const AssociarClientes = () => {
  const { addToast } = useToast();

  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  const [usuariosArr, setUsuariosArr] = useState<UsuarioType[] | undefined>();

  const [setClientesBuscaArr, setSetClientesBuscaArr] = useState<ClienteType[] | undefined>();
  const [tecnologiasBuscaArr, setTecnologiasBuscaArr] = useState<TecnologiaType[] | undefined>();

  const [clientesArr, setClientesArr] = useState<ClienteType[] | undefined>();
  const [clientesArr2, setClientesArr2] = useState<ClienteType[] | undefined>();
  const [tecnologiasArr, setTecnologiasArr] = useState<TecnologiaType[] | undefined>();

  const [usuario, setUsuario] = useState<UsuarioType | undefined>();
  const [cliente, setCliente] = useState({ idCliente: -1, razaoSocial: 'TODOS' });
  const [tecnologia, setTecnologia] = useState({ idTecnologia: -1, nomeTecnologia: 'TODOS' });
  const [checked, setChecked] = useState(false);

  const [pisoMinimo, setPisoMinimo] = useState<number>(0);
  const [tetoMaximo, setTetoMaximo] = useState<number>(0);

  const [data, setData] = useState<DataType[] | undefined>();
  const [filteredData, setFilteredData] = useState<DataType[]>();
  const [selectedData, setSelectedData] = useState<DataType | undefined>();
  const [handleAllItens, setHandleAllItens] = useState(false);

  const [showModalAssocia, setShowModalAssocia] = useState(false);
  const [showModalDesassocia, setShowModalDesassocia] = useState(false);
  const [loading, setLoading] = useState(false);

  const [chosenCliente, setChosenCliente] = useState({ idCliente: -1, razaoSocial: 'TODOS' });
  const [chosenCliente2, setChosenCliente2] = useState({ idCliente: -1, razaoSocial: 'TODOS' });
  const [chosenTecnologia, setChosenTecnologia] = useState({
    idTecnologia: -1,
    nomeTecnologia: 'TODOS',
  });

  const history = useHistory();
  const { user, page }: any = useParams();

  const [itensPerPage, setItensPerPage] = useState('10');

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.USUARIO.ASSOCIAR_CLIENTE]}], 'editar');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const fetchDados = async () => {

      const responseTech = await instanceMaquina.get('/tecnologia-rastreamento/listarIdNome');
      const tech: TecnologiaType[] = [
        {
          idTecnologia: -1,
          nomeTecnologia: 'TODOS',
        },
      ];
      responseTech.data.map((each: any) => {
        tech.push({
          idTecnologia: each.id,
          nomeTecnologia: each.nome,
        });
      });
      setTecnologiasArr(tech);

      const res = await instanceMaquina.get('/usuario/gerenciador');
      setUsuariosArr(res.data);

      const response = await instanceMaquina.get('/cliente/getIdRazaoSocial');
      const client: ClienteType[] = [
        {
          idCliente: -1,
          razaoSocial: 'TODOS',
        },
      ];
      response.data.filter(({ clienteEmbarcador }: { clienteEmbarcador: boolean }) => !clienteEmbarcador).map((each: any) => {
        client.push({
          idCliente: each.id,
          razaoSocial: each.razaoSocial,
        });
      });

      setClientesArr(client);
    };
    fetchDados();
  }, []);

  useEffect(() => {
    const fetchDados = async () => {

      setChosenCliente2({ idCliente: -1, razaoSocial: 'TODOS' })

      const response2 = await instanceMaquina.get('/embarcador/search-embarcador', {
        params: {
          razaoSocialCliente: chosenCliente.razaoSocial,
        }
      });
      const client2: ClienteType[] = [
        {
          idCliente: -1,
          razaoSocial: 'TODOS',
        },
      ];


      response2.data.map((each: any) => {
        client2.push({
          idCliente: each.idEmbarcador,
          razaoSocial: each.razaoSocial,
        });
      });
      setClientesArr2(client2);
    };
    fetchDados();
  }, [chosenCliente]);

  useEffect(() => {
    if (!user || !usuariosArr) {
      return;
    }
    const userFound = usuariosArr.find((each) => each.usuCdId.toString() === user);
    setUsuario(userFound);
  }, [user, usuariosArr]);

  useEffect(() => {
    if (usuario) {
      history.push(`/associar-clientes/${usuario.usuCdId}/1`);
    }
  }, [usuario]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const getData = async () => {
      try {
        setLoading(true);
        const response = await instanceMaquina.get(
          `/usuario/gerenciador/clientes-tecnologias-associados/${parseInt(user, 10)}`
        );

        const data = response.data.map((each: any, index: number) => {
          return { ...each, id: index + 1 };
        });
        setData(data);
      } catch (error) {
        console.log({ error});
      } finally {
        setLoading(false);
      }

    };
    getData();
  }, [user]);

  //Filtra o picker de clientes através da tec
  useEffect(() => {
    if (!data) {
      return;
    }

    let filteredClientesArr: ClienteType[] = [];

    if (tecnologia.idTecnologia !== -1) {
      data.map((each) => {
        if (
          !filteredClientesArr.some((cliente) => cliente.idCliente === each.idCliente) &&
          each.idTecnologia === tecnologia.idTecnologia
        ) {
          filteredClientesArr.push({
            idCliente: each.idCliente,
            razaoSocial: each.razaoSocial,
          });
        }
      });
    } else {
      data.map((each: DataType) => {
        if (!filteredClientesArr.some((cliente) => cliente.idCliente === each.idCliente)) {
          filteredClientesArr.push({
            idCliente: each.idCliente,
            razaoSocial: each.razaoSocial,
          });
        }
      });
    }

    setSetClientesBuscaArr([
      {
        idCliente: -1,
        razaoSocial: 'TODOS',
      },
      ...orderBy(
        filteredClientesArr,
        [(t) => removerSpecials(t.razaoSocial).toLowerCase()],
        ['asc']
      ),
    ]);
  }, [tecnologia, data]);

  //Filtra o picker de tecnologias através dos clientes
  useEffect(() => {
    if (!data) {
      return;
    }

    let filteredTecnologiaArr: TecnologiaType[] = [
      {
        idTecnologia: -1,
        nomeTecnologia: 'TODOS',
      },
    ];

    if (cliente.idCliente !== -1) {
      data.map((each) => {
        if (
          !filteredTecnologiaArr.some((cliente) => cliente.idTecnologia === each.idTecnologia) &&
          each.idCliente === cliente.idCliente
        ) {
          filteredTecnologiaArr.push({
            idTecnologia: each.idTecnologia,
            nomeTecnologia: each.nomeTecnologia,
          });
        }
      });
    } else {
      data.map((each: DataType) => {
        if (!filteredTecnologiaArr.some((cliente) => cliente.idTecnologia === each.idTecnologia)) {
          filteredTecnologiaArr.push({
            idTecnologia: each.idTecnologia,
            nomeTecnologia: each.nomeTecnologia,
          });
        }
      });
    }
    setTecnologiasBuscaArr(
      orderBy(
        filteredTecnologiaArr,
        [(t) => removerSpecials(t.nomeTecnologia).toLowerCase()],
        ['asc']
      )
    );
  }, [cliente, data]);

  useEffect(() => {
    if (!data || !usuario) {
      return;
    }
    if (
      // CLIENTE
      cliente.idCliente !== -1 &&
      tecnologia.idTecnologia === -1
    ) {
      setFilteredData(data.filter((each) => each.idCliente === cliente.idCliente));
      history.push(`/associar-clientes/${usuario.usuCdId}/1`);
    } else if (
      // TECNOLOGIA
      tecnologia.idTecnologia !== -1 &&
      cliente.idCliente === -1
    ) {
      setFilteredData(data.filter((each) => each.idTecnologia === tecnologia.idTecnologia));
      history.push(`/associar-clientes/${usuario.usuCdId}/1`);
    } else if (
      // CLIENTE E TECNOLOGIA
      tecnologia.idTecnologia !== -1 &&
      cliente.idCliente !== -1
    ) {
      const clientesFiltered = data.filter((each) => each.idCliente === cliente.idCliente);
      setFilteredData(
        clientesFiltered.filter((each) => each.idTecnologia === tecnologia.idTecnologia)
      );
      history.push(`/associar-clientes/${usuario.usuCdId}/1`);
    } else {
      setFilteredData(data);
      history.push(`/associar-clientes/${usuario.usuCdId}/1`);
    }
  }, [cliente, data, tecnologia, usuario]);

  useEffect(() => {
    if (
      (filteredData && filteredData.some((each) => each.idAssociacaoTecnologia === null)) ||
      (filteredData && !filteredData.length)
    ) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [filteredData]);

  useEffect(() => {
    if (!usuario) {
      setCliente({ idCliente: -1, razaoSocial: 'TODOS' });
      setTecnologia({ idTecnologia: -1, nomeTecnologia: 'TODOS' });
      setPisoMinimo(0);
      setTetoMaximo(0);
      setChecked(false);
      setFilteredData(undefined);
    } else if (cliente || tecnologia) {
      return;
    } else {
      setFilteredData(data);
    }
  }, [usuario, data]);

  const paginate = (pageNumber: number) => {
    if (!usuario) {
      return;
    }
    history.push(`/associar-clientes/${usuario.usuCdId}/${pageNumber}`);
  };

  const handleAssociar = async () => {


    if (!usuario) {
      return;
    }
    const data = {
      idUsuario: usuario.usuCdId,
      idCliente: chosenCliente.idCliente,
      idClienteEmbarcador: chosenCliente2.idCliente,
      idTecnologia: chosenTecnologia.idTecnologia,
      pisoMinimo: !pisoMinimo || pisoMinimo === 0 ? -1 : pisoMinimo,
      tetoMaximo: !tetoMaximo || tetoMaximo === 0 ? -1 : tetoMaximo,
    };

    setLoading(true)

    try {
      await instanceMaquina.post('usuario/gerenciador/associar-usuario-cliente-tecnologia', data);
      location.reload();
    } catch (err: any) {

      addToast({
        title: 'Erro',
        description: 'Erro ao associar',
        type: 'error',
      });

      console.log(err);
    } finally {
      setLoading(false)
    }
  };

  const handleDesassociar = async () => {
    setLoading(true);

    try {
      if (handleAllItens) {
        let desassociarData: {
          idUsuario?: number;
          associacoes: { idAssociacao: number; idTecnologia: number; idClienteEmbarcador?: number; }[] }
          = {
          idUsuario: usuario?.usuCdId,
          associacoes: [],
        };
        filteredData &&
          filteredData.map((each) => {
            desassociarData.associacoes.push({
              idAssociacao: each.idAssociacao,
              idTecnologia: each.idTecnologia,
              idClienteEmbarcador: each?.idClienteEmbarcador,
            });
          });

        await instanceMaquina.post(
          'usuario/gerenciador/desassociar-usuario-cliente-tecnologia',
          { limparTodas: true, ...desassociarData}
        );

        location.reload();
        setLoading(false);
        return;
      }

      data &&
        data.map(async (each) => {
          if (selectedData && each.id === selectedData.id) {
            const data = {
              idUsuario: usuario?.usuCdId,
              associacoes: [
                {
                  idAssociacao: each.idAssociacao,
                  idTecnologia: each.idTecnologia,
                  idClienteEmbarcador: each?.idClienteEmbarcador,
                },
              ],
            };
            await instanceMaquina.post('usuario/gerenciador/desassociar-usuario-cliente-tecnologia', data);
            setLoading(false);
          }
        });

      setSelectedData(undefined)
      location.reload();

    } catch (err) {
      addToast({
        title: 'Erro',
        description: 'Erro ao dessasociar!',
        type: 'error',
      });
    } finally {
      setLoading(false)
    }
    ;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <ModalAssocia
        setShow={setShowModalAssocia}
        show={showModalAssocia}
        pisoMinimo={pisoMinimo}
        tetoMaximo={tetoMaximo}
        setPisoMinimo={setPisoMinimo}
        setTetoMaximo={setTetoMaximo}
        setChosenCliente={setChosenCliente}
        chosenCliente={chosenCliente}
        setChosenTecnologia={setChosenTecnologia}
        chosenTecnologia={chosenTecnologia}
        onPress={() => handleAssociar()}
        clientesArr={clientesArr}
        clientesArr2={clientesArr2}
        tecnologiasArr={tecnologiasArr}
        chosenCliente2={chosenCliente2}
        setChosenCliente2={setChosenCliente2}
      />
      <ModalDesassocia
        setShow={setShowModalDesassocia}
        show={showModalDesassocia}
        onPress={handleDesassociar}
        setHandleAllItens={setHandleAllItens}
        loading={loading}
      />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Associar Clientes</h2>
          <h6 className="subtitulo">{'Usuários > Associar Clientes'}</h6>
        </div>

        <div className="content-container">
          {loading ? (
            <Spinner />
          ) : (
            <div className="painel">
              <div className="header-associar-container">
                <div className="header-associar-field">
                  <span>Usuário</span>
                  <Dropdown
                    value={usuario}
                    options={usuariosArr}
                    onChange={(e) => setUsuario(e.value)}
                    optionLabel="nome"
                    filter
                    filterBy="nome"
                    placeholder="Usuário"
                    className="p-dropdown"
                  />
                </div>
                <div className="header-associar-field">
                  <span>Cliente</span>
                  <Dropdown
                    value={cliente}
                    options={setClientesBuscaArr}
                    onChange={(e) => setCliente(e.value)}
                    optionLabel="razaoSocial"
                    filter
                    filterBy="razaoSocial"
                    placeholder="Cliente"
                    className="p-dropdown"
                    disabled={!usuario}
                  />
                </div>
                <div className="header-associar-field">
                  <span>Tecnologia</span>
                  <Dropdown
                    value={tecnologia}
                    options={tecnologiasBuscaArr}
                    onChange={(e) => setTecnologia(e.value)}
                    optionLabel="nomeTecnologia"
                    filter
                    filterBy="nomeTecnologia"
                    placeholder="Tecnologia "
                    className="p-dropdown"
                    disabled={!usuario}
                  />
                </div>
                <div className="header-associar-field button-field">
                  <span></span>
                  <Button
                    className="botao-associar"
                    onClick={() => {
                      if (canEdit) {
                        setShowModalAssocia(true);
                      } else {
                        history.push('/acessonegado');
                      }
                    }}
                    disabled={!usuario}
                  >
                    Associar
                  </Button>
                </div>
              </div>
              <div className="table-responsive" style={{ flex: 1 }}>
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Cliente (Transportador)</th>
                      <th>Regra de apólice</th>
                      <th>Tecnologia</th>
                      <th>Piso Mínimo</th>
                      <th>Teto Máximo</th>
                      <th className="icons-container-associar">
                        <Checkbox
                          onChange={(e) => {
                            if (canEdit) {
                              setHandleAllItens(true);
                              checked ? setShowModalDesassocia(true) : setShowModalAssocia(true);
                            } else {
                              history.push('/acessonegado');
                            }
                          }}
                          checked={checked}
                          disabled={!usuario || (filteredData && !filteredData.length)}
                        />
                      </th>
                    </tr>
                  </thead>
                  {!usuario && !user ? (
                    <tr>
                      <th style={{ textAlign: 'center', marginTop: 25, color: 'red' }} colSpan={5}>
                        Selecione um usuário para fazer a associação
                      </th>
                    </tr>
                  ) : (
                    <tbody>
                      {filteredData &&
                        filteredData
                          .slice(
                            parseInt(itensPerPage, 10) * page - parseInt(itensPerPage, 10),
                            parseInt(itensPerPage, 10) * page
                          )
                          .map((data) => {
                            return (
                              <tr
                                key={data.id}
                                onClick={() => {
                                  if (canEdit) {
                                    data.idAssociacaoTecnologia
                                      ? setShowModalDesassocia(true)
                                      : setShowModalAssocia(true);
                                    setSelectedData(data);
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                              >
                                <td>{data.razaoSocial}</td>
                                <td>{data.clienteEmbarcador}</td>
                                <td>{data.nomeTecnologia}</td>
                                <td>{data.pisoMinimo === -1 ? 0 : data.pisoMinimo}</td>
                                <td>{data.tetoMaximo === -1 ? 0 : data.tetoMaximo}</td>
                                <td className="icons-container-associar" style={{ width: 150 }}>
                                  <IoCheckmarkCircle
                                    color={data.idAssociacaoTecnologia ? '#63c960' : '#969696'}
                                    size={25}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          )}
          {page && !loading && (
            <Pagination
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={!filteredData || !filteredData.length ? 0 : filteredData.length}
              paginate={paginate}
              currentPage={page ? page : 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AssociarClientes;
