/* eslint-disable */
import axios from '../../config/axiosMaquina';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { CentroDistribuicaoTypes } from '../../api/Types/portariaGridTypes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import {
  maskCep,
  maskTel,
  validateLat,
  validateLng,
  validaEmail,
  soNumeros,
} from '../../util/Validacoes/Validacoes';
import { PaisType } from '../../api/model/PaisType';
import { getCookieSessionData } from '../../services/cookieService';
import Switch from 'react-bootstrap/esm/Switch';
import { RegionalType } from '../../api/model/RegionalType';
import { useToast } from '../../hooks/Toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import useAddress from '../../hooks/useAddress';

const CadastroPontos = () => {
  const { addToast } = useToast();
  const { getLatLngFromAddress } = useAddress();
  const [centroDistribuicaoId, setCentroDistribuicaoId] = useState('');
  const [cep, setCep] = useState('');
  const [descricao, setDescricao] = useState('');
  const [emailContato, setEmailContato] = useState('');
  const [emailContatoDois, setEmailContatoDois] = useState('');
  const [embarcadorId, setEmbarcadorId] = useState();
  const [latitude, setLatitude] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroBairro, setLogradouroBairro] = useState('');
  const [logradouroComplemento, setLogradouroComplemento] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState('');
  const [longitude, setLongitude] = useState('');
  const [municipioId, setMunicipioId] = useState<number | undefined>();
  const [paisId, setPaisId] = useState('');
  const [raioAproximacao, setRaioAproximacao] = useState('');
  const [regionalId, setRegionalId] = useState('');
  const [smsAgendamentoViagemContato, setSmsAgendamentoViagemContato] = useState(true);
  const [smsAgendamentoViagemContatoDois, setSmsAgendamentoViagemContatoDois] = useState(true);
  const [smsAgendamentoViagemContatoTres, setSmsAgendamentoViagemContatoTres] = useState(true);
  const [smsInicioViagemContato, setSmsInicioViagemContato] = useState(true);
  const [smsInicioViagemContatoDois, setSmsInicioViagemContatoDois] = useState(true);
  const [smsInicioViagemContatoTres, setSmsInicioViagemContatoTres] = useState(true);
  const [statusAtivo, setStatusAtivo] = useState(true);
  const [telContato, setTelContato] = useState('');
  const [telContatoDois, setTelContatoDois] = useState('');
  const [telContatoTres, setTelContatoTres] = useState('');
  const [ufId, setUfId] = useState<number>();

  const history = useHistory();

  type Municipios = {
    id: number;
    codigoMunicipioIbge: number;
    nomeMunicipio: string;
    idCodigoIbge: number;
    ufId: number;
    ufNome: string;
  };

  const [pais, setPais] = useState<PaisType[]>([]);
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<Municipios[]>([]);
  const [centroDistribuicao, setCentroDistribuicao] = useState<CentroDistribuicaoTypes[]>([]);
  const [regional, setRegional] = useState<RegionalType[]>([]);
  const [raioDefault, setRaioDefault] = useState('');
  const [patio, setPatio] = useState(false);
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [viaCepIbgeCidade, setViaCepIbgeCidade] = useState(0);
  const [viaCepCidade, setViaCepCidade] = useState('');

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidLatitude, setIsValidLatitude] = useState(true);
  const [isValidLongitude, setIsValidLongitude] = useState(true);
  const [isValidEmailDois, setIsValidEmailDois] = useState(true);
  const [error, setError] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);

  const [isDialogVisibleErroDescricao, setIsDialogVisibleErroDescricao] = useState(false);
  const [isValidDescricao, setIsValidDescricao] = useState(true);
  const [isDialogVisibleErroEndereco, setIsDialogVisibleErroEndereco] = useState(false);
  const [isValidEndereco, setIsValidEndereco] = useState(true);
  const { clienteId } = getCookieSessionData().usuarioVO;

  const validaDescricao = async (descricao: string) => {
    try {
      const response = await axios.get(
        `/ponto/verificarPontoDescricao?idCliente=${clienteId}&keyword=${descricao}`
      );
      setIsDialogVisibleErroDescricao(response.data);
      setIsValidDescricao(!response.data);
    } catch (error) {
      setIsDialogVisibleErroDescricao(false);
      setIsValidDescricao(true);
    }
  };

  const validaEndereco = async (endereco: string) => {
    try {
      const response = await axios.get(
        `/ponto/verificarPontoEndereco?idCliente=${clienteId}&keyword=${endereco}`
      );
      setIsDialogVisibleErroEndereco(response.data);
      setIsValidEndereco(!response.data);
    } catch (error) {
      setIsDialogVisibleErroEndereco(false);
      setIsValidEndereco(true);
    }
  };

  useEffect(() => {
    validaDescricao(descricao);
  }, [descricao]);

  useEffect(() => {
    validaEndereco(logradouro);
  }, [logradouro]);

  const fetchData = async () => {
    const response = await axios.get('/pais');
    setPais(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const loadEstadosPorPais = async (pais: any, uf?: any) => {
    try {
      const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/${pais}`);
      setUfs(response.data);
      if (uf) {
        let _ufs = response.data;
        let _uf = _ufs?.find((each: any) => each.sigla === uf)?.id;
        setUfId(_uf);
        loadMunicipiosPorEstado(_uf);
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadMunicipiosPorEstado = async (estado: any) => {
    if (estado) {
      try {
        const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
        setMunicipios(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    }
  };

  useEffect(() => {
    const buscarCep = async () => {
      setError(false);
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
        if (data.erro) {
          setError(true);
          return;
        }
        setPaisId('1');
        loadEstadosPorPais('1', data.uf);
        setViaCepCidade(data.localidade);
        setMunicipioId(parseInt(data.ibge));
        setLogradouro(data.logradouro);
        setLogradouroBairro(data.bairro);
        const codIbgeFloat = data.ibge / 100000;
        const codIbgeFormatado = Math.trunc(codIbgeFloat);
        setViaCepIbgeCod(codIbgeFormatado);
      } catch (erro) {
        console.log('error', erro);
        setError(true);
      }
    };
    if (cep.length === 9) {
      buscarCep();
    } else {
      setLogradouro('');
      setLogradouroBairro('');
      setLogradouroNumero('');
      setMunicipioId(0);
      setUfId(0);
      setLatitude('');
      setLongitude('');
    }
  }, [cep]);

  useEffect(() => {
    const buscarLatLong = async () => {
      let address = {};
      if (logradouro && (municipios != undefined || municipioId)) {
        try {
          const cidade = municipios.find(
            (c: any) => c.codigoMunicipioIbge == municipioId
          )?.nomeMunicipio;
          const estado = ufs.find((uf: any) => uf.id == ufId)?.sigla;
          address = {
            logradouro,
            numero: logradouroNumero,
            bairro: logradouroBairro,
            cidade,
            estado,
          };

          let location = await getLatLngFromAddress(address);
          const { lat, lng } = location;
          if (lat && lng) {
            setLatitude(lat.toString());
            setLongitude(lng.toString());
          }
        } catch (err: any) {
          console.log(err);
        }
      }
    };

    buscarLatLong();
  }, [cep, logradouroBairro, logradouro, logradouroNumero, municipioId, ufId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/veiculo/listarNomeCd?idCliente=${clienteId}`);
      setCentroDistribuicao(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/regional/cliente`, {
        params: {
          clienteId: clienteId,
        },
      });
      setRegional(response.data);
    };
    fetchData();
  }, []);

  const [flagIncluirCdCadPonto, setFlagIncluirCdCadPonto] = useState(false);
  const [flagIncluirRegionalCadPonto, setFlagIncluirRegionalCadPonto] = useState(false);

  useEffect(() => {
    const getParticularidades = async () => {
      try {
        const response = await axios.get(
          `/cliente/getParticularidadesCliente?cliente=${clienteId}`
        );

        setFlagIncluirCdCadPonto(response.data.flagIncluirCdCadPonto);
        setFlagIncluirRegionalCadPonto(response.data.flagIncluirRegionalCadPonto);
      } catch (err: any) {
        console.log(err);
      }
    };
    getParticularidades();
  }, [clienteId]);

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    //verifica se ambos emails estão invalidos e existem
    if (
      !descricao ||
      (!viaCepCidade && !municipioId) ||
      !logradouroNumero ||
      (!isValidEmail && emailContato) ||
      (!isValidEmailDois && emailContatoDois)
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }

    if (flagIncluirCdCadPonto) {
      if (!centroDistribuicaoId) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        e.stopPropagation();
        return;
      }
    }

    if (flagIncluirRegionalCadPonto) {
      if (!regionalId) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        e.stopPropagation();
        return;
      }
    }

    if (!isValidDescricao && !isValidEndereco) {
      addToast({
        title: 'Erro!',
        description: 'Descrição e Endereço já existentes',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }

    const data = {
      descricao: descricao,
      patio: patio,
      cep: cep,
      logradouro: logradouro,
      logradouroNumero: parseInt(logradouroNumero, 10),
      logradouroComplemento: logradouroComplemento,
      logradouroBairro: logradouroBairro,
      emailContato: emailContato,
      emailContatoDois: emailContatoDois,
      latitude: latitude,
      longitude: longitude,
      raioAproximacao:
        raioAproximacao && raioAproximacao != '' ? parseInt(raioAproximacao, 10) : raioDefault,
      telContato: telContato,
      smsAgendamentoViagemContato: smsAgendamentoViagemContato,
      smsInicioViagemContato: smsInicioViagemContato,
      telContatoDois: telContatoDois,
      smsAgendamentoViagemContatoDois: smsAgendamentoViagemContatoDois,
      smsInicioViagemContatoDois: smsInicioViagemContatoDois,
      telContatoTres: telContatoTres,
      smsAgendamentoViagemContatoTres: smsAgendamentoViagemContatoTres,
      smsInicioViagemContatoTres: smsInicioViagemContatoTres,
      statusAtivo: statusAtivo,
      municipioId: 0 ? null : viaCepIbgeCidade ? viaCepIbgeCidade : municipioId,
      ufId: 0 ? null : ufId,
      paisId: parseInt(paisId, 10),
      embarcadorId: embarcadorId,
      clienteId: clienteId,
      centroDistribuicaoId: parseInt(centroDistribuicaoId, 10),
      regionalId: parseInt(regionalId, 10),
    };

    axios
      .post('/ponto', data)
      .then(() => setIsDialogCadOK(true))
      .catch(() => {
        setIsDialogCadOK(false);
        addToast({
          title: 'Erro!',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      });
  }

  useEffect(() => {
    const getRaioDefault = async () => {
      try {
        const response = await axios.get(`/parametro-logistico`);
        setRaioDefault(response.data.raioDeAproximacaoVeiculosEmMetros);
        setRaioAproximacao(response.data.raioDeAproximacaoVeiculosEmMetros);
      } catch (err: any) {
        console.log(err);
      }
    };

    getRaioDefault();
  }, []);

  const reseta = () => {
    setIsDialogVisibleErroDescricao(false),
      setIsDialogVisibleErroEndereco(false),
      setDescricao(''),
      setLogradouro(''),
      setIsValidDescricao(true),
      setIsValidEndereco(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <Dialog
        header={
          <>
            <p>Atenção! Descrição e Endereço já cadastrados!</p>
            <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
              Não é possível cadastrar um novo ponto se a Descrição e o Logradouro já existirem.
            </p>
          </>
        }
        footer={
          <>
            {/* <Button
              label="Continuar"
              onClick={() => {
                setIsDialogVisibleErroDescricao(false), setIsValidDescricao(false);
              }}
            /> */}
            <Button label="Alterar" onClick={reseta} />
          </>
        }
        visible={isDialogVisibleErroDescricao && isDialogVisibleErroEndereco}
        style={{ width: '50vw' }}
        modal
        onHide={reseta}
      />

      <MenuLateral />

      <Dialog
        header="Ponto cadastrado com sucesso!"
        footer={<Button label="OK" onClick={() => setIsDialogCadOK(false)} />}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Pontos</h2>
          <h6 className="subtitulo">{'Cadastro > Pontos'}</h6>
        </div>

        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>

        <Form className="form-responsive" onSubmit={onSubmit} noValidate validated={isFormInvalid}>
          <Form.Check
            checked={patio}
            onChange={() => {
              setPatio(!patio);
            }}
            name="criticar"
            type="switch"
            id="custom-switch1"
            label="Pátio"
            style={{
              marginBottom: '1rem',
            }}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="formGridDescricao">
              <Form.Label className="require">Descrição</Form.Label>
              <Form.Control
                type="text"
                name="descricao"
                placeholder=""
                maxLength={256}
                value={descricao}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDescricao(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCEP">
              <Form.Label className="requiredField">CEP</Form.Label>
              <Form.Control
                name="cep"
                placeholder=""
                value={cep}
                onChange={(event: any) => {
                  setCep(maskCep(event.target.value));
                }}
                maxLength={9}
                minLength={9}
                required
                style={{ width: '32.8%' }}
              ></Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridPais">
              <Form.Label>País</Form.Label>
              <Form.Control
                name="paisId"
                placeholder=""
                value={paisId}
                onChange={(e: any) => {
                  setPaisId(e.target.value);
                  loadEstadosPorPais(e.target.value);
                }}
                as="select"
                defaultValue="0"
              >
                <option value="0">Selecione uma opção</option>
                {pais.map((pais) => (
                  <option value={pais.id} key={pais.id}>
                    {pais.paisDescricao}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridUF">
              <Form.Label>UF</Form.Label>
              <Form.Control
                type="number"
                name="ufId"
                as="select"
                defaultValue=""
                // value={idCodigoIbge ? idCodigoIbge : ufId}
                value={ufId}
                onChange={(e: any) => {
                  setUfId(e.target.value);
                  loadMunicipiosPorEstado(e.target.value);
                }}
                // disabled={paisId ? false : true}
              >
                <option value="">Selecione uma opção</option>
                {paisId == '1'
                  ? ufs.map((ufs) => (
                      <option value={ufs.id} key={ufs.id}>
                        {ufs.sigla}
                      </option>
                    ))
                  : ufs.map((ufs) => (
                      <option value={ufs.id} key={ufs.id}>
                        {ufs.estado}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                type="number"
                name="municipioId"
                // value={viaCepIbgeCidade ? viaCepIbgeCidade : municipioId}
                value={municipioId}
                defaultValue=""
                onChange={(e: any) => {
                  setMunicipioId(e.target.value);
                }}
                as="select"
              >
                <option value="">Selecione uma opção</option>
                {municipios.map((filteredMunicipio) => (
                  <option key={filteredMunicipio.nomeMunicipio} value={filteredMunicipio.id}>
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridBairro">
              <Form.Label className="requiredField">Bairro</Form.Label>
              <Form.Control
                type="text"
                name="logradouroBairro"
                placeholder=""
                maxLength={128}
                required
                value={logradouroBairro}
                // disabled={municipioId ? false : true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouroBairro(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLogradouro">
              <Form.Label className="requiredField">Logradouro</Form.Label>
              <Form.Control
                type="text"
                name="logradouro"
                placeholder=""
                maxLength={256}
                value={logradouro}
                required
                // disabled={municipioId ? false : true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouro(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridComplemento">
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                type="text"
                maxLength={128}
                name="logradouroComplemento"
                placeholder=""
                value={logradouroComplemento}
                // disabled={municipioId ? false : true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouroComplemento(e.target.value);
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridNumero">
              <Form.Label className="requiredField">Número</Form.Label>
              <Form.Control
                type="text"
                maxLength={8}
                name="logradouroNumero"
                required
                placeholder=""
                value={logradouroNumero ? logradouroNumero : ''}
                // disabled={municipioId ? false : true}
                onChange={(e: any) => {
                  setLogradouroNumero(soNumeros(e.target.value));
                }}
              ></Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridRaioAproximacao">
              <Form.Label>Raio Aproximação</Form.Label>
              <Form.Control
                type="text"
                maxLength={10}
                name="raioAproximacao"
                placeholder=""
                value={raioAproximacao}
                onChange={(e: any) => {
                  setRaioAproximacao(soNumeros(e.target.value));
                }}
              ></Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLatitude">
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                name="latitude"
                placeholder=""
                value={latitude ? latitude : ''}
                onChange={(e: any) => {
                  setLatitude(e.target.value);
                  if (validateLat(e.target.value)) {
                    setIsValidLatitude(true);
                  } else {
                    setIsValidLatitude(false);
                  }
                }}
                maxLength={32}
              ></Form.Control>{' '}
              {/* {!isValidLatitude && latitude.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Latitude inválida. Favor inserir: 2 números + vírgula + 6 números. Sinal negativo
                  opcional.
                </p>
              )} */}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLongitude">
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                name="longitude"
                placeholder=""
                value={longitude ? longitude : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLongitude(e.target.value);
                  if (validateLng(e.target.value)) {
                    setIsValidLongitude(true);
                  } else {
                    setIsValidLongitude(false);
                  }
                  // if (e.target.value.slice(0) === '-') {
                  //   setIsLongNegative(true);
                  //   if (validateLng(e.target.value)) {
                  //     setIsValidLongitude(true);
                  //   } else {
                  //     setIsValidLongitude(false);
                  //   }
                  // } else {
                  //   setIsLongNegative(false);
                  //   if (validateLng(LngPos(e.target.value))) {
                  //     setIsValidLongitude(true);
                  //   } else {
                  //     setIsValidLongitude(false);
                  //   }
                  // }
                }}
                maxLength={32}
              ></Form.Control>{' '}
              {/* {!isValidLongitude && longitude.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Longitude inválida. Favor inserir: 3 números + vírgula + 6 números. Sinal negativo
                  opcional.
                </p>
              )} */}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCD">
              <Form.Label>
                {flagIncluirCdCadPonto && <span className="txt-obrigatorio">*</span>}
                CD Origem
              </Form.Label>
              <Form.Control
                name="centroDistribuicaoId"
                placeholder=""
                as="select"
                defaultValue=""
                value={centroDistribuicaoId}
                onChange={(e: any) => {
                  setCentroDistribuicaoId(e.target.value);
                }}
                required={flagIncluirCdCadPonto}
              >
                <option value=""></option>
                {centroDistribuicao.map((cd) => (
                  <option value={cd.id} key={cd.id}>
                    {cd.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRegional">
              <Form.Label>
                {flagIncluirRegionalCadPonto && <span className="txt-obrigatorio">*</span>}
                Regional
              </Form.Label>
              <Form.Control
                name="regionaId"
                placeholder=""
                as="select"
                defaultValue=""
                value={regionalId}
                onChange={(e: any) => {
                  setRegionalId(e.target.value);
                }}
                required={flagIncluirRegionalCadPonto}
              >
                <option value=""></option>
                {regional.map((cd) => (
                  <option value={cd.id} key={cd.id}>
                    {cd.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmailUm">
              <Form.Label>Email 1</Form.Label>
              <Form.Control
                type="text"
                name="emailContato"
                placeholder=""
                value={emailContato}
                maxLength={256}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  setEmailContato(e.target.value);
                  if (validaEmail(e.target.value)) {
                    setIsValidEmail(true);
                  } else {
                    setIsValidEmail(false);
                  }
                }}
              ></Form.Control>{' '}
              {!isValidEmail && emailContato.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmailDois">
              <Form.Label>Email 2</Form.Label>
              <Form.Control
                type="text"
                name="emailContatoDois"
                placeholder=""
                value={emailContatoDois}
                maxLength={256}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  setEmailContatoDois(e.target.value);
                  if (validaEmail(e.target.value)) {
                    setIsValidEmailDois(true);
                  } else {
                    setIsValidEmailDois(false);
                  }
                }}
              ></Form.Control>{' '}
              {!isValidEmailDois && emailContatoDois.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridTelContatoUm">
              <Form.Label>Contato Celular 1</Form.Label>
              <Form.Control
                name="telContato"
                placeholder=""
                value={telContato ? telContato : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTelContato(maskTel(e.target.value));
                }}
                maxLength={14}
                minLength={13}
              ></Form.Control>
              <div style={{ marginTop: '1%' }}>
                <Switch
                  checked={smsAgendamentoViagemContato}
                  name="SMSAgendamento"
                  id="SMSAgendamento"
                  type="switch"
                  label="Enviar SMS no agendamento da viagem"
                  onChange={() => {
                    setSmsAgendamentoViagemContato(!smsAgendamentoViagemContato);
                  }}
                />
              </div>
              <div>
                <Switch
                  checked={smsInicioViagemContato}
                  name="SMSInicio"
                  id="SMSInicio"
                  type="switch"
                  label="Enviar SMS no começo da viagem"
                  onChange={() => {
                    setSmsInicioViagemContato(!smsInicioViagemContato);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelContatoDois">
              <Form.Label>Contato Celular 2</Form.Label>
              <Form.Control
                name="telContatoDois"
                placeholder=""
                value={telContatoDois ? telContatoDois : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTelContatoDois(maskTel(e.target.value));
                }}
                maxLength={14}
                minLength={13}
              ></Form.Control>
              <div style={{ marginTop: '1%' }}>
                <Switch
                  checked={smsAgendamentoViagemContatoDois}
                  name="SMSAgendamentoDois"
                  id="SMSAgendamentoDois"
                  type="switch"
                  label="Enviar SMS no agendamento da viagem"
                  onChange={() => {
                    setSmsAgendamentoViagemContatoDois(!smsAgendamentoViagemContatoDois);
                  }}
                />
              </div>
              <div>
                <Switch
                  checked={smsInicioViagemContatoDois}
                  name="SMSInicioDois"
                  id="SMSInicioDois"
                  type="switch"
                  label="Enviar SMS no começo da viagem"
                  onChange={() => {
                    setSmsInicioViagemContatoDois(!smsInicioViagemContatoDois);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelContatoTres">
              <Form.Label>Contato Celular 3</Form.Label>
              <Form.Control
                name="telContatoTres"
                placeholder=""
                value={telContatoTres ? telContatoTres : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTelContatoTres(maskTel(e.target.value));
                }}
                maxLength={14}
                minLength={13}
              ></Form.Control>
              <div style={{ marginTop: '1%' }}>
                <Switch
                  checked={smsAgendamentoViagemContatoTres}
                  name="SMSCAgendamentoTres"
                  id="SMSCAgendamentoTres"
                  type="switch"
                  label="Enviar SMS no agendamento da viagem"
                  onChange={() => {
                    setSmsAgendamentoViagemContatoTres(!smsAgendamentoViagemContatoTres);
                  }}
                />
              </div>
              <div>
                <Switch
                  checked={smsInicioViagemContatoTres}
                  name="SMSComecoTres"
                  id="SMSComecoTres"
                  type="switch"
                  label="Enviar SMS no começo da viagem"
                  onChange={() => {
                    setSmsInicioViagemContatoTres(!smsInicioViagemContatoTres);
                  }}
                />
              </div>
            </Form.Group>
          </Form.Row>

          <ButtonsForm />
        </Form>
      </div>
    </div>
  );
};

export default CadastroPontos;
