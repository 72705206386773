/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Form } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import checaCPF from '../../util/checaCpf';
import { cpfMask, soNumeros } from '../../util/Validacoes/Validacoes';
import '../ConsultarPesquisasLancadas/styles.css';
import ListagemConsultaVeiculo from './ListagemConsultarVeiculo';
import { VeiculoTypeGrid } from '../../Types/Veiculos/VeiculoTypeGrid';
import { convertCompilerOptionsFromJson, convertToObject } from 'typescript';
import { Tela } from '../../components/Tela';

type ListagemConsulta = {
  atualizacao: boolean;
  bloqueado: boolean;
  contrato: string;
  idTecnologia: number;
  idVeiculo: number;
  mapa: boolean;
  numeroEquipamento: string;
  placa: string;
  razaoSocialCliente: string;
  tecnologia: string;
  tipoFrota: string;
};

export type FiltroVeiculo = {
  idCliente: number;
  idTecnologia: number;
  idUsuario: number;
  numeroEquipamento: string | null;
  pagina: number;
  placaVeiculo: string | null;
  qtdRegistros: number;
};

type ListagemUsuariosGerenciadores = {
  usuCdId: number;
  nome: string;
  ativo: boolean;
};

type ListagemTecnologias = {
  terCdId: number;
  terTxNome: string;
  terBlAtivo: boolean;
};

const ConsultarVeiculo = () => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [listaTecnologias, setListaTecnologias] = useState<ListagemTecnologias[]>([]);
  const [listaUsuariosGerenciadores, setListaUsuariosGerenciadores] = useState<
    ListagemUsuariosGerenciadores[]
  >([]);

  const [empresa, setEmpresa] = useState('');
  const [tecnologia, setTecnologia] = useState('');
  const [usuario, setUsuario] = useState('');
  const [model, setModel] = useState<FiltroVeiculo>({} as FiltroVeiculo);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [isPlacaRegistered, setIsPlacaRegistered] = useState(true);
  const [isNumeroEquipamentoRegistered, setIsNumeroEquipamentoRegistered] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [historicosPerPage, setHistoricosPerPage] = useState(10);
  const indexOfLastPost = currentPage * historicosPerPage;
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const currentVeiculos = dataResults?.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
        console.log(listaAtivos);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = () => {
      axios.get('/usuario/gerenciador').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaUsuariosGerenciadores(listaAtivos);
        console.log(listaAtivos);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = () => {
      axios.get('/tecnologia-rastreamento').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.terBlAtivo === true);
        setListaTecnologias(listaAtivos);
      });
    };
    loadData();
  }, []);

  const buscar = async (event: any) => {
    event.preventDefault();
    if (model.placaVeiculo) {
      if (isPlacaRegistered === false) {
        addToast({
          title: 'Erro',
          description: 'Placa não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.numeroEquipamento) {
      if (isNumeroEquipamentoRegistered === false) {
        addToast({
          title: 'Erro',
          description: 'Nº Equipamento não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    try {
      const response = await api.get(`/veiculo/consulta-gerenciar-veiculo`, {
        params: {
          ...model,
        },
      });
      console.log(response);
      if (response.status === 200) {
        setCadastroRealizado(true);
        setDataResults(response.data);
        if (response.data.length === 0) {
          console.log(response);
          addToast({
            title: 'Erro',
            description: 'Não existe resultado para esse filtro!',
            type: 'error',
          });
          event.stopPropagation();
          return;
        } else {
          history.push({
            pathname: '/admin/listar/veiculos',
            state: { listagem: response.data, model: model },
          });
          return;
        }
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const verifyIfPlacaIsRegistered = async (value: string) => {
    if (model.idCliente) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?idCliente=${model.idCliente}&placaVeiculo=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegistered(true);
        } else {
          setIsPlacaRegistered(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    } else {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?placaVeiculo=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegistered(true);
        } else {
          setIsPlacaRegistered(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfNumeroEquipamentoIsRegistered = async (value: string) => {
    if (model.idCliente) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?idCliente=${model.idCliente}&numeroEquipamento=${value}`
        );
        if (response.data === true) {
          setIsNumeroEquipamentoRegistered(true);
        } else {
          setIsNumeroEquipamentoRegistered(false);
        }
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    } else {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?numeroEquipamento=${value}`
        );
        if (response.data === true) {
          setIsNumeroEquipamentoRegistered(true);
        } else {
          setIsNumeroEquipamentoRegistered(false);
        }

        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  return (
    <Tela nome="Veículo" caminho="Cadastro > Gerenciamento de Clientes > Veículo">
      {/* TODO : Trocar para Listagem de Veiculos */}
      {/* {cadastroRealizado ? (
          <ListagemConsultaVeiculo dataResults={dataResults} setDataResults={setDataResults} />
        ) : ( */}
      <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
        <Form.Group className="row-modal">
          <Form.Label>Cliente</Form.Label>

          <Dropdown
            style={{ width: '100.4%' }}
            value={empresa}
            options={listaClientes}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                idCliente: e.target.value,
              });

              setEmpresa(e.target.value);
            }}
            optionLabel="razaoSocial"
            optionValue="id"
            showClear
            filter
            className="multiselect-custom"
            id="empresa"
          />
        </Form.Group>

        {/* Lista de Usuários Gerenciadores */}

        <Form.Row className="row-container-modal">
          <Form.Group className="row-modal">
            <Form.Label>Grupo de Usuários</Form.Label>
            <Dropdown
              style={{ width: '100%' }}
              value={usuario}
              options={listaUsuariosGerenciadores}
              placeholder="Selecione"
              onChange={(e: any) => {
                setModel({
                  ...model,
                  idUsuario: e.target.value,
                });

                setUsuario(e.target.value);
              }}
              optionLabel="nome"
              optionValue="usuCdId"
              showClear
              filter
              className="multiselect-custom"
              id="grupoUsuario"
            />
          </Form.Group>
        </Form.Row>

        {/* Lista de Tecnologias - Pegar no cad de veiculo */}
        <Form.Group>
          <Form.Label>Tecnologia</Form.Label>
          <Dropdown
            style={{ width: '100%' }}
            value={tecnologia}
            options={listaTecnologias}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                idTecnologia: e.target.value,
              });

              setTecnologia(e.target.value);
            }}
            optionLabel="terTxNome"
            optionValue="terCdId"
            showClear
            filter
            className="multiselect-custom"
            id="tecnologia"
          />
        </Form.Group>

        {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}

        <Form.Group className="row-modal">
          <Form.Label>Placa</Form.Label>

          <Form.Control
            onChange={(e) => {
              if (e.target.value === '') {
                setModel({
                  ...model,
                  placaVeiculo: null,
                });
              } else {
                setModel({
                  ...model,
                  placaVeiculo: e.target.value,
                });
                // verifyIfPlacaIsRegistered(e.target.value);
              }
            }}
            onBlur={() => {
              if (!model.placaVeiculo) {
                return;
              }
              verifyIfPlacaIsRegistered(model.placaVeiculo);
            }}
            value={model?.placaVeiculo ? model?.placaVeiculo : ''}
            type="text"
            id="placaVeiculo"
            name="placaVeiculo"
            placeholder=""
            style={{ width: '100%' }}
          />
        </Form.Group>

        {/* Validação só numero : FEITO! */}

        <Form.Group className="row-modal">
          <Form.Label>Nº Equipamento</Form.Label>

          <Form.Control
            onChange={(e) => {
              if (e.target.value === '') {
                setModel({
                  ...model,
                  numeroEquipamento: null,
                });
              } else {
                setModel({
                  ...model,
                  numeroEquipamento: soNumeros(e.target.value),
                });
                // verifyIfNumeroEquipamentoIsRegistered(e.target.value);
              }
            }}
            onBlur={() => {
              if (!model.numeroEquipamento) {
                return;
              }
              verifyIfNumeroEquipamentoIsRegistered(model.numeroEquipamento);
            }}
            value={model?.numeroEquipamento ? model?.numeroEquipamento : ''}
            type="text"
            id="numeroEquipamento"
            name="numeroEquipamento"
            placeholder=""
            style={{ width: '100%' }}
          />
        </Form.Group>

        <ButtonsForm />
      </Form>
    </Tela>
  );
};

export default ConsultarVeiculo;
