/* eslint-disable */
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { useHistory } from 'react-router-dom';
import { Form, Col } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import checaCPF from '../../util/checaCpf';
import { useToast } from '../../hooks/Toast';
import { cpfMask, validaNome } from '../../util/Validacoes/Validacoes';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { soLetras } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';

function CadastroBlackListSeguradora() {
  const history = useHistory();
  const { addToast } = useToast();
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [motivo, setMotivo] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isNomeValid, setIsNomeValid] = useState(false);
  const [isValidMotivo, setIsValidMotivo] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [cpfErro, setCpfErro] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleRegister = async (event: any) => {
    event.preventDefault();
    if (!cpf || !nome || !motivo || !isValid || !isValidMotivo || !isNomeValid) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os campos obrigatórios.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const data = {
      cpf: cpf,
      nome: nome,
      motivo: motivo,
      ativo: true,
    };

    try {
      await axios.post('/blacklist', data);
      setIsDialogVisible(true);
    } catch {
      setIsDialogVisible(false);
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar!.',
        type: 'error',
      });
      return;
    }
  };

  const verificaCpfBlackList = async () => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });
      if (response.data) {
        setCpfErro(true);
      } else {
        setCpfErro(false);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Blacklist cadastrada com sucesso!"
        footer={<Button onClick={() => setIsDialogVisible(false)}>OK</Button>}
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        onHide={() => {
          history.goBack();
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">BlackList Seguradoras</h2>
          <h6 className="subtitulo">{'Cadastro > BlackList Seguradora'}</h6>
        </div>
        <div className="painel">
          <Form noValidate validated={isFormInvalid} onSubmit={handleRegister}>
            <Form.Group as={Col} controlId="formGrid" style={{ marginLeft: '-15px' }}>
              <Form.Label className="required">CPF</Form.Label>
              <Form.Control
                value={cpf}
                onChange={(event: any) => {
                  if (checaCPF(event.target.value) === false) {
                    setCpf(cpfMask(event.target.value));
                    setIsValid(false);
                  } else {
                    setCpf(cpfMask(event.target.value));
                    setIsValid(true);
                  }
                }}
                id="cpf"
                name="cpf"
                placeholder=""
                required
                title="Cpf inválido"
                style={{ width: '41%' }}
                maxLength={14}
                minLength={14}
                onBlur={verificaCpfBlackList}
              />
              {cpfErro && (
                <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
                  Favor entrar em contato com a Golden Service (21) 3644-7000.
                </p>
              )}
              {!isValid && cpf.length > 1 && (
                <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '-15px' }}>
              <Form.Label className="required">Nome</Form.Label>
              <Form.Control
                value={nome}
                onChange={(event) => {
                  event.preventDefault();
                  setNome(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomeValid(true);
                  } else {
                    setIsNomeValid(false);
                  }
                }}
                id="nome"
                name="nome"
                placeholder=""
                required
                type="text"
                title="Preencha com dois nomes"
                style={{ width: '100%' }}
                maxLength={128}
              />
              {!isNomeValid && nome.length > 1 && (
                <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>Nome inválido</p>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '-15px' }}>
              <Form.Label className="required">Motivo</Form.Label>
              <Form.Control
                value={motivo}
                onChange={(event) => {
                  event.preventDefault();
                  setMotivo(event.target.value);
                  if (motivo.length === 0) {
                    setIsValidMotivo(true);
                  } else {
                    if (motivo.length <= 2) {
                      setIsValidMotivo(false);
                    } else {
                      setIsValidMotivo(true);
                    }
                  }
                }}
                id="motivo"
                name="motivo"
                placeholder=""
                required
                type="text"
                minLength={3}
                maxLength={512}
              />
              {!isValidMotivo && motivo.length > 0 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Motivo inválido, digite 3 caracteres ou mais
                </p>
              )}
            </Form.Group>

            <ButtonsForm />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default CadastroBlackListSeguradora;
