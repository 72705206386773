/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { RotaType } from '../../api/model/RotaType';
import { getCookieSessionData } from '../../services/cookieService';
import { Container, Table, Button, Col, Form, Navbar, Tabs, Tab, Modal } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ParadaType } from '../../api/model/ParadaType';
import { useToast } from '../../hooks/Toast';
import { Tela } from '../../components/Tela';
import { ContainerButtons, SecundaryButton } from '../../components/ButtonsForm/styles';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const Paradas = () => {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const { addToast } = useToast();
  const [rotas, setRotas] = useState<RotaType[]>([]);
  const [paradas, setParadas] = useState<any[]>([]);
  const [sequencia, setSequencia] = useState('');
  const [motivo, setMotivo] = useState('');
  const [local, setLocal] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [ativo, setAtivo] = useState(false);
  const [idCodigoIbge, setIdCodigoIbge] = useState('');
  const [cep, setCep] = useState('');
  const [idParada1, setIdParada1] = useState();
  const [idParada2, setIdParada2] = useState();
  const [sequencia1, setSequencia1] = useState('');
  const [sequencia2, setSequencia2] = useState('');

  const history = useHistory();

  const { id }: any = useParams(); // id rota
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [parada, setParada] = useState<number>();
  const [nomeParada, setNomeParada] = useState<string>();

  useEffect(() => {
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }], 'inserir');
  }, [TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/rota');
      setRotas(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/rota/parada-por-rota?idRota=${id}`);
      setParadas(response.data);
    };
    fetchData();
  }, []);

  const subtrairPosicao = (filteredParada: any, idParada: any) => {
    const listaAtualizada = paradas.map((p) => {
      if (p.sequencia === filteredParada) {
        if (filteredParada === 0 || filteredParada < 0 || filteredParada === 1) {
          return {
            ...p,
          };
        }
        return {
          ...p,
          sequencia: p.sequencia - 1,
        };
      } else {
        return {
          ...p,
          sequencia: p.sequencia === filteredParada - 1 ? p.sequencia + 1 : p.sequencia,
        };
      }
    });
    setParadas(listaAtualizada);
  };

  const somarPosicao = (filteredParada2: any, idParada2: any) => {
    const listaSequencia = paradas.map((filteredSequencia2) => filteredSequencia2.sequencia);

    var maior = 0;
    for (var i = 0; i < listaSequencia.length; i++) {
      if (listaSequencia[i] > maior) {
        maior = listaSequencia[i];
      }
    }
    const listaAtualizada = paradas.map((p) => {
      if (p.sequencia == filteredParada2 && p.sequencia != maior) {
        return {
          ...p,
          sequencia: p.sequencia + 1,
        };
      } else {
        return {
          ...p,
          sequencia: p.sequencia === filteredParada2 + 1 ? p.sequencia - 1 : p.sequencia,
        };
      }
    });
    setParadas(listaAtualizada);
  };

  const atualizacaoSeq1 = async (sequencia1: any, sequencia2: any, idParada1: any) => {
    const idParadaMudou = paradas
      .filter((fp) => fp.sequencia === sequencia1)
      .map((mp) => mp.idParada)
      .shift();

    try {
      await axios.put(
        `/parada/atualizarSequencia?idParada1=${idParada1}&Sequencia1=${sequencia1}&idParada2=${idParadaMudou}&Sequencia2=${sequencia2}`
      );
      addToast({
        title: 'Sucesso!',
        description: 'Atualização de sequências efetuada com sucesso.',
        type: 'success',
      });
      setTimeout(function () {
        history.push(`/rotaeditarparada/${id}`);
      }, 1500);
    } catch (err: any) {
      if (idParada2 === undefined) {
        addToast({
          title: 'Erro!',
          description: 'Não existe sequência anterior a esta.',
          type: 'error',
        });
        return;
      }
      addToast({
        title: 'Erro!',
        description: 'Erro ao atualizar.',
        type: 'error',
      });
      return;
    }
  };

  const atualizacaoSeq2 = async (sequencia1: any, sequencia2: any, idParada1: any) => {
    const idParadaMudou = paradas
      .filter((fp) => fp.sequencia === sequencia1)
      .map((mp) => mp.idParada)
      .shift();
    try {
      await axios.put(
        `/parada/atualizarSequencia?idParada1=${idParada1}&Sequencia1=${sequencia1}&idParada2=${idParadaMudou}&Sequencia2=${sequencia2}`
      );
      addToast({
        title: 'Sucesso!',
        description: 'Atualização de sequências efetuada com sucesso.',
        type: 'success',
      });
      setTimeout(function () {
        history.push(`/rotaeditarparada/${id}`);
      }, 1500);
    } catch {
      if (idParada2 === undefined) {
        addToast({
          title: 'Erro!',
          description: 'Não existe sequência posterior a esta.',
          type: 'error',
        });
        return;
      }
      addToast({
        title: 'Erro!',
        description: 'Erro ao atualizar.',
        type: 'error',
      });
      return;
    }
  };

  const handleDelete = async (idParada: any) => {
    const response = await axios.get(`/parada/${idParada}`);
    setAtivo(response.data.ativo);
    setMotivo(response.data.motivo);
    setSequencia(response.data.sequencia);
    setLogradouro(response.data.logradouro);
    setBairro(response.data.bairro);
    setCidade(response.data.cidade);
    setComplemento(response.data.complemento);
    setIdCodigoIbge(response.data.idCodigoIbge);
    setLocal(response.data.local);
    setNumero(response.data.numero);
    setCep(response.data.cep);

    const dataAtivo = {
      sequencia: sequencia,
      motivo: motivo,
      local: local,
      logradouro: logradouro,
      cep: cep,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      ativo: false,
      idCodigoIbge: idCodigoIbge,
      clienteId: {
        id: clienteId,
      },
    };
    try {
      await axios.delete(`/parada/${idParada}`, {
        data: dataAtivo,
      });
      addToast({
        title: 'Sucesso!',
        description: 'Exclusão efetuada com sucesso.',
        type: 'success',
      });
      setShow(false);
      setTimeout(function () {
        history.push(`/rota/1`);
      }, 1500);
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao excluir.',
        type: 'error',
      });
      return;
    }
  };

  function paradaEditar(id: number) {
    history.push(`/paradaeditar/${id}`);
  }

  function paradaCadastro(id: number) {
    history.push(`/paradacadastro/${id}`);
  }

  return (
    <Tela nome="Rotas" caminho="Cadastro > Rotas">
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${nomeParada}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(parada)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <Tabs defaultActiveKey="parada" id="uncontrolled-tab-example" className="mt-4">
        <Tab
          eventKey="editarrota"
          title="Dados da Rota"
          onEnter={() => history.push(`/rota-editar-cadastrar/${id}`)}
        >
          {/* <EditarRotas /> */}
        </Tab>
        <Tab eventKey="parada" title="Paradas">
          <Navbar style={{ width: '100%' }}>
            {canInsert ? (
              <Button
                className="bg-dark mt-4"
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
                onClick={() => paradaCadastro(id)}
              >
                +
              </Button>
            ) : (
              <Button
                className="bg-dark mt-4 disabled-create-button"
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '20px',
                }}
                disabled={true}
              >
                +
              </Button>
            )}
          </Navbar>

          <Table style={{ marginTop: '30px' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Motivo</th>
                <th>Local</th>
                <th>Endereço</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody key={id}>
              {paradas &&
                paradas
                  .sort(function (a: any, b: any) {
                    return a.sequencia - b.sequencia;
                  })
                  .map((p, index) => (
                    <tr key={index}>
                      <td>{p.sequencia}</td>
                      {p.motivo === '1' && <td>Alimentação</td>}
                      {p.motivo === '2' && <td>Descanso</td>}
                      {p.motivo === '3' && <td>Entrega</td>}
                      {p.motivo === '4' && <td>Coleta</td>}
                      {p.motivo === '5' && <td>Fiscalização</td>}
                      {p.motivo === '9' && <td>Outros</td>}
                      {p.motivo === '' && <td></td>}
                      <td>{p.local ? p.local : ''}</td>
                      <td>
                        {p?.logradouro}, {p?.numero} <br key="break" /> {p?.bairro}, {p?.cidade}
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            width: 20,
                            height: 20,
                            padding: 0,
                            marginRight: 15,
                            marginBottom: 2,
                          }}
                        >
                          <i
                            title="Subir posição"
                            onClick={() => {
                              atualizacaoSeq1(p.sequencia - 1, p.sequencia, p.idParada);
                              subtrairPosicao(p.sequencia, p.idParada);
                            }}
                            className="pi pi-chevron-up"
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            width: 20,
                            height: 20,
                            padding: 0,
                            marginRight: 15,
                            marginBottom: 2,
                          }}
                        >
                          <i
                            title="Descer posição"
                            onClick={() => {
                              atualizacaoSeq2(p.sequencia + 1, p.sequencia, p.idParada);

                              somarPosicao(p.sequencia, p.idParada);
                            }}
                            className="pi pi-chevron-down"
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            width: 20,
                            height: 20,
                            padding: 0,
                            marginRight: 15,
                            marginBottom: 2,
                          }}
                        >
                          <i
                            title="Deletar parada"
                            key={p.idParada}
                            onClick={() => {
                              setParada(p.idParada);
                              setShow(true);
                              setNomeParada(p.local);
                            }}
                            className="pi pi-trash"
                          ></i>
                        </button>
                      </td>
                      <td>
                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            width: 20,
                            height: 20,
                            padding: 0,
                            marginRight: 15,
                            marginBottom: 2,
                          }}
                        >
                          {console.log({ p })}
                          <i
                            title="Editar parada"
                            key={p.idParada}
                            onClick={() => paradaEditar(p.idParada)}
                            className="pi pi-pencil"
                          ></i>
                        </button>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
            </tbody>
          </Table>
          <ContainerButtons>
            <SecundaryButton
              type="button"
              onClick={() => {
                history.replace('/rota/1');
              }}
            >
              Voltar
            </SecundaryButton>
          </ContainerButtons>
        </Tab>
      </Tabs>
    </Tela>
  );
};

export default Paradas;
