/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Container, Form, Col, Button, Navbar, InputGroup, Modal, Spinner } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import { useToast } from '../../hooks/Toast';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { validaEmail, validaSenha } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../services/cookieService';
import { MenuPerfilType } from '../../Types/MenuPerfil';
import { Tela } from '../../components/Tela';
import { dataConverter } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { deleteUser, updateUser } from '../../services/user';
import { profileMenuGr } from '../../services/profileMenu';

function EditarUsuarioGerenciador() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const { id }: any = useParams();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogExclusaoOK, setIsDialogExclusaoOK] = useState(false);
  const { addToast } = useToast();

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [senhaValida, setSenhaValida] = useState(true);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidNome, setIsValidNome] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidSenha, setIsValidSenha] = useState(true);
  const [ativo, setAtivo] = useState(true);
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [dataCadastro, setDataCadastro] = useState('');
  const [usuarioIdKeycloak, setUsuarioIdKeycloak] = useState('');
  const { usuarioId } = getCookieSessionData().usuarioVO;
  const [loading, setLoading] = useState(false);
  const [profileLogin, setProfileLogin] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [listaMenuPerfil, setListaMenuPerfil] = useState<MenuPerfilType[]>([]);
  const [selectedMenuPerfil, setSelectedMenuPerfil] = useState<number>();
  const location: {
    state: string;
  } = useLocation();
  const { state } = location;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [validacaoEmail, setValidacaoEmail] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.USUARIO.GERENCIADOR] }], 'excluir');
    rolePermission([{ ADMIN: [ADMIN.USUARIO.GERENCIADOR] }], 'editar');
  }, [ADMIN, rolePermission]);

  const buscaListaMenuPerfis = async () => {
    setLoading(true);
    try {
      setProfileLogin(true);
      profileMenuGr()
        .then((res: any) => {
          const getGrProfiles = res.data.filter((profile: any) => !profile.client_id);

          setListaMenuPerfil(orderBy(getGrProfiles, 'mep_tx_nome_menu'));
        })
        .catch(() => setListaMenuPerfil([]))
        .finally(() => setProfileLogin(false));
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    buscaListaMenuPerfis();
  }, []);

  // const validaEmailExistente = async () => {
  //   try {
  //     const response = await axios.get(`/usuario/validacaoTrocaEmail`, {
  //       params: {
  //         email: email,
  //         login: login,
  //       },
  //     });

  //     //console.log(response.data);
  //     if (response.data === true) {
  //       setValidacaoEmail(true);
  //       // const alerta = alert('Email já existe');
  //       addToast({
  //         title: 'Erro',
  //         description: 'E-mail já existe!',
  //         type: 'error',
  //       });
  //       return;
  //     } else {
  //       setValidacaoEmail(false);
  //       setEmail(email);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/usuario/gerenciador/${id}`);
      setLogin(response.data.login);
      setEmail(response.data.email);
      setSenha(response.data.senha);
      setNovaSenha(response.data.senha);
      setSelectedMenuPerfil(response.data.idMenuPerfil);
      setNome(response.data.nome);
      setAtivo(response.data.ativo);
      setDataCadastro(response.data.usuDtHrCadastro);
      setUsuarioIdKeycloak(response.data.usuarioIdKeycloak);
      if (response.data === '') {
        try {
          const response = await axios.get(`/usuario/reutilizar-usuario?login=${state}`);
          console.log(response.data);
          setLogin(response.data.login);
          setSenha(response.data.senha);
          setSelectedMenuPerfil(response.data.idMenuPerfil);
          setEmail(response.data.email);
          setNovaSenha(response.data.senha);
          setNome(response.data.nome);
          setAtivo(true);
          setDataCadastro(response.data.usuDtHrCadastro);
          setUsuarioIdKeycloak(response.data.usuarioIdKeycloak);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    fetchData();
  }, [id]);

  const handleEdit = async (event: any) => {
    event.preventDefault();
    if (
      !selectedMenuPerfil ||
      !nome ||
      !login ||
      !senha ||
      !isValidLogin ||
      !isValidNome ||
      !isValidSenha ||
      !novaSenha
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const data = {
      login: login.toLowerCase(),
      senha: novaSenha,
      email,
      nome,
      idMenuPerfil: selectedMenuPerfil,
      ativo,
      usuResponsavelCadastro: usuarioId,
      usuDtHrUltimaAlteracao: dataConverter(new Date().toISOString()),
      usuDtHrCadastro: dataCadastro,
      access_token: getCookieSessionData().access_token,
      roleUser: 'admin',
      usuarioIdKeycloak,
      usuCdId: id,
    };
    try {
      setLoading(true);
      if (senhaValida == true && validacaoEmail == false) {
        await updateUser(id, data);

        setIsDialogCadOK(true);
        setLoading(false);
      } else {
        if (senhaValida == false) {
          addToast({
            title: 'Erro',
            description: 'Senhas diferentes!',
            type: 'error',
          });
          setLoading(false);
          return;
        }
        addToast({
          title: 'Erro',
          description: 'E-mail já existe!',
          type: 'error',
        });
        setLoading(false);
        return;
      }
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao atualizar!',
        type: 'error',
      });
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.put(`user/delete/`, {
        idUsuario: Number(id),
      });

      setIsDialogExclusaoOK(true);
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir!',
        type: 'error',
      });
      return;
    }
    handleClose();
  };

  const gerenciarSenha = (event: any) => {
    if (senha != null) {
      setSenha(event.target.value);
      setNovaSenha('');
      if (validaSenha(event.target.value)) {
        setIsValidSenha(true);
      } else {
        setIsValidSenha(false);
      }
    }
  };

  return (
    <Tela
      nome="Usuário"
      caminho="Alterar > Usuário Gerenciador"
      loading={loading}
      setLoading={setLoading}
      canDelete={canDelete}
      onClickDelete={() => {
        handleShow();
      }}
    >
      <Dialog
        header="Usuário alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-gerenciadores/1');
        }}
      />

      <Dialog
        header="Usuário excluído com sucesso!"
        footer={<Button onClick={() => setIsDialogExclusaoOK(false)}>Ok</Button>}
        visible={isDialogExclusaoOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-gerenciadores/1');
        }}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${nome}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
      <Form noValidate validated={isFormInvalid} onSubmit={handleEdit}>
        <Form.Group
          as={Col}
          controlId="formGridAddress1"
          style={{ marginLeft: '-14px', marginTop: '2%' }}
        >
          <Form.Label>Login</Form.Label>
          <Form.Control
            value={login}
            onChange={(event) => {
              event.preventDefault();
              setLogin(event.target.value);
              if (login.length < 2) {
                setIsValidLogin(false);
              } else {
                setIsValidLogin(true);
              }
            }}
            disabled
            style={{ width: '50.6%' }}
            minLength={3}
          />
          {!isValidLogin && (
            <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
              Nome inválido, digite 3 caracteres ou mais
            </p>
          )}
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Nome</Form.Label>

            <Form.Control
              value={nome}
              onChange={(event) => {
                event.preventDefault();
                setNome(event.target.value);
                if (nome.length < 2) {
                  setIsValidNome(false);
                }
                if (nome.length >= 2 || nome.length === 0) {
                  setIsValidNome(true);
                }
              }}
              required
              minLength={3}
            />
            {!isValidNome && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Perfil</Form.Label>

            <Form.Control
              value={selectedMenuPerfil}
              onChange={(event) => {
                setSelectedMenuPerfil(parseInt(event.target.value, 10));
              }}
              required
              as="select"
            >
              <option value="">
                {profileLogin && listaMenuPerfil.length === 0
                  ? 'Carregando perfis...'
                  : 'Selecione uma opção'}
              </option>
              {listaMenuPerfil &&
                listaMenuPerfil.map((each: any) => (
                  <option value={each.mep_cd_id}>{each.mep_tx_nome_menu}</option>
                ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Group as={Col} controlId="formGridEmail" style={{ marginLeft: '-14px' }}>
          <Form.Label>Email</Form.Label>

          <Form.Control
            value={email}
            onChange={(event) => {
              event.preventDefault();
              setEmail(event.target.value);
              if (validaEmail(event.target.value) || event.target.value.trim() === '') {
                setIsValidEmail(true);
              } else {
                setIsValidEmail(false);
              }
            }}
            // onBlur={() => validaEmailExistente()}
            style={{ width: '50.6%' }}
            type="email"
            // ref={ref}
          />
          {!isValidEmail && (
            <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
          )}
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={senha}
                onChange={(event) => gerenciarSenha(event)}
                required
                type={passwordShown ? 'text' : 'password'}
                style={{ borderRightColor: 'white' }}
                maxLength={10}
                minLength={5}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$"
                title="A senha deve ter entre 5 e 10 caracteres, contendo pelo menos uma letra e um número. Caracteres especiais são opcionais."
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity}
                    />
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity}
                    />
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {!isValidSenha && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Senha inválida! A senha deve ter entre 5 e 10 caracteres, contendo pelo menos uma
                letra e um número. Caracteres especiais são opcionais.
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Confirme a senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={novaSenha}
                onChange={(event: any) => {
                  setNovaSenha(event.target.value);
                  event.preventDefault();
                  if (event.target.value === senha) {
                    setSenhaValida(true);
                  } else {
                    setSenhaValida(false);
                  }
                  console.log(senhaValida);
                }}
                required
                type={passwordShown2 ? 'text' : 'password'}
                maxLength={10}
                minLength={5}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$"
                style={{ borderRightColor: 'white' }}
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown2 ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity2}
                    />
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity2}
                    />
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {senhaValida === false && (
              <p style={{ fontSize: '12px', color: 'red' }}>Senhas diferentes</p>
            )}
          </Form.Group>
        </Form.Row>
        {loading ? (
          <div style={{ marginTop: 30 }}>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto o cadastro está sendo atualizado...
            </p>
            <ButtonsForm canEdit={canEdit} />
          </div>
        ) : (
          <div style={{ marginTop: 30 }}>
            <ButtonsForm canEdit={canEdit} />
          </div>
        )}
      </Form>
    </Tela>
  );
}

export default EditarUsuarioGerenciador;
