import { useLocation } from 'react-router';
import { getCookieSessionData } from '../../services/cookieService';
import { obterData, obterHora } from '../../util/date';
import { handleRota } from '../../util/getPontosLista';
import { SMMapa } from './Mapa';

export const RelatorioXLSMapa = (data: SMMapa[], setExcelMapa: any, headerName: string) => {
  console.log(data);

  function separarPlacas(placas: string) {
    const [placaVeiculo, carreta1, carreta2] = placas.split(' ').map((p) => p.trim());
    return { placaVeiculo, carreta1, carreta2 };
  }
  const { usuarioVO } = getCookieSessionData();

  const excel = [
    {
      xSteps: 6,
      ySteps: 0,
      columns: [
        {
          title: headerName,
          widthPx: 3000,
          style: {
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },

    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Placas', width: { wch: 10 } },
        { title: 'Tecnologia', width: { wch: 10 } },
        { title: 'Posição(Coordenada)', width: { wch: 30 } },
        { title: 'Posição(Data)', width: { wch: 20 } },
        { title: 'Posição(Hora)', width: { wch: 20 } },
        { title: 'Localização', width: { wch: 35 } },
        { title: 'Baú', width: { wch: 10 } },
        { title: 'Carreta(1)', width: { wch: 10 } },
        { title: 'Carreta(2)', width: { wch: 10 } },
        { title: 'Rota', width: { wch: 20 } },
        { title: 'Status', width: { wch: 20 } },
        { title: 'Operação', width: { wch: 20 } },
        { title: 'Transportador', width: { wch: 30 } },
      ],
      data: data.map((each) => [
        { value: each.placas ? separarPlacas(each.placas).placaVeiculo : '' }, // Placas
        { value: each.nomeTecnologiaRastreamento || '' }, // Tecnologia
        { value: `${each.latitudeAtual}, ${each.longitudeAtual}` }, // Posição(Coordenada)
        { value: each.dtHrUltimaPosicao ? obterData(each.dtHrUltimaPosicao) : '' }, // Posição(Data)
        { value: each.dtHrUltimaPosicao ? obterHora(each.dtHrUltimaPosicao) : '' }, // Posição(Hora)
        {
          value:
            each.localizacao === null
              ? ''
              : each.localizacao.length > 35
              ? `${each.localizacao.slice(0, 35)}...`
              : each.localizacao,
        }, // Localização
        { value: each.bau === null || each.bau === 'N/I' ? 'N/I' : each.bau }, // Baú
        { value: each.placas ? separarPlacas(each.placas).carreta1 : '' }, // Carreta(1)
        { value: each.placas ? separarPlacas(each.placas).carreta2 : '' }, // Carreta(2)
        {
          value:
            each.rota === null
              ? ''
              : handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota).length > 25 && each.idSm
              ? `${handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota).slice(0, 25)}...`
              : handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota),
        }, // Rota
        { value: each.statusViagem || '' }, // Status
        { value: each.operacao || '' }, // Operação
        { value: usuarioVO.isEmbarcador ? each.nomeTransportador : each.razaoSocialEmbarcador }, // Transportador
      ]),
    },
  ];

  setExcelMapa(excel);
  console.log('excel', excel);
};

export default RelatorioXLSMapa;
