import { log } from 'console';
/* eslint-disable */

import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import history from './history';
import { AuthResponse } from '../api/model/AuthResponse';
import { confirmLoginUser, postLoginUser } from '../api/authAPI';
import { getCookiePermissoesData } from './permissionamento';

export type Role_Realm_Access =
  | 'offline_access'
  | 'app-admin'
  | 'uma_authorization'
  | 'app-user'
  | 'app-corretora'
  | 'app-seguradora';
export type Role = 'admin' | 'user' | 'corretora' | 'seguradora';
export type Roles_Account = 'manage-account' | 'manage-account-links' | 'view-profile';

type AccessToken = {
  exp: number;
  sub: string;
  realm_access: {
    roles: Role_Realm_Access[];
  };
  resource_access: {
    goldenservice: {
      roles: Role[];
    };
  };
  account: {
    roles: Roles_Account[];
  };
  name: string;
};

const cookies = new Cookies();

export const loginUser = async (
  login: string,
  senha: string,
  role: string,
  codigo: number | null
) => {
  const response = await postLoginUser(login, senha, role, codigo);
  return response;
};

export const confirmUser = async (
  login: string,
  senha: string,
  role: string,
  codigo: number | null
) => {
  const response = await confirmLoginUser(login, senha, role, codigo);
  return response;
};

export const saveCookieSessionData = (authresponse: AuthResponse) => {
  const currentTime = new Date();

  const expirationTime = new Date(currentTime.getTime() + 8 * 60 * 60 * 1000);

  cookies.set(
    'userSession',
    {
      access_token: authresponse.access_token,
      usuarioVO: {
        clienteId: authresponse.usuarioVO.clienteId,
        cnpj: authresponse.usuarioVO.cnpj,
        clienteRazaoSocial: authresponse.usuarioVO.clienteRazaoSocial,
        isEmbarcador: authresponse.usuarioVO.isEmbarcador,
        corretoraId: authresponse.usuarioVO.corretoraId,
        corretoraNome: authresponse.usuarioVO.corretoraNome,
        login: authresponse.usuarioVO.login,
        nome: authresponse.usuarioVO.nome,
        perfil: authresponse.usuarioVO.perfil,
        role: authresponse.usuarioVO.role,
        seguradoraId: authresponse.usuarioVO.seguradoraId,
        seguradoraNome: authresponse.usuarioVO.seguradoraNome,
        usuarioId: authresponse.usuarioVO.usuarioId,
      },
    },
    { path: '/', expires: expirationTime }
  );
};

export const getCookieSessionData = (): any => {
  const sessionCookieData = cookies.get('userSession') ?? '{}';
  // const parsedSessionData: AuthResponse = JSON.parse(sessionCookieData);
  //  return parsedSessionData;
  return sessionCookieData;
};

export const getAccessTokenDecodedByCookie = (): AccessToken => {
  const sessionCookieData = getCookieSessionData();
  const tokenDecoded: any = jwtDecode(sessionCookieData.access_token);
  const formatedTokenDecoded = {
    exp: tokenDecoded.exp,
    sub: tokenDecoded.sub,
    realm_access: tokenDecoded.roles,
    resource_access: {
      goldenservice: tokenDecoded.resource_access['goldenservice-api'],
    },
    account: tokenDecoded.account,
    name: tokenDecoded.name,
  };
  return formatedTokenDecoded;
};

export const isTokenValid = () => {
  try {
    const { exp } = getAccessTokenDecodedByCookie();

    if (Date.now() <= exp * 1000) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const isAuthenticated = () => {
  const sessionCookieData = getCookieSessionData();

  return sessionCookieData.access_token; // && isTokenValid();
};

export const isAllowedByRole = (routeRoles: Role[] = []) => {
  const userToken = getAccessTokenDecodedByCookie();
  const userRoles = userToken.resource_access.goldenservice.roles;
  //     'console log 02 allowedByRole: ',
  //     routeRoles.some((role) => userRoles.includes(role))
  //   );
  return routeRoles.some((role) => userRoles.includes(role));
};

export const isAllowedByPermission = (permission: any, type: string) => {
  const permissions = getCookiePermissoesData();

  const {
    usuarioVO: { isEmbarcador, role },
  } = getCookieSessionData();

  if (permissions?.null?.idMenuGrupo === null) {
    return true;
  }

  if (!isEmbarcador && role === 'user') {
    try {
      const perm = permission[0].TRANSPORTADOR;
      console.log(perm);

      return perm.some((p: number) => {
        if (permissions[p] && permissions[p][type] === true) {
          return true;
        }
        return false;
      });
    } catch (error) {
      return true;
    }
  }

  if (isEmbarcador && role === 'user') {
    try {
      const perm = permission[0].EMBARCADOR;

      return perm.some((p: number) => {
        if (permissions[p] && permissions[p][type] === true) {
          return true;
        }
        return false;
      });
    } catch (error) {
      console.log(error);

      return true;
    }
  }

  if (role === 'admin') {
    try {
      const perm = permission[0].ADMIN;

      return perm.some((p: number) => {
        if (permissions[p] && permissions[p][type] === true) {
          return true;
        }
        return false;
      });
    } catch (e) {
      return true;
    }
  }
};

export const logout = () => {
  cookies.remove('userParticularidades');
  for (let i = 0; i < 14; i += 1) {
    cookies.remove(`permissao${i}`);
  }
  cookies.remove('userSession');

  history.push('/');

  // if (
  //   getCookieSessionData().usuarioVO.role == 'corretora' ||
  //   getCookieSessionData().usuarioVO.role == 'seguradora'
  // ) {
  //   cookies.remove('userSession');
  //   history.replace('/parceiros');
  // } else {
  //   cookies.remove('userSession');
  //   history.replace('/');
  // }
};

export const doLogin = () => {
  history.push('/carregando-permissoes');
};

export const other = () => {};
