/* eslint-disable */
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Form, Col, Button, InputGroup } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';
import { validaSenha, noArroba } from '../../../util/Validacoes/Validacoes';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../../services/cookieService';
import { Spinner } from 'react-bootstrap';
import { Tela } from '../../../components/Tela';
import { dataConverter } from '../../../util/date';
import { createUser } from '../../../services/user';

function CadastroUsuariosSeguradoras() {
  const history = useHistory();
  const { id }: any = useParams();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [senhaValida, setSenhaValida] = useState(true);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidNome, setIsValidNome] = useState(true);
  const [isValidSenha, setIsValidSenha] = useState(true);
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [nome, setNome] = useState('');
  const [validacaoLogin, setValidacaoLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmaSenha, setConfirmaSenha] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const seguradoraId = getCookieSessionData().usuarioVO.seguradoraId;
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;

  const roleLogado = getCookieSessionData().usuarioVO.role;

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const [isDialogRecuperarDados, setIsDialogRecuperarDados] = useState(false);
  const [idUsuarioRecuperado, setIdUsuarioRecuperado] = useState();

  const recuperarDadosUsuarioExistente = async () => {
    if (login === '') return;

    try {
      const response = await axios.get(`/usuario/reutilizar-usuario-seguradora?login=${login}`);
      if (response.data.ativo === false) {
        setIsDialogRecuperarDados(true);
        setIdUsuarioRecuperado(response.data.usuCdId);
      } else {
        setIsDialogRecuperarDados(false);
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const validaLoginExistente = async () => {
    if (login === '') return;

    try {
      const response = await axios.get(`/usuario/validacaoCriacaoLogin`, {
        params: {
          login: login,
        },
      });

      if (response.data === true) {
        setValidacaoLogin(true);
        addToast({
          title: 'Erro',
          description: 'Login já existe!',
          type: 'error',
        });
        return;
      } else {
        setValidacaoLogin(false);
        setLogin(login);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleRegister = async (event: any) => {
    event.preventDefault();
    if (!senha || !nome || !login || !confirmaSenha) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (roleLogado === 'admin') {
      const data = {
        ativo: true,
        login: login.toLowerCase(),
        senha: senha,
        nome: nome,
        usuResponsavelCadastro: idUsuario,
        usuDtHrUltimaAlteracao: dataConverter(new Date().toISOString()),
        usuDtHrCadastro: dataConverter(new Date().toISOString()),
        seguradoraId: +id,
        roleUser: 'seguradora',
      };
      try {
        setLoading(true);
        if (senhaValida && !validacaoLogin) {
          await createUser(data);
          setIsDialogCadOK(true);
          setLoading(false);
        } else {
          if (!senhaValida) {
            addToast({
              title: 'Erro',
              description: 'Senhas diferentes!',
              type: 'error',
            });

            setLoading(false);
            return;
          } else {
            addToast({
              title: 'Erro',
              description: 'Login já existe!',
              type: 'error',
            });

            setLoading(false);
            return;
          }
        }
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });

        setLoading(false);
        return;
      }
    } else {
      const data = {
        login: login.toLowerCase(),
        senha: senha,
        nome: nome,
        usuResponsavelCadastro: idUsuario,
        usuDtHrUltimaAlteracao: dataConverter(new Date().toISOString()),
        usuDtHrCadastro: dataConverter(new Date().toISOString()),
        seguradoraId: seguradoraId,
        roleUser: 'seguradora',
      };
      try {
        setLoading(true);
        if (senhaValida == true && validacaoLogin == false) {
          await createUser(data);
          setIsDialogCadOK(true);
          setLoading(false);
        } else {
          if (senhaValida == false) {
            addToast({
              title: 'Erro',
              description: 'Senhas diferentes!',
              type: 'error',
            });

            setLoading(false);
            return;
          } else {
            addToast({
              title: 'Erro',
              description: 'Login já existe!',
              type: 'error',
            });

            setLoading(false);
            return;
          }
        }
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });

        setLoading(false);
        return;
      }
    }
  };

  return (
    <Tela
      nome="Usuário"
      caminho="Cadastro > Usuário Seguradora"
      loading={loading}
      setLoading={setLoading}
    >
      <Dialog
        header="Usuário cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-seguradoras/1');
        }}
      />

      <Dialog
        header="Usuário inativo. Deseja recuperar dados?"
        footer={
          <>
            <Button
              style={{ width: 120 }}
              onClick={() =>
                history.push({
                  pathname: `/atualizacao-usuario-seguradoras/${idUsuarioRecuperado}`,
                  state: login,
                })
              }
            >
              Sim
            </Button>
            <Button style={{ width: 120 }} onClick={() => setIsDialogRecuperarDados(false)}>
              Não
            </Button>
          </>
        }
        visible={isDialogRecuperarDados}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogRecuperarDados(false);
        }}
      />

      <Form noValidate validated={isFormInvalid} onSubmit={handleRegister}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Login</Form.Label>
            <Form.Control
              value={login}
              onChange={(event) => {
                event.preventDefault();
                setLogin(noArroba(event.target.value));
                if (login.length < 2) {
                  setIsValidLogin(false);
                }
                if (login.length >= 2 || login.length === 0) {
                  setIsValidLogin(true);
                }
              }}
              required
              minLength={3}
              maxLength={100}
              onBlur={() => {
                recuperarDadosUsuarioExistente();
                validaLoginExistente();
              }}
            />
            {!isValidLogin && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Login inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Nome</Form.Label>

            <Form.Control
              value={nome}
              onChange={(event) => {
                event.preventDefault();
                setNome(event.target.value);
                if (nome.length < 2) {
                  setIsValidNome(false);
                }
                if (nome.length >= 2 || nome.length === 0) {
                  setIsValidNome(true);
                }
              }}
              required
              minLength={3}
              maxLength={100}
            />
            {!isValidNome && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={senha}
                onChange={(event) => {
                  event.preventDefault();
                  setSenha(event.target.value);
                  if (validaSenha(event.target.value)) {
                    setIsValidSenha(true);
                  } else {
                    setIsValidSenha(false);
                  }
                }}
                required
                type={passwordShown ? 'text' : 'password'}
                style={{ borderRightColor: 'white' }}
                maxLength={10}
                minLength={5}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$"
                title="A senha deve ter entre 5 e 10 caracteres, contendo pelo menos uma letra e um número. Caracteres especiais são opcionais."
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {!isValidSenha && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Senha inválida! A senha deve ter entre 5 e 10 caracteres, contendo pelo menos uma
                letra e um número. Caracteres especiais são opcionais.
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Confirme a senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={confirmaSenha}
                onChange={(event: any) => {
                  setConfirmaSenha(event.target.value);
                  event.preventDefault();
                  if (event.target.value === senha) {
                    setSenhaValida(true);
                  } else {
                    setSenhaValida(false);
                  }
                }}
                required
                type={passwordShown2 ? 'text' : 'password'}
                maxLength={10}
                minLength={5}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$"
                style={{ borderRightColor: 'white' }}
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown2 ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {senhaValida == false && senha.length === 8 && (
              <p style={{ fontSize: '12px', color: 'red' }}>Senhas diferentes</p>
            )}
          </Form.Group>
        </Form.Row>
        {loading ? (
          <div style={{ marginTop: 30 }}>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto o cadastro está sendo concluído...
            </p>
            <ButtonsForm />
          </div>
        ) : (
          <div style={{ marginTop: 30 }}>
            <ButtonsForm />
          </div>
        )}
      </Form>
    </Tela>
  );
}

export default CadastroUsuariosSeguradoras;
