/* eslint-disable */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import Switch from 'react-bootstrap/esm/Switch';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { CentroDistribuicaoTypes } from '../../api/Types/portariaGridTypes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import {
  maskCep,
  maskTel,
  validateLat,
  validateLng,
  validaEmail,
  soNumeros,
} from '../../util/Validacoes/Validacoes';
import { PaisType } from '../../api/model/PaisType';
import { getCookieSessionData } from '../../services/cookieService';
import { RegionalType } from '../../api/model/RegionalType';
import { useToast } from '../../hooks/Toast';
import api from '../../config/axiosMaquina';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

const EditarPontos = () => {
  const { TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const { clienteId } = getCookieSessionData().usuarioVO;
  const { id }: any = useParams();
  const { addToast } = useToast();
  const history = useHistory();

  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [centroDistribuicaoId, setCentroDistribuicaoId] = useState('');
  const [cep, setCep] = useState('');
  const [patio, setPatio] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [emailContato, setEmailContato] = useState('');
  const [emailContatoDois, setEmailContatoDois] = useState('');
  const [embarcadorId, setEmbarcadorId] = useState();
  const [latitude, setLatitude] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroBairro, setLogradouroBairro] = useState('');
  const [logradouroComplemento, setLogradouroComplemento] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState('');
  const [longitude, setLongitude] = useState('');
  const [municipioId, setMunicipioId] = useState<number | undefined>();
  const [paisId, setPaisId] = useState('');
  const [raioAproximacao, setRaioAproximacao] = useState('');
  const [regionalId, setRegionalId] = useState('');
  const [smsAgendamentoViagemContato, setSmsAgendamentoViagemContato] = useState(true);
  const [smsAgendamentoViagemContatoDois, setSmsAgendamentoViagemContatoDois] = useState(true);
  const [smsAgendamentoViagemContatoTres, setSmsAgendamentoViagemContatoTres] = useState(true);
  const [smsInicioViagemContato, setSmsInicioViagemContato] = useState(true);
  const [smsInicioViagemContatoDois, setSmsInicioViagemContatoDois] = useState(true);
  const [smsInicioViagemContatoTres, setSmsInicioViagemContatoTres] = useState(true);
  const [statusAtivo, setStatusAtivo] = useState(true);
  const [telContato, setTelContato] = useState('');
  const [telContatoDois, setTelContatoDois] = useState('');
  const [telContatoTres, setTelContatoTres] = useState('');
  const [ufId, setUfId] = useState<number>();

  type Municipios = {
    id: number;
    codigoMunicipioIbge: number;
    nomeMunicipio: string;
    idCodigoIbge: number;
    ufId: number;
    ufNome: string;
  };

  const [pais, setPais] = useState<PaisType[]>([]);
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<Municipios[]>([]);
  const [centroDistribuicao, setCentroDistribuicao] = useState<CentroDistribuicaoTypes[]>([]);
  const [regional, setRegional] = useState<RegionalType[]>([]);

  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [viaCepIbgeCidade, setViaCepIbgeCidade] = useState(0);
  const [viaCepCidade, setViaCepCidade] = useState('');

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidLatitude, setIsValidLatitude] = useState(true);
  const [isValidLongitude, setIsValidLongitude] = useState(true);
  const [isValidEmailDois, setIsValidEmailDois] = useState(true);
  const [error, setError] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // INICIO GET PAIS, UF E CIDADE
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/pais');
      setPais(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PONTO], EMBARCADOR: [EMBARCADOR.CADASTRO.PONTO] }],
      'editar'
    );
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PONTO], EMBARCADOR: [EMBARCADOR.CADASTRO.PONTO] }],
      'excluir'
    );
  }, [rolePermission]);

  const loadEstadosPorPais = async (pais: any) => {
    if (!pais) return;

    try {
      const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/${pais}`);
      setUfs(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };
  useEffect(() => {
    loadEstadosPorPais(paisId);
    ufId && loadMunicipiosPorEstado(ufId);
  }, [ufId, paisId]);

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };
  // FINAL GET PAIS, UF E CIDADE

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios
        .get(`/veiculo/listarNomeCd?idCliente=${clienteId}`)
        .then((response: any) => {
          setCentroDistribuicao(response.data);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios
        .get(`/regional/cliente`, {
          params: {
            clienteId,
          },
        })
        .then((response: any) => {
          setRegional(response.data);
        });
    };
    fetchData();
  }, []);

  // INICIO GET POR CEP
  const buscarCep = () => {
    if (cep.length < 9) {
      setLogradouro('');
      setLogradouroBairro('');
      return;
    }
    setError(false);
    fetch(`https://viacep.com.br/ws/${cep}/json`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          setError(true);
          return;
        }
        setPaisId('1');
        loadEstadosPorPais('1');
        setViaCepCidade(data.localidade);
        setMunicipioId(parseInt(data.ibge));
        setLogradouro(data.logradouro);
        setLogradouroBairro(data.bairro);
        setUfId(ufs.find((each: any) => each.sigla === data.uf)?.id);
        loadMunicipiosPorEstado(ufs.find((each: any) => each.sigla === data.uf)?.id);
        const codIbgeFloat = data.ibge / 100000;
        const codIbgeFormatado = Math.trunc(codIbgeFloat);
        setViaCepIbgeCod(codIbgeFormatado);
      })
      .catch((erro) => {
        setError(true);
      });
  };

  const buscarLatLong = async () => {
    try {
      let cidade: string | undefined;
      let estado: string | undefined;
      if (municipios != undefined || municipioId) {
        cidade = municipios.find(
          (cidade: any) => cidade.codigoMunicipioIbge == municipioId
        )?.nomeMunicipio;
      }
      if (municipios != undefined || municipioId) {
        estado = ufs.find((uf: any) => uf.id == ufId)?.sigla;
      }
      let response: any = [];
      if (
        cep &&
        !logradouroBairro &&
        !logradouro &&
        !logradouroNumero &&
        !municipioId &&
        !ufId &&
        cep.length === 9
      ) {
        response = await api.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${cep}&key=AIzaSyBmU-yfPkkgwyI9Z7pw8EUFLrfYIGtj5ew`
        );
      } else if (logradouroBairro || logradouro || logradouroNumero || municipioId || ufId) {
        response = await api.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${logradouro || ''}${
            logradouroNumero || ''
          }${logradouroBairro}${cidade || ''}${
            estado || ''
          }&key=AIzaSyBmU-yfPkkgwyI9Z7pw8EUFLrfYIGtj5ew`
        );
      }
      if (response.data.results[0]) {
        setLatitude(response.data.results[0].geometry.location.lat.toString());
        setLongitude(response.data.results[0].geometry.location.lng.toString());
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const loadData = () => {
      axios
        .get(`/ponto/${id}`)
        .then((response: any) => {
          setCentroDistribuicaoId(response.data.centroDistribuicaoId);
          setCep(response.data.cep);
          setPatio(response.data.patio);
          setDescricao(response.data.descricao);
          setEmailContato(response.data.emailContato);
          setEmailContatoDois(response.data.emailContatoDois);
          setEmbarcadorId(response.data.embarcadorId);
          setLatitude(response.data.latitude);
          setLogradouro(response.data.logradouro);
          setLogradouroBairro(response.data.logradouroBairro);
          setLogradouroComplemento(response.data.logradouroComplemento);
          setLogradouroNumero(response.data.logradouroNumero);
          setLongitude(response.data.longitude);
          setMunicipioId(response.data.municipioId);
          setPaisId(response.data.paisId);
          setRaioAproximacao(response.data.raioAproximacao);
          setRegionalId(response.data.regionalId);
          setSmsAgendamentoViagemContato(response.data.smsAgendamentoViagemContato);
          setSmsAgendamentoViagemContatoDois(response.data.smsAgendamentoViagemContatoDois);
          setSmsAgendamentoViagemContatoTres(response.data.smsAgendamentoViagemContatoTres);
          setSmsInicioViagemContato(response.data.smsInicioViagemContato);
          setSmsInicioViagemContatoDois(response.data.smsInicioViagemContatoDois);
          setSmsInicioViagemContatoTres(response.data.smsInicioViagemContatoTres);
          setStatusAtivo(response.data.statusAtivo);
          setTelContato(response.data.telContato);
          setTelContatoDois(response.data.telContatoDois);
          setTelContatoTres(response.data.telContatoTres);
          setUfId(response.data.ufId);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    loadData();
  }, [id]);

  const [flagIncluirCdCadPonto, setFlagIncluirCdCadPonto] = useState(false);
  const [flagIncluirRegionalCadPonto, setFlagIncluirRegionalCadPonto] = useState(false);

  useEffect(() => {
    const getParticularidades = async () => {
      try {
        const response = await axios.get(
          `/cliente/getParticularidadesCliente?cliente=${clienteId}`
        );

        setFlagIncluirCdCadPonto(response.data.flagIncluirCdCadPonto);
        setFlagIncluirRegionalCadPonto(response.data.flagIncluirRegionalCadPonto);
      } catch (err: any) {
        console.log(err);
      }
    };
    getParticularidades();
  }, [clienteId]);

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!descricao || !isValidEmail || !isValidEmailDois) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }

    if (flagIncluirCdCadPonto) {
      if (!centroDistribuicaoId) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        e.stopPropagation();
        return;
      }
    }

    if (flagIncluirRegionalCadPonto) {
      if (!regionalId) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        e.stopPropagation();
        return;
      }
    }

    const data = {
      descricao,
      cep,
      logradouro,
      logradouroNumero: parseInt(logradouroNumero, 10),
      logradouroComplemento,
      logradouroBairro,
      emailContato,
      emailContatoDois,
      latitude,
      longitude,
      raioAproximacao: parseInt(raioAproximacao, 10),
      telContato,
      smsAgendamentoViagemContato,
      smsInicioViagemContato,
      telContatoDois,
      smsAgendamentoViagemContatoDois,
      smsInicioViagemContatoDois,
      telContatoTres,
      smsAgendamentoViagemContatoTres,
      smsInicioViagemContatoTres,
      statusAtivo,
      municipioId: viaCepIbgeCidade || municipioId,
      ufId: ufId,
      paisId: parseInt(paisId, 10),
      embarcadorId,
      clienteId,
      centroDistribuicaoId: parseInt(centroDistribuicaoId, 10),
      regionalId: parseInt(regionalId, 10),
    };

    axios
      .put(`/ponto/${id}`, data)
      .then(() => setIsDialogCadOK(true))
      .catch(() => {
        addToast({
          title: 'Erro!',
          description: 'Erro ao atualizar!',
          type: 'error',
        });
      });
  }

  async function handleDelete(id: number) {
    try {
      await axios.put(`/ponto/delete-logico?idPonto=${id}`);
      addToast({
        title: 'Sucesso!',
        description: 'Ponto excluído com sucesso.',
        type: 'success',
      });
      history.goBack();
    } catch (alert) {
      ('Erro ao Desativar Cadastro');
    }
    handleClose();
  }

  const handleCepBlur = () => {
    buscarCep();
    buscarLatLong();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Ponto alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${descricao}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Pontos</h2>
          <h6 className="subtitulo" style={{ width: '100%' }}>
            {'Cadastro > Pontos'}
          </h6>
          <button
            className="btn-desativar"
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              marginLeft: '50%',
            }}
            onClick={() => {
              if (canDelete) {
                handleShow();
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
          </button>
        </div>

        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>

        <Form className="form-responsive" onSubmit={onSubmit} noValidate validated={isFormInvalid}>
          <Form.Check
            checked={patio}
            onChange={() => {
              setPatio(!patio);
            }}
            name="criticar"
            type="switch"
            id="custom-switch1"
            label="Pátio"
            style={{
              marginBottom: '1rem',
            }}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="formGridDescricao">
              <Form.Label className="require">Descrição</Form.Label>
              <Form.Control
                type="text"
                name="descricao"
                placeholder=""
                value={descricao}
                maxLength={256}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setDescricao(e.target.value);
                }}
                required
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCEP">
              <Form.Label>CEP</Form.Label>
              <Form.Control
                name="cep"
                placeholder=""
                value={cep}
                onChange={(event: any) => {
                  setCep(maskCep(event.target.value));
                }}
                onBlur={() => handleCepBlur()}
                maxLength={9}
                minLength={9}
                style={{ width: '32.8%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridPais">
              <Form.Label>País</Form.Label>
              <Form.Control
                name="paisId"
                placeholder=""
                value={paisId}
                onChange={(e: any) => {
                  setPaisId(e.target.value);
                  loadEstadosPorPais(e.target.value);
                }}
                as="select"
                defaultValue="0"
              >
                <option value="0">Selecione uma opção</option>
                {pais.map((pais) => (
                  <option value={pais.id} key={pais.id}>
                    {pais.paisDescricao}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridUF">
              <Form.Label>UF</Form.Label>
              <Form.Control
                type="number"
                name="ufId"
                placeholder=""
                as="select"
                defaultValue={0}
                value={ufId}
                onChange={(e: any) => {
                  setUfId(e.target.value);
                  loadMunicipiosPorEstado(e.target.value);
                }}
              >
                <option value={0}>Selecione uma opção</option>
                {paisId == '1'
                  ? ufs.map((ufs) => (
                      <option value={ufs.id} key={ufs.id}>
                        {ufs.sigla}
                      </option>
                    ))
                  : ufs.map((ufs) => (
                      <option value={ufs.id} key={ufs.id}>
                        {ufs.estado}
                      </option>
                    ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                type="number"
                name="municipioId"
                placeholder="0"
                value={municipioId}
                defaultValue=""
                onChange={(e: any) => {
                  setMunicipioId(e.target.value);
                }}
                as="select"
              >
                <option value={0}>Selecione uma opção</option>

                {municipios.map((filteredMunicipio) => (
                  <option key={filteredMunicipio.nomeMunicipio} value={filteredMunicipio.id}>
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridBairro">
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                type="text"
                name="logradouroBairro"
                placeholder=""
                maxLength={128}
                value={logradouroBairro}
                disabled={!municipioId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouroBairro(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLogradouro">
              <Form.Label>Logradouro</Form.Label>
              <Form.Control
                type="text"
                name="logradouro"
                placeholder=""
                maxLength={256}
                value={logradouro}
                disabled={!municipioId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouro(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridComplemento">
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                type="text"
                name="logradouroComplemento"
                maxLength={128}
                placeholder=""
                value={logradouroComplemento}
                disabled={!municipioId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouroComplemento(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridNumero">
              <Form.Label>Número</Form.Label>
              <Form.Control
                type="text"
                maxLength={6}
                name="logradouroNumero"
                placeholder=""
                value={logradouroNumero || ''}
                disabled={!municipioId}
                onChange={(e: any) => {
                  setLogradouroNumero(soNumeros(e.target.value));
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridRaioAproximacao">
              <Form.Label>Raio Aproximação</Form.Label>
              <Form.Control
                type="text"
                name="raioAproximacao"
                placeholder=""
                maxLength={10}
                value={raioAproximacao || ''}
                onChange={(e: any) => {
                  setRaioAproximacao(soNumeros(e.target.value));
                }}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLatitude">
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                name="latitude"
                placeholder=""
                value={latitude || ''}
                onChange={(e: any) => {
                  setLatitude(e.target.value);
                  if (validateLat(e.target.value)) {
                    setIsValidLatitude(true);
                  } else {
                    setIsValidLatitude(false);
                  }
                }}
                maxLength={32}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridLongitude">
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                name="longitude"
                placeholder=""
                value={longitude || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLongitude(e.target.value);
                  if (validateLng(e.target.value)) {
                    setIsValidLongitude(true);
                  } else {
                    setIsValidLongitude(false);
                  }
                }}
                maxLength={32}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCD">
              <Form.Label>
                {flagIncluirCdCadPonto && <span className="txt-obrigatorio">*</span>}
                CD Origem
              </Form.Label>
              <Form.Control
                name="centroDistribuicaoId"
                placeholder=""
                as="select"
                defaultValue=""
                required={flagIncluirCdCadPonto}
                value={centroDistribuicaoId}
                onChange={(e: any) => {
                  setCentroDistribuicaoId(e.target.value);
                }}
              >
                <option value="" />
                {centroDistribuicao.map((cd) => (
                  <option value={cd.id} key={cd.id}>
                    {cd.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRegional">
              <Form.Label>
                {flagIncluirRegionalCadPonto && <span className="txt-obrigatorio">*</span>}
                Regional
              </Form.Label>
              <Form.Control
                name="regionaId"
                placeholder=""
                as="select"
                defaultValue=""
                value={regionalId}
                required={flagIncluirRegionalCadPonto}
                onChange={(e: any) => {
                  setRegionalId(e.target.value);
                }}
              >
                <option value="" />
                {regional.map((cd) => (
                  <option value={cd.id} key={cd.id}>
                    {cd.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridEmailUm">
              <Form.Label>Email 1</Form.Label>
              <Form.Control
                type="text"
                name="emailContato"
                placeholder=""
                value={emailContato}
                maxLength={256}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  setEmailContato(e.target.value);
                  if (validaEmail(e.target.value)) {
                    setIsValidEmail(true);
                  } else {
                    setIsValidEmail(false);
                  }
                }}
              />{' '}
              {!isValidEmail && emailContato.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmailDois">
              <Form.Label>Email 2</Form.Label>
              <Form.Control
                type="text"
                name="emailContatoDois"
                placeholder=""
                value={emailContatoDois}
                maxLength={256}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  setEmailContatoDois(e.target.value);
                  if (validaEmail(e.target.value)) {
                    setIsValidEmailDois(true);
                  } else {
                    setIsValidEmailDois(false);
                  }
                }}
              />{' '}
              {!isValidEmailDois && emailContatoDois.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Email inválido</p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridTelContatoUm">
              <Form.Label>Contato Celular 1</Form.Label>
              <Form.Control
                name="telContato"
                placeholder=""
                value={telContato || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTelContato(maskTel(e.target.value));
                }}
                maxLength={14}
                minLength={13}
              />
              <div style={{ marginTop: '1%' }}>
                <Switch
                  checked={smsAgendamentoViagemContato}
                  name="SMSAgendamento"
                  id="SMSAgendamento"
                  type="switch"
                  label="Enviar SMS no agendamento da viagem"
                  onChange={() => {
                    setSmsAgendamentoViagemContato(!smsAgendamentoViagemContato);
                  }}
                />
              </div>
              <div>
                <Switch
                  checked={smsInicioViagemContato}
                  name="SMSInicio"
                  id="SMSInicio"
                  type="switch"
                  label="Enviar SMS no começo da viagem"
                  onChange={() => {
                    setSmsInicioViagemContato(!smsInicioViagemContato);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelContatoDois">
              <Form.Label>Contato Celular 2</Form.Label>
              <Form.Control
                name="telContatoDois"
                placeholder=""
                value={telContatoDois || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTelContatoDois(maskTel(e.target.value));
                }}
                maxLength={14}
                minLength={13}
              />
              <div style={{ marginTop: '1%' }}>
                <Switch
                  checked={smsAgendamentoViagemContatoDois}
                  name="SMSAgendamentoDois"
                  id="SMSAgendamentoDois"
                  type="switch"
                  label="Enviar SMS no agendamento da viagem"
                  onChange={() => {
                    setSmsAgendamentoViagemContatoDois(!smsAgendamentoViagemContatoDois);
                  }}
                />
              </div>
              <div>
                <Switch
                  checked={smsInicioViagemContatoDois}
                  name="SMSInicioDois"
                  id="SMSInicioDois"
                  type="switch"
                  label="Enviar SMS no começo da viagem"
                  onChange={() => {
                    setSmsInicioViagemContatoDois(!smsInicioViagemContatoDois);
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTelContatoTres">
              <Form.Label>Contato Celular 3</Form.Label>
              <Form.Control
                name="telContatoTres"
                placeholder=""
                value={telContatoTres || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTelContatoTres(maskTel(e.target.value));
                }}
                maxLength={14}
                minLength={13}
              />
              <div style={{ marginTop: '1%' }}>
                <Switch
                  checked={smsAgendamentoViagemContatoTres}
                  name="SMSCAgendamentoTres"
                  id="SMSCAgendamentoTres"
                  type="switch"
                  label="Enviar SMS no agendamento da viagem"
                  onChange={() => {
                    setSmsAgendamentoViagemContatoTres(!smsAgendamentoViagemContatoTres);
                  }}
                />
              </div>
              <div>
                <Switch
                  checked={smsInicioViagemContatoTres}
                  name="SMSComecoTres"
                  id="SMSComecoTres"
                  type="switch"
                  label="Enviar SMS no começo da viagem"
                  onChange={() => {
                    setSmsInicioViagemContatoTres(!smsInicioViagemContatoTres);
                  }}
                />
              </div>
            </Form.Group>
          </Form.Row>

          <ButtonsForm canEdit={canEdit} />
        </Form>
      </div>
    </div>
  );
};

export default EditarPontos;
