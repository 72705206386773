/* eslint-disable */
import React, { FC, useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer,
  Circle,
} from '@react-google-maps/api';
import BuildImage from '../../assets/markers/building.png';
import StackedBuildingImage from '../../assets/markers/stackedBuilding.png';
import TruckImage from '../../assets/markers/truck.png';
import { PositionTruck } from '../../types';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import uniqWith from 'lodash/uniqWith';
import { obterDataHora } from '../../util/date';
import { ListVeiculosPatio } from '../../pages/Patio/MapaPatio';
import { PontoPatioType } from '../../api/model/PontoType';

type Props = {
  positions: PositionTruck[];
  goToMap: boolean;
  goLatitude: number;
  goLongitude: number;
  raio: number | undefined;
  map: google.maps.Map;
  center: LatLng;
  setCenter: (value: LatLng) => void;
  setMap: (value: google.maps.Map) => void;
  selectedMarker: LatLng;
  setSelectedMarker: (value: LatLng) => void;
  requestPatio?: boolean;
  setAtrasados?: (value: ListVeiculosPatio[]) => void;
  setFiltrado?: (value: ListVeiculosPatio[]) => void;
  setDataProgressao?: (value: ListVeiculosPatio[]) => void;
  dataPatio: ListVeiculosPatio[];
};

type LatLng = {
  lat: any;
  lng: any;
};

const { REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY } = process.env;

const MapContainerPatio: FC<Props> = ({
  positions,
  goToMap,
  goLatitude,
  goLongitude,
  raio,
  map,
  setMap,
  selectedMarker,
  setSelectedMarker,
  center,
  setCenter,
  requestPatio,
  dataPatio,
}) => {
  const { clienteId } = getCookieSessionData().usuarioVO;

  const [selectedMarkerBuild, setSelectedMarkerBuild] = useState<LatLng>();
  const [showInfo, setShowInfo] = useState(false);
  const [pontosList, setPontosList] = useState<PontoPatioType[]>([]);
  const [showInfoBuild, setShowInfoBuild] = useState(false);
  const [currentZoom, setCurrentZoom] = useState<number>();

  const defaultCenter = {
    lat: -14.749,
    lng: -43.4382,
  };

  const positionOnClick = {
    lat: goLatitude,
    lng: goLongitude,
  };

  const mapStyles = {
    height: '50vh',
    width: '100%',
  };

  const options = {
    fillColor: '#D5D4F4',
    strokeColor: '#AFAFFC',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1,
  };

  const createKey = (location: { lat: number | null; lng: number | null }) => {
    if (!location.lat || !location.lng) {
      return;
    }
    return location.lat + location.lng;
  };

  const getPontos = async () => {
    try {
      // Verifique se response.data é um array
      if (!Array.isArray(dataPatio)) {
        throw new Error('Response data is not an array');
      }

      const pontos = dataPatio.map((p: any) => {
        return {
          ...p,
          latitude: Number(p.latitude),
          longitude: Number(p.longitude),
        };
      });

      if (!pontos.length) return;
      setPontosList(pontos);
    } catch (err: any) {
      console.error('Error in getPontos:', err.message);
    }
  };

  useEffect(() => {
    getPontos();
  }, []);

  // manter dados únicos onde lat/lng sejam iguais
  const uniqueLocations = uniqWith(
    pontosList,
    (locationA, locationB) =>
      locationA.latitude === locationB.latitude && locationA.longitude === locationB.longitude
  );

  const renderRadius = (location: LatLng) => {
    const { lat, lng } = location;
    if (requestPatio) {
      const pontos = pontosList.filter((p: PontoPatioType) => {
        return p.latitude === lat && p.longitude === lng;
      });
    }
  };

  const renderDescricaoPatioPontos = (latitude: number, longitude: number) => {
    const pontos = pontosList.filter((p: PontoPatioType) => {
      return p.latitude === latitude && p.longitude === longitude;
    });
    if (!pontos.length) return;
    return pontos.map((d: PontoPatioType, index) => {
      return (
        <ul key={index} style={{ listStyle: 'none', padding: 0 }}>
          <li
            style={{
              borderBottom: '1px solid rgba(139,139,139,0.2)',
              paddingTop: 5,
              paddingBottom: 2,
              textAlign: 'left',
              listStyleType: 'none',
              listStylePosition: 'inside',
              textTransform: 'uppercase',
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {`Embarcador: ${d.embarcador || ''}`}
            <br />
            {`Localização: ${d.localizacao || ''}`}
            <br />

            {`Status: ${d.status || ''}`}
            <br />

            {`Transportador: ${d.trasportador || ''}`}
            <br />

            {`Placa: ${d.vei_tx_placa || ''}`}
          </li>
        </ul>
      );
    });
  };

  const renderBuildingImage = (location: LatLng) => {
    const { lat, lng } = location;
    const stackedLocation = pontosList.filter((p: PontoPatioType) => {
      return p.latitude === lat && p.longitude === lng;
    });
    if (stackedLocation.length > 1) {
      return StackedBuildingImage;
    } else if (stackedLocation.length === 1) {
      return BuildImage;
    }
  };

  map?.addListener('zoom_changed', function () {
    const zoom = map.getZoom();
    setCurrentZoom(zoom);
  });

  useEffect(() => {
    if (currentZoom && currentZoom >= 14) {
      setShowInfoBuild(true);
      if (map?.getCenter()?.lat() && map?.getCenter()?.lng()) {
        const location = {
          lat: Number(map?.getCenter()?.lat().toFixed(7)),
          lng: Number(map?.getCenter()?.lng().toFixed(7)),
        };
        map.setZoom(14);
        setSelectedMarkerBuild(location);
      }
    }
  }, [currentZoom]);

  useEffect(() => {
    setCenter(positionOnClick);
  }, [goLatitude, goLongitude]);
  return (
    <>
      <LoadScript googleMapsApiKey={GOOGLE_API_KEY || ''}>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={goToMap === true ? 17 : 3.2}
          onLoad={(m) => {
            setMap(m);
            setCenter(defaultCenter);
          }}
          // onZoomChanged={() => onZoomChange()}
          center={center}
        >
          {!positions.length && <></>}
          <>
            <MarkerClusterer
              imagePath={'../../assets/clusterers'}
              averageCenter={true}
              // gridSize={50}
              title={'Click to zoom'}
              maxZoom={13}
              minimumClusterSize={1}
            >
              {(clusterer) =>
                positions.map((each, index) => {
                  const location = { lat: each.lat, lng: each.lng };
                  const key = createKey(location);
                  return (
                    <>
                      <Marker
                        icon={TruckImage}
                        key={index}
                        position={location}
                        clusterer={clusterer}
                        onClick={() => {
                          setSelectedMarker(location);
                          setShowInfo(true);
                        }}
                      >
                        {showInfo && selectedMarker && createKey(selectedMarker) === key && (
                          <InfoWindow position={location} onCloseClick={() => setShowInfo(false)}>
                            <div style={{ width: '250px' }}>
                              <div>
                                <span
                                  style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    // backgroundColor: '#FFDA53',
                                    // borderRadius: '5px',
                                  }}
                                >
                                  {each.placas}
                                </span>
                              </div>

                              <div>
                                <span>&nbsp;</span>
                              </div>

                              <div>
                                <span style={{ fontWeight: 'bold' }}>Transportadora:</span>
                                <span> {each.transportador}</span>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>SM nº: </span>
                                {each.idSm && (
                                  <span style={{ marginLeft: '5px' }}>{each.idSm}</span>
                                )}

                                <span style={{ fontWeight: 'bold', marginLeft: '15px' }}>
                                  Início:
                                </span>
                                {each.inicio && (
                                  <span style={{ marginLeft: '5px' }}>
                                    {obterDataHora(each.inicio, true)}
                                  </span>
                                )}
                              </div>

                              {each.proximoPonto && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    // justifyContent: 'space-between',
                                  }}
                                >
                                  <span style={{ fontWeight: 'bold' }}>Próximo Ponto:</span>
                                  <span style={{ marginLeft: '5px' }}>
                                    {each.proximoPonto ? each.proximoPonto : 'Viagem Concluída'}
                                  </span>
                                </div>
                              )}

                              <div>
                                <span>&nbsp;</span>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'space-between',
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>Distância Percorrida:</span>
                                <span style={{ marginLeft: '5px' }}></span>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'space-between',
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>Tempo Decorrido:</span>
                                <span style={{ marginLeft: '5px' }}></span>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  // justifyContent: 'space-between',
                                }}
                              >
                                <span style={{ fontWeight: 'bold' }}>Velocidade Média:</span>
                                <span style={{ marginLeft: '5px' }}></span>
                              </div>

                              <div>
                                <span>&nbsp;</span>
                              </div>

                              <span style={{ fontWeight: 'bold' }}>Status ignição:</span>
                              <span style={{ marginLeft: '5px' }}>
                                {each.statusIgnicao === true ? 'Ligada' : 'Desligada'}
                              </span>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    </>
                  );
                }) as any
              }
            </MarkerClusterer>

            <MarkerClusterer
              imagePath={'../../assets/clusterers/build'}
              averageCenter={true}
              maxZoom={13}
              // gridSize={50}
              title={'Click to zoom'}
              minimumClusterSize={1}
            >
              {(clusterer) =>
                uniqueLocations.map((each, index) => {
                  const location = { lat: each.latitude, lng: each.longitude };
                  if (typeof location.lat !== 'number') return;
                  const key = createKey(location);
                  return (
                    <>
                      <Marker
                        icon={renderBuildingImage(location)}
                        position={location}
                        key={index}
                        clusterer={clusterer}
                        onClick={() => {
                          setSelectedMarkerBuild(location);
                          setShowInfoBuild(true);
                        }}
                      >
                        {showInfoBuild &&
                          currentZoom &&
                          currentZoom >= 14 &&
                          each.latitude &&
                          each.longitude &&
                          selectedMarkerBuild &&
                          createKey(selectedMarkerBuild) === key && (
                            <InfoWindow
                              position={location}
                              onCloseClick={() => {
                                setShowInfoBuild(false);
                              }}
                            >
                              <div
                                style={{
                                  padding: '5px, 10px',
                                  width: 'fit-content',
                                  overflow: 'none',
                                }}
                              >
                                {renderDescricaoPatioPontos(each.latitude, each.longitude)}
                              </div>
                            </InfoWindow>
                          )}
                      </Marker>
                      {renderRadius(location)}
                    </>
                  );
                }) as any
              }
            </MarkerClusterer>
          </>
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default MapContainerPatio;
