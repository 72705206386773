/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, InputGroup, Modal } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';
import { validaSenha, noArroba } from '../../../util/Validacoes/Validacoes';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { Spinner } from 'react-bootstrap';
import { Tela } from '../../../components/Tela';
import { dataConverter } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { updateUser } from '../../../services/user';

const AtualizarUsuariosCorretoras = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogExclusaoOK, setIsDialogExclusaoOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const { idUsu, idCorretora, loginAntigo }: any = useParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [senhaValida, setSenhaValida] = useState(true);
  const [isValidLogin, setIsValidLogin] = useState(true);
  const [isValidNome, setIsValidNome] = useState(true);
  const [isValidSenha, setIsValidSenha] = useState(true);
  const [login, setLogin] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [senha, setSenha] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [nome, setNome] = useState('');
  const [dataCadastro, setDataCadastro] = useState('');
  const [usuarioIdKeycloak, setUsuarioIdKeycloak] = useState('');
  const [loading, setLoading] = useState(false);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [usuResponsavelCadastro, setUsuResponsavelCadastro] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.USUARIO.CORRETORA] }], 'editar');
    rolePermission([{ ADMIN: [ADMIN.USUARIO.CORRETORA] }], 'excluir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/usuario/corretora/${idUsu}`);
      setLogin(response.data.login);
      setSenha(response.data.senha);
      setNovaSenha(response.data.senha);
      setNome(response.data.nome);
      setAtivo(response.data.ativo);
      setDataCadastro(response.data.usuDtHrCadastro);
      setUsuResponsavelCadastro(response.data.usuResponsavelCadastro);
      setUsuarioIdKeycloak(response.data.usuarioIdKeycloak);
      if (response.data === '') {
        try {
          const response = await axios.get(
            `/usuario/reutilizar-usuario-corretora?login=${loginAntigo}`
          );
          console.log(response.data);
          setLogin(response.data.login);
          setSenha(response.data.senha);
          setNovaSenha(response.data.senha);
          setNome(response.data.nome);
          setAtivo(true);
          setDataCadastro(response.data.usuDtHrCadastro);
          setUsuarioIdKeycloak(response.data.usuarioIdKeycloak);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    fetchData();
  }, [idCorretora]);

  const handleEdit = async (event: any) => {
    event.preventDefault();
    if (!senha || !nome || !login || !novaSenha) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const data = {
      ativo: ativo,
      corretoraId: +idCorretora,
      login: login.toLowerCase(),
      nome: nome,
      roleUser: 'corretora',
      senha: novaSenha,
      usuCdId: +idUsu,
      usuDtHrCadastro: dataConverter(dataCadastro),
      usuDtHrUltimaAlteracao: dataConverter(new Date().toISOString()),
      usuResponsavelCadastro: usuResponsavelCadastro,
      usuarioIdKeycloak: usuarioIdKeycloak,
    };

    try {
      setLoading(true);
      if (senhaValida == true) {
        await updateUser(idUsu, data);
        console.log(data);
        setIsDialogCadOK(true);
        setLoading(false);
      }
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao atualizar!',
        type: 'error',
      });
      setLoading(false);
      return;
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.put(`/usuario/delete-logico?idUsuario=${id}`);
      setIsDialogExclusaoOK(true);
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir!',
        type: 'error',
      });
      return;
    }
    handleClose();
  };

  const gerenciarSenha = (event: any) => {
    if (senha != null) {
      setSenha(event.target.value);
      setNovaSenha('');
      if (validaSenha(event.target.value)) {
        setIsValidSenha(true);
      } else {
        setIsValidSenha(false);
      }
    }
  };

  return (
    <Tela
      nome="Usuário"
      caminho="Alterar > Usuário Corretora"
      loading={loading}
      setLoading={setLoading}
      canDelete={canDelete}
      onClickDelete={() => {
        handleShow();
      }}
    >
      <Dialog
        header="Usuário excluído com sucesso!"
        footer={<Button onClick={() => setIsDialogExclusaoOK(false)}>Ok</Button>}
        visible={isDialogExclusaoOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-corretoras/1');
        }}
      />

      <Dialog
        header="Usuário alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar-usuarios-corretoras/1');
        }}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${nome}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(idCorretora)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
      <Form noValidate validated={isFormInvalid} onSubmit={handleEdit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label>Login</Form.Label>
            <Form.Control
              value={login}
              onChange={(event) => {
                event.preventDefault();
                setLogin(noArroba(event.target.value));
                if (login.length < 2) {
                  setIsValidLogin(false);
                } else {
                  setIsValidLogin(true);
                }
              }}
              minLength={3}
              maxLength={100}
              disabled
            />
            {!isValidLogin && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Nome</Form.Label>

            <Form.Control
              value={nome}
              onChange={(event) => {
                event.preventDefault();
                setNome(event.target.value);
                if (nome && nome.length < 2) {
                  setIsValidNome(false);
                }
                if (nome && (nome.length >= 2 || nome.length === 0)) {
                  setIsValidNome(true);
                }
              }}
              required
              minLength={3}
              maxLength={100}
            />
            {!isValidNome && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, digite 3 caracteres ou mais
              </p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={senha}
                onChange={(event) => gerenciarSenha(event)}
                required
                type={passwordShown ? 'text' : 'password'}
                maxLength={10}
                minLength={5}
                style={{ borderRightColor: 'white' }}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$"
                title="A senha deve ter entre 5 e 10 caracteres, contendo pelo menos uma letra e um número. Caracteres especiais são opcionais."
              />

              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {!isValidSenha && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Senha inválida! A senha deve ter entre 5 e 10 caracteres, contendo pelo menos uma
                letra e um número. Caracteres especiais são opcionais.
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridAddress1">
            <Form.Label className="required">Confirme a senha</Form.Label>
            <InputGroup>
              <Form.Control
                value={novaSenha}
                onChange={(event: any) => {
                  setNovaSenha(event.target.value);
                  if (event.target.value === senha) {
                    setSenhaValida(true);
                  } else {
                    setSenhaValida(false);
                  }
                }}
                required
                type={passwordShown2 ? 'text' : 'password'}
                style={{ borderRightColor: 'white' }}
                maxLength={10}
                minLength={5}
                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$"
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  style={{
                    background: 'white',
                    borderLeftColor: 'white',
                    marginLeft: '-3px',
                    borderTopRightRadius: '5px',
                    borderBottomRightRadius: '5px',
                  }}
                >
                  {passwordShown2 ? (
                    <i
                      title="Ocultar Senha"
                      className="pi pi-eye-slash"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  ) : (
                    <i
                      title="Mostrar Senha"
                      className="pi pi-eye"
                      onClick={togglePasswordVisiblity2}
                    ></i>
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
            {senhaValida == false && senha.length === 8 && (
              <p style={{ fontSize: '12px', color: 'red' }}>Senhas diferentes</p>
            )}
          </Form.Group>
        </Form.Row>
        {loading ? (
          <div style={{ marginTop: 30 }}>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto o cadastro está sendo atualizado...
            </p>
            <ButtonsForm canEdit={canEdit} />
          </div>
        ) : (
          <div style={{ marginTop: 30 }}>
            <ButtonsForm canEdit={canEdit} />
          </div>
        )}
      </Form>
    </Tela>
  );
};

export default AtualizarUsuariosCorretoras;
