/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { IoCheckmarkSharp, IoMailUnread, IoMailOpenSharp } from 'react-icons/io5';
import { debounce } from 'lodash';
import axios from 'axios';
import Pagination from './Pagination/Pagination';
import instanceMaquina from '../../config/axiosMaquina';
import { isAllowedByRole, getCookieSessionData } from '../../services/cookieService';
import Search from '../../components/Search/Search';
import { saveCookieParticularidadesData } from '../../services/cookieParticularidades';
import { Tela } from '../../components/Tela';
import { obterDataHora } from '../../util/date';
import { monitoringRoute } from '../../services/monitoringRoute';
import { useToast } from '../../hooks/Toast';
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
export type PainelHomeType = {
  cidade_destino: string;
  cidade_origem: string;
  dt_fim_real: string;
  dt_inicio_real: string;
  empresa: string;
  estado_destino: string;
  estado_origem: string;
  num_sm: number;
  status_escolta: boolean;
  status_finalizada: boolean;
  status_iniciada: boolean;
  status_isca: boolean;
  hint_checklist: string;
  status_visualizada: boolean;
  tecnologia_rastreamento: string;
  uf_destino: string;
  uf_origem: string;
  valor_carga: number;
  vei_placa: string;
  bau_checklist: number;
  carreta2_checklist: number;
  carreta_checklist: number;
  veiculo_checklist: number;
  sat_veiculo: boolean;
  data_inclusao: string;
  checklist: string;
  id_cliente: number;
  bau_id: number;
  numero_bau: string;
  carreta_id: number;
  placa_carreta: string;
  carreta2_id: number;
  placa_carreta2: string;
  id_veiculo: number;
  placa: string;
  status_cancelamento: boolean;
  bau_sat: number;
  carreta_sat: number;
  veiculo_sat: number;
  carreta2_sat: number;
  hintColor: string;
  cliente_razao_social: string;
};

const Home: React.FC = () => {
  const [refresh, setRefresh] = useState(0);
  const { addToast } = useToast();
  const [lastPage, setLastPage] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const [data, setData] = useState<PainelHomeType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState<PainelHomeType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalItems, setTotalItems] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  const { page }: any = useParams();
  const history = useHistory();
  const { usuarioId, isEmbarcador, clienteId, cnpj, role } = getCookieSessionData().usuarioVO || {};
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = filteredData.length
    ? filteredData.slice(indexOfFirstPost, indexOfLastPost)
    : data;

  useEffect(() => setSearch(null), []);

  useEffect(() => {
    setData((prevData) =>
      [...prevData].sort((a, b) =>
        sortOrder === 'asc' ? a.num_sm - b.num_sm : b.num_sm - a.num_sm
      )
    );
  }, [sortOrder]);

  useEffect(() => {
    const handleSaveParticularidades = async () => {
      if (role === 'admin') {
        return;
      }
      const response = await instanceMaquina.get(
        `/cliente/getParticularidadesCliente?cliente=${clienteId}`
      );

      saveCookieParticularidadesData(response.data);
    };
    handleSaveParticularidades();
  }, [clienteId]);

  const insertColorHint = (data: any[]) => {
    const array = data.map((each: any, index) => {
      const qtdChecklist = each.hint_checklist
        .split(',')
        .map((item: string) => item.trim())
        .filter((item: string) => item.length > 0);

      const qtdChecklistOk = qtdChecklist.filter((check: string) =>
        check?.split(':')[1]?.trim().toLowerCase().startsWith('ok')
      );

      return {
        ...each,
        hintColor: qtdChecklist.length === qtdChecklistOk.length ? '#3AE963' : '#E93A3A',
      };
    });
    return array;
  };

  const getDatas = async ({
    page,
    isSearching = false,
  }: {
    page: number;
    isSearching?: boolean;
  }) => {
    const params = {
      page_size: itensPerPage,
      page,
      id_usuario: usuarioId,
      ...(search && { num_sm: search }),
    };

    const paramsEmb = {
      page_size: itensPerPage,
      page,
      cnpj_embarcador: cnpj,
      id_usuario: usuarioId,
      ...(search && { num_sm: search }),
    };

    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_URL}/list-sm/`, {
        params: isEmbarcador ? paramsEmb : params,
      });

      if (isSearching && !response.data.result.length) {
        addToast({
          title: '',
          description: 'Não há registros com a S.M informada',
          type: 'error',
        });
        return;
      }

      let sortedData = insertColorHint(response.data.result);

      // Aplica a ordenação inicial
      sortedData = sortedData.sort((a, b) =>
        sortOrder === 'asc' ? a.num_sm - b.num_sm : b.num_sm - a.num_sm
      );

      setTotalItems(response.data.count);
      setLastPage(response.data.pages);
      setData(sortedData);
    } catch (err: any) {
      console.log(err.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!refreshitensPerPage) return;

    const getDados = async () => {
      if (role === 'admin') {
        return;
      }
      setLoading(true);

      await getDatas({ page: 1 });

      setRefreshitensPerPage(false);
    };
    getDados();
  }, [refreshitensPerPage]);

  useEffect(() => {
    const getDados = async () => {
      if (role === 'admin') {
        return;
      }
      setLoading(true);

      await getDatas({ page: 1 });

      setTimeout(() => {
        window.location.reload();
      }, 1200000);
    };
    getDados();
  }, []);

  useEffect(() => {
    if (!refresh) return;

    const getDados = async () => {
      if (role === 'admin') {
        return;
      }
      setLoading(true);

      await getDatas({ page: refresh });
    };
    getDados();
  }, [refresh]);

  useEffect(() => {
    if (isAllowedByRole(['user', 'seguradora', 'corretora'])) {
      history.push(`/home/1`);
    }
  }, []);

  const handleVisualizar = async (each: PainelHomeType) => {
    const dataDispositivos = {
      idCheck: each.veiculo_checklist,
      idCheckBau: each.bau_checklist,
      idSat: each.sat_veiculo,
      idBauSat: each.bau_sat,
      idCarSat: each.carreta_sat,
      idCarSat2: each.carreta2_sat,
      idCarCheck: each.carreta_checklist,
      idCar2Check: each.carreta2_checklist,
      veiculoId: each.id_veiculo,
      veiculoPlaca: each.placa,
      carretaPlaca: each.placa_carreta,
      carretaId: each.carreta_id,
      carreta2Placa: each.placa_carreta2,
      carreta2Id: each.carreta2_id,
      bauNumero: each.numero_bau,
      bauId: each.bau_id,
      clienteId: each.id_cliente,
    };

    try {
      if (!each.status_iniciada) {
        window.open(
          `/sm/editar/${each.num_sm}`,
          'janela',
          'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
        );
      } else {
        window.open(
          `/finalizar-sm-emandamento/${each.num_sm}`,
          'janela',
          'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
        );
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const retornaEscoltaIsca = (each: any) => {
    if (each.statusEscolta && each.statusIsca) {
      return 'Escolta/Isca';
    }
    if (each.statusEscolta && !each.statusIsca) {
      return 'Escolta';
    }
    if (!each.statusEscolta && each.statusIsca) {
      return 'Isca';
    }
    return '';
  };

  const retornaStatus = (each: any) => {
    if (each.statusFinalizada) {
      return 'Finalizada';
    }
    if (each.statusIniciada) {
      return 'Em andamento';
    }
    if (each.statusCancelamento) {
      return 'Cancelada';
    }
    return 'Pendente';
  };

  useEffect(() => {
    if (search === null) return;

    const debounceTimeout = setTimeout(() => {
      const fetchData = async () => {
        if (search?.trim() === '') {
          setLoading(true);

          await getDatas({ page: 1 });

          return;
        }
        setLoading(true);

        getDatas({ page: 1, isSearching: true });
      };

      fetchData();
    }, 1000);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [search]);

  const paginate = (pageNumber: number) => {
    history.push(`/home/${pageNumber}`);
  };

  const handleSortToggle = () => {
    setSortOrder((prevOrder) => {
      return prevOrder === 'asc' ? 'desc' : 'asc';
    });
  };

  const sortedItems = [...currentData].sort((a, b) => {
    return sortOrder === 'asc' ? a.num_sm - b.num_sm : b.num_sm - a.num_sm;
  });

  return (
    <Tela home loading={loading} setLoading={setLoading}>
      {sortedItems.length == 0 ? (
        <div
          style={{ inset: 0 }}
          className="position-absolute d-flex justify-content-center align-items-center"
        >
          <img
            style={{ width: '250px', objectFit: 'cover' }}
            src="/emptyData.png"
            alt="empty data"
          />
        </div>
      ) : (
        !isAllowedByRole(['admin']) && (
          <div className="content-container">
            <Search
              placeholder="Pesquise a S.M"
              disabled={!sortedItems}
              setLoading={setLoadingSearch}
              setSearch={setSearch}
              setSearchValue={setSearchValue}
              search={search}
            />
            <div className="table-responsive" style={{ flex: 1 }}>
              <div className="painel">
                <table className="table">
                  <thead className="thead">
                    {isAllowedByRole(['corretora', 'seguradora']) ? (
                      <tr>
                        <th>Empresa</th>
                        <th>Nº S.M</th>
                        <th>Origem</th>
                        <th>Destino</th>
                        <th>Placa</th>
                        <th>Tecnologia</th>

                        <th>Valor Carga</th>
                        <th>Escolta/Isca</th>
                        <th>Início Real</th>
                        <th>Término Real</th>
                      </tr>
                    ) : isAllowedByRole(['user']) ? (
                      <tr>
                        <th style={{ cursor: 'pointer' }} onClick={handleSortToggle}>
                          Nº S.M {sortOrder === 'asc' ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                        </th>

                        <th>Origem</th>
                        <th>Destino</th>
                        <th>Placa</th>
                        <th>Tecnologia</th>
                        <th>Empresa</th>
                        <th>Início Real</th>
                        <th>Término Real</th>
                        <th>Situação</th>
                        <th />
                      </tr>
                    ) : null}
                  </thead>
                  <tbody>
                    {sortedItems &&
                      sortedItems.map((each: PainelHomeType, index) => {
                        {
                          if (isAllowedByRole(['corretora', 'seguradora'])) {
                            return (
                              <tr key={index}>
                                <td>{each.cliente_razao_social}</td>
                                <td>{each.num_sm}</td>
                                <td>
                                  {each.cidade_origem}/{each.uf_origem}
                                </td>
                                <td>
                                  {each.cidade_destino}/{each.uf_destino}
                                </td>
                                <td>{each.vei_placa}</td>
                                <td>{each.tecnologia_rastreamento}</td>

                                <td>{each.valor_carga}</td>
                                <td>
                                  {each.status_escolta && each.status_isca
                                    ? 'Escolta/Isca'
                                    : each.status_escolta && !each.status_isca
                                    ? 'Escolta'
                                    : !each.status_escolta && each.status_isca
                                    ? 'Isca'
                                    : ''}
                                </td>
                                <td>{obterDataHora(each.dt_inicio_real, true)}</td>
                                <td>{obterDataHora(each.dt_fim_real, true)}</td>
                              </tr>
                            );
                          }
                          if (isAllowedByRole(['user'])) {
                            return (
                              <tr key={index}>
                                <td>{each.num_sm}</td>
                                <td>
                                  {each.cidade_origem}/{each.uf_origem}
                                </td>
                                <td>
                                  {each.cidade_destino}/{each.uf_destino}
                                </td>
                                <td>{each.vei_placa}</td>
                                <td>{each.tecnologia_rastreamento}</td>
                                <td>{each.empresa}</td>
                                <td>{obterDataHora(each.dt_inicio_real, true)}</td>
                                <td>{obterDataHora(each.dt_fim_real, true)}</td>
                                <td>
                                  {each.status_finalizada
                                    ? 'Finalizada'
                                    : each.status_iniciada
                                    ? 'Em andamento'
                                    : each.status_cancelamento
                                    ? 'Cancelada'
                                    : 'Pendente'}
                                </td>
                                <td style={{ width: '10%' }} id="icons-container">
                                  <Button
                                    tooltip={each.hint_checklist}
                                    tooltipOptions={{ position: 'bottom' }}
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderColor: 'transparent',
                                      padding: 0,
                                      paddingRight: 5,
                                      marginBottom: 2,
                                    }}
                                  >
                                    <IoCheckmarkSharp size={22} color={each.hintColor} />
                                  </Button>

                                  <Button
                                    tooltip="Visualizar SM"
                                    tooltipOptions={{ position: 'bottom' }}
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderColor: 'transparent',
                                      padding: 0,
                                      paddingRight: 5,
                                      marginBottom: 2,
                                    }}
                                    onClick={() => handleVisualizar(each)}
                                  >
                                    {!each.status_visualizada ? (
                                      <IoMailUnread size={22} color="#9F9F9F" />
                                    ) : (
                                      <IoMailOpenSharp size={22} color="#9F9F9F" />
                                    )}
                                  </Button>
                                </td>
                              </tr>
                            );
                          }
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <Pagination
              lastPage={lastPage}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={totalItems}
              paginate={paginate}
              currentPage={page}
              setRefresh={setRefresh}
              setData={setData}
              setRefreshitensPerPage={setRefreshitensPerPage}
            />
          </div>
        )
      )}
    </Tela>
  );
};

export default Home;
