/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { RotaType } from '../../api/model/RotaType';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { Table, Button, Accordion, Spinner } from 'react-bootstrap';
import { ParadaType } from '../../api/model/ParadaType';
import './styles.css';
import Pagination from '../../components/Pagination/Pagination';
import { getCookieSessionData } from '../../services/cookieService';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';
import { Tela } from '../../components/Tela';

function Rotas() {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [paradas, setParadas] = useState<ParadaType[]>([]);
  const [rotas, setRotas] = useState<RotaType[]>([]);
  const history = useHistory();
  const { page } = useParams<{ page: string }>();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<RotaType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [currentPage, setCurrentPage] = useState<string>(page || '1');
  const [itensPerPage, setItensPerPage] = useState<string>('10');
  const [totalPosts, setTotalPosts] = useState<number>(0);

  const indexOfLastPost = parseInt(currentPage) * parseInt(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage);

  const paginate = (pageNumber: number) => {
    history.push(`/rota/${pageNumber}`);
    setCurrentPage(pageNumber.toString());
  };

  useEffect(() => {
    setCurrentPage(page || '1');
  }, [page]);

  useEffect(() => {
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA] }], 'inserir');
  }, [TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/rota/grid-rota-cliente`, {
          params: {
            idCliente: clienteId,
            qtdRegistros: itensPerPage,
            pagina: parseInt(currentPage) - 1,
            keyword: searchTerm.trim() !== '' ? searchTerm : undefined,
          },
        });
        setTotalPosts(response.data.count);
        const routes = response.data.result.filter((item: RotaType) => !item.rotaAvulsa);
        setRotas(routes);
        setSearchResults(routes);
      } catch (error) {
        console.error('Erro ao buscar rotas', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [itensPerPage, currentPage, clienteId, searchTerm]);

  function rotaEditar(id: number) {
    history.push(`/rota-editar-cadastrar/${id}`);
  }

  const handleParadas = async (idRota: number) => {
    setParadas([] as any);
    try {
      const response = await axios.get(`/rota/parada-por-rota?idRota=${idRota}`);
      setParadas(response.data);
    } catch (error) {
      console.error('Erro ao buscar paradas da rota', error);
    }
  };

  return (
    <Tela
      nome="Rotas"
      caminho="Cadastro > Rotas"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/rota-editar-cadastrar"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      createDisabled={!canInsert}
    >
      <div className="painel" style={{ width: '100%' }}>
        <div className="table-responsive" style={{ width: '100%' }}>
          <Accordion>
            {!loading && (
              <Table className="rotas">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nome</th>
                    <th>Descrição da Origem</th>
                    <th>Descrição do Destino</th>
                    <th>UF Origem</th>
                    <th>UF Destino</th>
                    <th>Rota Internacional</th>
                    <th></th>
                  </tr>
                </thead>

                {rotas.length > 0 ? (
                  rotas.map((rota) => (
                    <tbody key={rota.idRota}>
                      <tr>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={rota.idRota.toString()}
                          onClick={() => handleParadas(rota.idRota)}
                        >
                          <i className="pi pi-caret-right mt-2" title="Visualizar Paradas"></i>
                        </Accordion.Toggle>
                        <td>{rota.nome}</td>
                        <td>{rota.descricaoOrigem || ''}</td>
                        <td>{rota.descricaoDestino || ''}</td>
                        <td>{rota.ufOrigemInternacional || rota.ufOrigem}</td>
                        <td>{rota.ufDestinoInternacional || rota.ufDestino}</td>
                        <td>{rota.execRotaInternacional ? 'Sim' : 'Não'}</td>
                        <td>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              width: 20,
                              height: 20,
                              padding: 0,
                              marginRight: 15,
                              marginBottom: 2,
                            }}
                            onClick={() => rotaEditar(rota.idRota)}
                          >
                            <i className="pi pi-pencil" title="Editar Rota"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center">
                        Nenhuma rota encontrada
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            )}
            <Pagination
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={totalPosts}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Accordion>
        </div>
      </div>
    </Tela>
  );
}

export default Rotas;
