/* eslint-disable */

import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import instanceMaquina from '../../config/axiosMaquina';
import {
  soNumeros,
  maskCpnj,
  maskTel,
  soLetras,
  validaNumero,
  maskCep,
} from '../../util/Validacoes/Validacoes';

type ModelValidationType = {
  isCnpjInvalid: boolean;
  isFormInvalid: boolean;
  exists: boolean;
};

export type ModelVinculoTransportador = {
  crt_tx_razao_social_trans: string;
  crt_tx_cnpj: string;
  crt_bl_gestao_patio_trans: boolean;
  cli_tx_codigo_legado: number;
};

export default function VincularTrasportadorAdd() {
  const { addToast } = useToast();
  const history = useHistory();
  const { clienteId } = getCookieSessionData().usuarioVO;

  const [model, setModel] = useState<ModelVinculoTransportador>({
    crt_tx_razao_social_trans: '',
    crt_tx_cnpj: '',
    crt_bl_gestao_patio_trans: false,
    cli_tx_codigo_legado: 0,
  });

  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [modelValidation, setModelValidation] = useState<ModelValidationType>({
    isCnpjInvalid: false,
    isFormInvalid: false,
    exists: false,
  });

  function handleCnpjValidation(cnpj: string) {
    if (cnpj.length == 0 || cnpj.length == 18) {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: false,
      });
    } else {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: true,
      });
    }
  }

  function updateModel(e: ChangeEvent<HTMLInputElement>, validation?: Function) {
    const { name, value } = e.target;
    setModel((prevState: any) => ({
      ...prevState,
      [name]: validation ? validation(value) : value,
    }));
  }

  const verificaCnpjExistente = async () => {
    try {
      const response = await instanceMaquina.get(
        `/embarcador/existe-cnpj-cliente?cnpjEmbarcador=${soNumeros(
          model.crt_tx_cnpj
        )}&idCliente=${clienteId}`
      );
      setModelValidation((prevState) => ({
        ...prevState,
        exists: response.data,
      }));
    } catch (error) {
      console.error('Erro ao verificar CNPJ existente:', error);
    }
  };

  useEffect(() => {
    if (model.crt_tx_cnpj.length === 18) {
      verificaCnpjExistente && verificaCnpjExistente();
    } else {
      setModelValidation({
        ...modelValidation,
        exists: false,
      });
    }
  }, [model.crt_tx_cnpj]);

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    if (
      !model.crt_tx_cnpj ||
      !model.crt_tx_razao_social_trans ||
      !model.cli_tx_codigo_legado ||
      modelValidation.isCnpjInvalid ||
      modelValidation.exists
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setModelValidation((prevState) => ({
        ...prevState,
        isFormInvalid: true,
      }));
      return;
    }

    const data = {
      ...model,
      crt_tx_cnpj: model.crt_tx_cnpj.replace(/\D/g, ''),
    };

    try {
      await instanceMaquina.post(`/create-vinculo-transportador?idCliente=${clienteId}`, data);
      setIsDialogVisibleCadEfetuado(true);
    } catch (error) {
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Vincular transportador cadastrado com sucesso!"
        footer={<Button label="OK" onClick={() => history.push('/vincular-transportador/1')} />}
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadEfetuado(false)}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Cadastro de Vincular Transportador</h2>
          {isAllowedByRole(['user']) && (
            <h6 className="subtitulo">{'Cadastro > Vincular Transportador'}</h6>
          )}
          {isAllowedByRole(['admin']) && (
            <h6 className="subtitulo">
              {'Cadastro > Gerenciamento de Clientes > Vincular Transportador'}
            </h6>
          )}
        </div>

        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>

        <Form
          className="form-responsive"
          onSubmit={onSubmit}
          noValidate
          validated={modelValidation.isFormInvalid}
        >
          <Form.Check
            checked={model.crt_bl_gestao_patio_trans}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setModel({
                ...model,
                crt_bl_gestao_patio_trans: e.target.checked,
              });
            }}
            name="crt_bl_gestao_patio_trans"
            type="switch"
            id="custom-switch1"
            label="Pátio"
            style={{ marginBottom: '1rem' }}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCnpj">
              <Form.Label className="require">CNPJ Nº</Form.Label>
              <Form.Control
                type="text"
                name="cnpj"
                placeholder=""
                required
                maxLength={18}
                minLength={18}
                isInvalid={modelValidation.exists}
                value={maskCpnj(model.crt_tx_cnpj)}
                onBlur={() => handleCnpjValidation(model.crt_tx_cnpj)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setModel({
                    ...model,
                    crt_tx_cnpj: e.target.value,
                  });
                }}
              />
              {modelValidation.isCnpjInvalid && (
                <Form.Control.Feedback type="invalid">CNPJ inválido.</Form.Control.Feedback>
              )}
              {modelValidation.exists && (
                <Form.Control.Feedback type="invalid">
                  CNPJ já possui regra de apólice cadastrada.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRazaoSocial">
              <Form.Label className="require">Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="razaoSocial"
                placeholder=""
                maxLength={256}
                required
                value={model.crt_tx_razao_social_trans}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setModel({
                    ...model,
                    crt_tx_razao_social_trans: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group
            style={{ paddingRight: '0', paddingLeft: '0' }}
            md={3}
            as={Col}
            controlId="formGridcodReferencia"
          >
            <Form.Label className="require">Cód Legado</Form.Label>
            <Form.Control
              name="cli_tx_codigo_legado"
              placeholder=""
              maxLength={256}
              required
              value={model.cli_tx_codigo_legado !== 0 ? model.cli_tx_codigo_legado : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setModel({
                  ...model,
                  cli_tx_codigo_legado: Number(e.target.value),
                });
              }}
            />
          </Form.Group>

          <ButtonsForm />
        </Form>
      </div>
    </div>
  );
}
