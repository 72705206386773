/* eslint-disable */
export function validaVazioNulo(e: any): Boolean {
  if (e === '' || e === undefined || e === null) {
    return false;
  } else return true;
}

export function maxNascDate(): Date {
  let current = new Date();
  const ms = current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18;
  let max = new Date(ms);
  return max;
}

export function validarPlaca(placa: string) {
  var regex = new RegExp('^[a-zA-Z0-9]+$');
  const numberArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

  if ((placa && !regex.test(placa)) || placa.length > 7) {
    return false;
  }
  if (placa.length <= 3 && !numberArr.includes(placa.slice(-1))) {
    return true;
  } else if (placa.length === 4 && numberArr.includes(placa.slice(-1))) {
    return true;
  } else if (placa.length === 5) {
    return true;
  } else if (placa.length > 5 && numberArr.includes(placa.slice(-1))) {
    return true;
  }
}

export function validaRG(rg: any) {
  return rg.replace(/^[.-]+/g, '');
  // return d.replace(/[^0-9]+/g, '');
}

export function maskPlaca(placa: string) {
  var pattern = '[A-Z]{3}[0-9][0-9A-Z][0-9]{2}';
  if (pattern.match(placa)) {
    return placa;
  }
}

export function validateName(s: String): boolean {
  const fields = s.split(' ');
  if (fields.length < 2) return true;
  else return false;
}

export function maskTel(telefone: any) {
  if (!telefone) {
    telefone = '';
  }
  telefone = telefone.replace(/\D/g, '');
  telefone = telefone.replace(/^(\d)/, '($1');
  telefone = telefone.replace(/(.{3})(\d)/, '$1)$2');
  if (telefone.length == 9) {
    telefone = telefone.replace(/(.{1})$/, '-$1');
  } else if (telefone.length == 10) {
    telefone = telefone.replace(/(.{2})$/, '-$1');
  } else if (telefone.length == 11) {
    telefone = telefone.replace(/(.{3})$/, '-$1');
  } else if (telefone.length == 12) {
    telefone = telefone.replace(/(.{4})$/, '-$1');
  } else if (telefone.length > 12) {
    telefone = telefone.replace(/(.{4})$/, '-$1');
  }
  return telefone;
}

export function unmaskTel(telefone: any): string {
  if (!telefone) return '';
  return telefone.replace('(', '').replace(')', '').replace('-', '');
}

export function maskRg(v0: any) {
  var v = v0.toUpperCase().replace(/[^\dX]/g, '');
  return v.length == 8 || v.length == 9
    ? v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, '$1.$2.$3-$4')
    : v0;
}

export function maior18() {
  const current = new Date();
  const maxDate = new Date(current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18);
  return maxDate;
}

export const validaDataMaxRG = () => {
  const current = new Date();
  const maxDate = new Date(current.getTime() - 1000 * 60 * 60 * 24 * 365 * 100);
  const maxDateFormatado =
    maxDate.getFullYear().toString() +
    '-' +
    (maxDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    maxDate.getDate().toString().padStart(2, '0');
  return maxDateFormatado;
};

export const maxDateFormatadoMaior18 = () => {
  const current = new Date();
  const maxDate = new Date(
    current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
  );
  const maxDateFormatado =
    maxDate.getFullYear().toString() +
    '-' +
    (maxDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    maxDate.getDate().toString().padStart(2, '0');
  return maxDateFormatado;
};

export const validaDataMaxCNH = () => {
  const current = new Date();
  const maxDate = new Date(
    current.getTime() + 1000 * 60 * 60 * 24 * 365 * 10 + 1000 * 60 * 60 * 24 * 120
  );
  const maxDateFormatado =
    maxDate.getFullYear().toString() +
    '-' +
    (maxDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    maxDate.getDate().toString().padStart(2, '0');
  return maxDateFormatado;
};

export const validaDataAtualVencimento = () => {
  const current = new Date();
  const maxDate = new Date(current.getTime());
  const maxDateFormatado =
    maxDate.getFullYear().toString() +
    '-' +
    (maxDate.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    (maxDate.getDate() + 1).toString().padStart(2, '0');
  return maxDateFormatado;
};

export function soNumeros(d: any) {
  return d.replace(/[^0-9]+/g, '');
}

export function soNumerosNegativo(d: any) {
  var regex = d.match(/[+\-0-9]+/g);
  return regex;
}

export function moneyToNumber(money = '0'): number {
  const onlyNumbers = money.replace(/[^0-9]+/g, '');
  // Format arbitrary value do get fraction amount
  const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
  const parts = formatter.formatToParts(1.11111);

  const fraction = parts.find((e) => e.type === 'fraction');
  if (!fraction) {
    return Number(onlyNumbers);
  }

  // Find out where to put the dot using the newly discovered fraction amount
  const dotPosition = onlyNumbers.length - fraction.value.length;
  const number = addStringAtPosition(onlyNumbers, '.', dotPosition);
  return Number(number);
}

export function toCurrency(number: number | string) {
  if (!number) {
    number = 0;
  }
  const formatter = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(Number(number));
}

export function toDecimal(number: number | string) {
  if (typeof number == 'string') {
    number = soNumeros(number);
  }
  const formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
  });
  return formatter.format(Number(number) / 100).replaceAll(',', '');
}

function addStringAtPosition(text: string, string: string, index: number): string {
  if (index > 0) {
    return text.substring(0, index) + string + text.substring(index, text.length);
  }
  return string + text;
}

export function maskCep(d: any) {
  d = soNumeros(d);
  d = d.replace(/^(\d{5})(\d)/, '$1-$2');
  return d;
}

export function maskCpnj(d: any) {
  d = soNumeros(d);
  d = d.replace(/^(\d{2})(\d)/, '$1.$2');
  d = d.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  d = d.replace(/\.(\d{3})(\d)/, '.$1/$2');
  d = d.replace(/(\d{4})(\d)/, '$1-$2');
  return d;
}

export function getMoney(str: any) {
  return parseInt(str.replace(/[\D]+/g, ''));
}
export function formatReal(int: any) {
  var tmp = int + '';
  tmp = tmp.replace(/([0-9]{2})$/g, ',$1');
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

  return tmp;
}

export function validaNome(n: string) {
  var nomes = n.trim().split(' ');
  if (nomes.length > 1) {
    var valido = false;
    for (var x = 0; x < nomes.length; x++) {
      if (nomes[x].match(/^[a-zA-Z\u00C0-\u017F´]*$/)) {
        valido = true;
      } else {
        valido = false;
        break;
      }
    }

    if (valido) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function soLetras(v: any) {
  return v.replace(/[^a-z A-Zà-úÀ-Ú]/s, ''); //Remove tudo o que não é Letra
}

export function semCaracterEspecial(v: any) {
  return v.replace(/[^a-z .A-Z0-9]+/, ''); //Remove tudo o que não é Letra
}

export function noArroba(a: any) {
  return a.replace(/[^a-z .A-Z0-9*&%$#!><_-]+/, ''); //Remove tudo o que não é Letra
}

export function validaNumero(d: any) {
  var numeros = d;
  if (numeros.length > 0) {
    var valido = false;
    for (var x = 0; x < numeros.length; x++) {
      if (numeros[x].match(/[0-9]+$/)) {
        valido = true;
      } else {
        valido = false;
        break;
      }
    }
    if (valido) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function validaNumeroCinco(value: string) {
  // Retorna true se o valor estiver vazio ou se for exatamente 5 dígitos
  return value.length === 0 || /^[0-9]{5}$/.test(value);
}

export function validaNumeroAntt(d: any) {
  var numeros = d.toString();
  if (numeros.length > 0) {
    var valido = false;
    for (var x = 0; x < numeros.length; x++) {
      if (numeros[x].match(/[0-9]+$/)) {
        valido = true;
      } else {
        valido = false;
        break;
      }
    }
    if (valido) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function validaFormatoPlaca(d: any) {
  var numeros = d.toString();
  if (numeros.length > 0) {
    var valido = false;

    for (var x = 0; x < 3; x++) {
      if (numeros[x].match(/[A-Z]+$/)) {
        valido = true;
      }
    }

    if (valido) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export const cpfMask = (value: string) => {
  return value
    ?.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};
export function FormatTimezoneToGrid(data: any) {
  var dia = data.split('T')[0];
  var mes = data.split('-')[1];
  var ano = data.split('-')[0];

  return ('0' + dia).slice(-2) + '/' + mes.slice(-2) + '/' + ano;
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export function FormataStringData(data: any) {
  if (data === undefined) {
    return;
  }

  var dia = data.split('/')[2];
  var mes = data.split('/')[1];
  var ano = data.split('/')[0];

  return ('0' + dia).slice(-2) + '/' + ('0' + mes).slice(-2) + '/' + ano;
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export function FormataStringData2(data: any) {
  if (!data) {
    return ''; // or handle the null/undefined case as needed
  }

  const [ano, mes, dia] = data.split('-');

  return ('0' + dia).slice(-2) + '/' + ('0' + mes).slice(-2) + '/' + ano;
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export function FormataStringData3(data: any) {
  var dia = data.split('-')[2];
  var mes = data.split('-')[1];
  var ano = data.split('-')[0];

  return ano + '/' + ('0' + mes).slice(-2) + '/' + ('0' + dia).slice(-2);
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export function FormataStringData4(data: any) {
  var dia = data.split('/')[0];
  var mes = data.split('/')[1];
  var ano = data.split('/')[2];

  return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export function validaEmail(email: any) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function validaEmailsEmLista(emails: string): boolean {
  emails = emails.trim();
  if (emails.endsWith(';')) {
    emails = emails.slice(0, -1);
  }

  const listaEmails = emails.split(';');

  return listaEmails.every((email) => validaEmail(email.trim()));
}

export function validateLatLgn(n: any) {
  let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0).{1}d{1,6}');

  return pattern.test(n);
}

export function validateLat(lat: any) {
  let pattern = new RegExp('^-?([0-9]{2})\\,{1}\\d{6}');

  return pattern.test(lat);
}

export function validateLng(lng: any) {
  let pattern = new RegExp('^-?([0-9]{3})\\,{1}\\d{6}');

  return pattern.test(lng);
}

export function LngPos(lng: any) {
  let longitude = lng.slice(9).replace();
  return longitude;
}

export function validaSenha(senha: string): boolean {
  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{5,10}$/;
  return regex.test(senha);
}

export const soNumeros2 = (value: string) => {
  return value.replace(/[0-9]+$/, ''); // substitui qualquer caracter que nao seja numero por nada
};

export const isHourLessThanBaseHour = ({
  baseDate,
  baseHour,
  date,
  hour,
  minutesAdition,
}: {
  baseDate?: string;
  baseHour?: string;
  date: string;
  hour: string;
  minutesAdition?: number;
}) => {
  const baseDateArr = baseDate && baseDate.split('-');
  const baseHourArr = baseHour && baseHour.split(':');
  const dateArr = date.split('-');
  const hourArr = hour.split(':');

  const baseDateAndHour =
    baseDateArr && baseHourArr
      ? new Date(
          parseInt(baseDateArr[0], 10),
          parseInt(baseDateArr[1], 10) - 1,
          parseInt(baseDateArr[2], 10),
          parseInt(baseHourArr[0], 10),
          parseInt(baseHourArr[1], 10)
        )
      : new Date();

  if (minutesAdition) {
    baseDateAndHour.setMinutes(baseDateAndHour.getMinutes() + minutesAdition);
  }

  const dateAndHour =
    dateArr && hourArr
      ? new Date(
          parseInt(dateArr[0], 10),
          parseInt(dateArr[1], 10) - 1,
          parseInt(dateArr[2], 10),
          parseInt(hourArr[0], 10),
          parseInt(hourArr[1], 10)
        )
      : new Date();

  if (dateAndHour < baseDateAndHour) {
    return true;
  } else {
    return false;
  }
};

export const isHourEqualsThanBaseHour = ({
  baseDate,
  baseHour,
  date,
  hour,
  minutesAdition,
}: {
  baseDate?: string;
  baseHour?: string;
  date: string;
  hour: string;
  minutesAdition?: number;
}) => {
  const baseDateArr = baseDate && baseDate.split('-');
  const baseHourArr = baseHour && baseHour.split(':');
  const dateArr = date.split('-');
  const hourArr = hour.split(':');

  const baseDateAndHour =
    baseDateArr && baseHourArr
      ? new Date(
          parseInt(baseDateArr[0], 10),
          parseInt(baseDateArr[1], 10) - 1,
          parseInt(baseDateArr[2], 10),
          parseInt(baseHourArr[0], 10),
          parseInt(baseHourArr[1], 10)
        )
      : new Date();

  if (minutesAdition) {
    baseDateAndHour.setMinutes(baseDateAndHour.getMinutes() + minutesAdition);
  }

  const dateAndHour =
    dateArr && hourArr
      ? new Date(
          parseInt(dateArr[0], 10),
          parseInt(dateArr[1], 10) - 1,
          parseInt(dateArr[2], 10),
          parseInt(hourArr[0], 10),
          parseInt(hourArr[1], 10)
        )
      : new Date();

  if (dateAndHour <= baseDateAndHour) {
    return true;
  } else {
    return false;
  }
};

export const isDateLessThanBaseDate = ({
  baseDate,
  date,
}: {
  baseDate?: string;
  date?: string;
}) => {
  const baseDateArr = baseDate && baseDate.split('-');
  const dateArr = date && date.split('-');

  const baseDateFormated = baseDateArr
    ? new Date(
        parseInt(baseDateArr[0], 10),
        parseInt(baseDateArr[1], 10) - 1,
        parseInt(baseDateArr[2], 10)
      )
    : new Date();

  baseDateFormated.setHours(0);
  baseDateFormated.setMinutes(0);
  baseDateFormated.setSeconds(0);
  baseDateFormated.setMilliseconds(0);

  const dateFormated = dateArr
    ? new Date(parseInt(dateArr[0], 10), parseInt(dateArr[1], 10) - 1, parseInt(dateArr[2], 10))
    : new Date();

  dateFormated.setHours(0);
  dateFormated.setMinutes(0);
  dateFormated.setSeconds(0);
  dateFormated.setMilliseconds(0);

  if (dateFormated < baseDateFormated) {
    return true;
  } else {
    return false;
  }
};

export const addDaysToDate = (dateString: string, days: number): string => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
};

export const isDateGreaterThanBaseDateFim = ({
  baseDate,
  date,
}: {
  baseDate?: string;
  date?: string;
}) => {
  const baseDateArr = baseDate && baseDate.split('-');
  const dateArr = date && date.split('-');

  const baseDateFormated = baseDateArr
    ? new Date(
        parseInt(baseDateArr[0], 10),
        parseInt(baseDateArr[1], 10) - 1,
        parseInt(baseDateArr[2], 10)
      )
    : new Date();

  baseDateFormated.setHours(0);
  baseDateFormated.setMinutes(0);
  baseDateFormated.setSeconds(0);
  baseDateFormated.setMilliseconds(0);

  const dateFormated = dateArr
    ? new Date(parseInt(dateArr[0], 10), parseInt(dateArr[1], 10) - 1, parseInt(dateArr[2], 10))
    : new Date();

  dateFormated.setHours(0);
  dateFormated.setMinutes(0);
  dateFormated.setSeconds(0);
  dateFormated.setMilliseconds(0);

  if (dateFormated <= baseDateFormated) {
    return true;
  } else {
    return false;
  }
};

export function DataMaxima(data: any, d: number) {
  if (!data) return '';

  const novaData = new Date(data);
  novaData.setDate(novaData.getDate() + d);
  var dia = novaData.getDate();
  var mes = novaData.getMonth() + 1;
  var ano = novaData.getFullYear();

  return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}
