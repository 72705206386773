/* eslint-disable */

import { useEffect, useState, useCallback } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { IoAddCircleOutline, IoMailUnread, IoRadioButtonOnOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { Accordion, Form, Spinner } from 'react-bootstrap';
import GoogleMaps from '../../components/GoogleMaps/GoogleMapsPatio';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { ActiveSM, PositionTruck } from '../../types';
import { Progressao } from '../ProgressaoViagens/ProgressaoViagens';
import { handleRota } from '../../util/getPontosLista';
import { Tela } from '../../components/Tela';
import { soNumeros } from '../../util/Validacoes/Validacoes';
import { obterDataHora } from '../../util/date';
import { isBefore, isEqual } from 'date-fns';
import { matchSmId, vehicleStatusTravel } from '../../util/transitTime';
import { handleListActiveSM } from '../../services/transitTime';
import { PercentageDiv, TableData, TableHead, TableRow } from '../Mapa/styles';
import { MapaFilter } from '../Mapa/Filter';
import { InputNumberToken } from 'antd/es/input-number/style/token';
import { log } from 'console';

export type ListVeiculosPatio = {
  bau: boolean;
  embarcador: string;
  ignicao: boolean;
  latitude: number;
  localizacao: string;
  longitude: number;
  position: string;
  status: string;
  trasportador: string;
  vei_cd_id: number;
  vei_tx_placa: string;
  velociade: number;
};

type ListMonitoramentoSmMapaPontoVO = {
  distancia: string;
  horario: string;
  idSm: number;
  latitude: number;
  longitude: number;
  ponto: string;
  remessa: string;
  previsao: string;
  previsaocomparadas: string;
  raioAproximacao: number;
  dtHrPlanejadaViagemPonto: string;
  dtHrChegada: string;
  dtHrIncioRealSm: string;
  dtHrPrevisaoComParadas: string;
  dtHrPrevisao: string;
};

type ControleType = { velocidade: number; temperatura: number };

type filtersName =
  | 'Todos'
  | 'Placa'
  | 'Status'
  | 'Posicao'
  | 'Transportador'
  | 'Embarcador'
  | 'Localizacao'
  | 'Bau'
  | 'Ignicao';

type LatLng = {
  lat: any;
  lng: any;
};
const cookieSessionData = getCookieSessionData();
const clienteId = cookieSessionData?.usuarioVO?.clienteId;

const MapaPatio = () => {
  const location = useLocation();
  const { usuarioId, clienteId } = getCookieSessionData().usuarioVO;
  const { usuarioVO } = getCookieSessionData();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filtroBusca, setFiltroBusca] = useState<filtersName>('Todos');
  const [smFiltrada, setSmFiltrada] = useState<ListVeiculosPatio[]>([]);
  const [filter, setFilter] = useState(
    location.pathname === '/mapa-operacao' || !usuarioVO.isEmbarcador ? true : false
  );
  const [sm, setSm] = useState<ListVeiculosPatio[]>([]);
  const [veiculosMapa, setVeiculosMapa] = useState<any[]>([]);
  const [options, setOptions] = useState<ListVeiculosPatio[]>([]);
  const [filtrado, setFiltrado] = useState<ListVeiculosPatio[]>([]);
  const [message, setMessage] = useState<string>('');
  const [positionsTruck, setPostitionsTruck] = useState<PositionTruck[]>([]);
  const [atrasados, setAtrasados] = useState<ListVeiculosPatio[]>([]);
  const [parametroLogistico, setParametroLogistico] = useState<{
    tempoAtualizacaoDadosEstaticosEmMinutos: number;
    raioDeAproximacaoVeiculosEmMetros: number;
    tempoAtualizacaoMapaEmMinutos: number;
  }>();
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);
  const [dataProgressao, setDataProgressao] = useState<ListVeiculosPatio[]>([]);
  const [goToMap, setGoToMap] = useState(false);
  const [clickLatitude, setClickLatitude] = useState(0);
  const [clickLongitude, setclickLongitude] = useState(0);
  const [flagProgressaoViagem, setFlagProgressaoViagem] = useState<number>();
  const [flagControles, setFlagControles] = useState<ControleType>({
    velocidade: 0,
    temperatura: 0,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedMarker, setSelectedMarker] = useState<LatLng>();
  const [center, setCenter] = useState<LatLng | undefined>({ lat: 0, lng: 0 });
  const [smActive, setSMActive] = useState<ActiveSM[]>([]);

  useEffect(() => {
    instanceMaquina
      .get('parametro-logistico')
      .then((response: any) => {
        setParametroLogistico(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getServicosContratados = async () => {
    try {
      const { data } = await instanceMaquina.get(
        `/cliente/getServicosContratadosCliente?cliente=${clienteId}`
      );
      setFlagProgressaoViagem(data.cscNuCronogramaViagens);
      setFlagControles({
        velocidade: data.controleVelocidade,
        temperatura: data.controleTemperatura,
      });
    } catch (err: any) {
      setLoading(false);
      console.error(err);
    }
  };

  const getMapaData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instanceMaquina.get('list-veiculos-patio', {
        params: {
          idCliente: clienteId,
        },
      });

      setAtrasados(response.data);
      setDataProgressao(response.data);
      setFiltrado(response.data);

      const { data } = await instanceMaquina.get('/veiculo/consulta-gerenciar-veiculo');
      const veiculos = data.filter((d: any) => d.mapa);
      setVeiculosMapa(veiculos);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [atrasados, usuarioId]);

  useEffect(() => {
    if (smFiltrada.length && !filter) {
      setSm(smFiltrada);
    }
  }, [smFiltrada, filter]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!parametroLogistico) {
      getServicosContratados();
      return;
    }

    getMapaData();
    const milisegundosParaAtualizacao =
      parametroLogistico.tempoAtualizacaoMapaEmMinutos * 1000 * 60;
    timer = setTimeout(() => {
      setIsDialogAtualizandoOK(true);
      getMapaData();
    }, milisegundosParaAtualizacao);
    setIsDialogAtualizandoOK(false);
    return () => clearTimeout(timer);
  }, [parametroLogistico, sm]);

  useEffect(() => {
    console.log('searchTerm', searchTerm);

    if (searchTerm.length) {
      search();
    } else {
      setFiltrado(atrasados);
    }
  }, [searchTerm]);

  const search = () => {
    if (searchTerm.trim() === '') {
      setFiltrado(atrasados);
      return;
    }

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = filtrado.filter((each) => {
      switch (filtroBusca) {
        case 'Todos':
          return (
            each.vei_tx_placa?.toLowerCase().includes(lowerCaseSearchTerm) ||
            (each.position && obterDataHora(each.position, true)?.includes(searchTerm)) ||
            each.status?.toLowerCase().includes(lowerCaseSearchTerm) ||
            (usuarioVO.isEmbarcador
              ? each.trasportador?.toLowerCase().includes(lowerCaseSearchTerm)
              : each.embarcador?.toLowerCase().includes(lowerCaseSearchTerm)) ||
            each.localizacao?.toLowerCase().includes(lowerCaseSearchTerm)
          );
        case 'Placa':
          return each.vei_tx_placa?.toLowerCase().includes(lowerCaseSearchTerm);
        case 'Posicao':
          return each.position && obterDataHora(each.position, true)?.includes(searchTerm);
        case 'Status':
          return each.status?.toLowerCase().includes(lowerCaseSearchTerm);
        case 'Embarcador':
          return each.embarcador?.toLowerCase().includes(lowerCaseSearchTerm);
        case 'Transportador':
          return each.trasportador?.toLowerCase().includes(lowerCaseSearchTerm);
        case 'Localizacao':
          return each.localizacao?.toLowerCase().includes(lowerCaseSearchTerm);
        default:
          return false;
      }
    });

    setFiltrado(filtered);
  };

  useEffect(() => {
    let positionsTruck: PositionTruck[] = [];

    sm.map((sm) => {
      const truckLocation = {
        lat: sm.latitude,
        lng: sm.longitude,
        isTruck: true,
        transportador: sm.trasportador,
        statusIgnicao: true,
        placas: sm.vei_tx_placa,
      };
      positionsTruck.push(truckLocation);
    });

    setPostitionsTruck(positionsTruck);
  }, [sm]);

  function renderAtrasado(each: ListVeiculosPatio) {
    return (
      <button
        onClick={() => clickGoToMap(each.latitude, each.longitude)}
        title={each.latitude ? `${each.latitude}, ${each.longitude}` : ''}
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
      >
        <IoRadioButtonOnOutline size={18} color={vehicleStatusTravel(each as any)} />
      </button>
    );
  }

  const positionOnClick = {
    lat: clickLatitude,
    lng: clickLongitude,
  };

  const clickGoToMap = (latitude: number, longitude: number) => {
    setGoToMap(true);
    setClickLatitude(Number(latitude));
    setclickLongitude(Number(longitude));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (map) {
      setCenter(positionOnClick);
      map.setZoom(14);
    }
  };

  const handleFilter = () => {
    setFilter(false);
  };

  const isSameOrBefore = (date1: string, date2: string) => {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    return isBefore(firstDate, secondDate) || isEqual(firstDate, secondDate);
  };

  // modifying the original list of vehicles to add the statusSm property
  matchSmId(filtrado as any, smActive);

  return (
    <Tela nome="Pátio" caminho="Rastreamento > Pátio">
      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          setIsDialogAtualizandoOK(false);
        }}
      />

      <>
        {(!usuarioVO.isEmbarcador || location.pathname === '/mapa-operacao') && (
          <div className="return-btn">
            <Button
              color="white"
              onClick={() => {
                setFilter(true);
              }}
              size={12}
            >
              Voltar
            </Button>
          </div>
        )}

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <GoogleMaps
              map={map!}
              setMap={setMap}
              selectedMarker={selectedMarker!}
              setSelectedMarker={setSelectedMarker}
              positions={positionsTruck}
              raio={parametroLogistico?.raioDeAproximacaoVeiculosEmMetros || undefined}
              goToMap={goToMap}
              goLatitude={clickLatitude}
              goLongitude={clickLongitude}
              center={center!}
              dataPatio={filtrado}
              setCenter={setCenter}
            />

            <div
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Form.Group className="row-modal" style={{ maxWidth: '160px', marginRight: 20 }}>
                <Form.Control
                  id="viagemEscolta"
                  name="viagemEscolta"
                  placeholder=""
                  onChange={(e) => {
                    setFiltroBusca(e.target.value as filtersName);
                  }}
                  as="select"
                  disabled={!sm}
                >
                  <option value="Todos">Todos</option>
                  <option value="Placa">Placa</option>
                  <option value="Status">Status</option>
                  <option value="Posicao">Posição</option>
                  <option value="Transportador">Transportador</option>
                  <option value="Embarcador">Embarcador</option>
                  <option value="Localizacao">Localização</option>
                  {/* <option value="Bau">Baú</option>
            <option value="Ignicao">Ignição</option> */}
                </Form.Control>
              </Form.Group>
              <span className="p-input-icon-left">
                <input
                  type="text"
                  placeholder={filtroBusca}
                  className="p-inputtext p-component"
                  id="search-input"
                  style={{ width: '340px', marginBottom: '17px', height: '38px' }}
                  value={searchTerm}
                  disabled={loading}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchTerm(e.target.value)
                  }
                />
                <i className="pi pi-search" style={{ marginTop: '-16px' }}></i>
              </span>
            </div>

            <Accordion>
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <table
                  className="table"
                  style={{
                    whiteSpace: 'nowrap',
                    width: '100%',
                  }}
                >
                  <thead className="thead" style={{ width: '100%' }}>
                    <TableRow isDialogAtualizandoOK={isDialogAtualizandoOK}>
                      <TableHead>Placa</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead>Posição</TableHead>
                      <TableHead>Transportador</TableHead>
                      <TableHead>Embarcador</TableHead>
                      <TableHead>Localização</TableHead>
                      <TableHead>Bau</TableHead>
                      <TableHead>Ignição</TableHead>
                      <TableHead>Vel</TableHead>
                    </TableRow>
                  </thead>
                  <tbody style={{ overflowY: 'auto', height: '100%' }}>
                    {filtrado.map((each, index) => (
                      <tr key={index}>
                        <TableData>{each.vei_tx_placa}</TableData>
                        <TableData>
                          {each.status ? (
                            <>
                              <button
                                onClick={() => clickGoToMap(each.latitude, each.longitude)}
                                title={`${each.latitude}, ${each.longitude}`}
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                  padding: 0,
                                }}
                              >
                                <IoRadioButtonOnOutline size={18} color="#9F9F9F" />
                              </button>
                              <span>{each.status}</span>
                            </>
                          ) : (
                            <>
                              {renderAtrasado(each)}
                              <span>{each.status}</span>
                            </>
                          )}
                        </TableData>
                        <TableData>{each.position}</TableData>
                        <TableData>{each.trasportador}</TableData>

                        <TableData>{each.embarcador || '-/-'}</TableData>
                        <TableData style={{ textAlign: 'left' }} title={each.localizacao}>
                          {each.localizacao === null
                            ? ''
                            : each.localizacao.length > 35
                            ? `${each.localizacao.slice(0, 35)}...`
                            : each.localizacao}
                        </TableData>
                        <TableData>{each.bau ? 'ABERTO' : 'FECHADO'}</TableData>
                        <TableData>{each.ignicao ? 'ON' : 'OFF'}</TableData>
                        {flagControles.velocidade ? (
                          <TableData style={{ textAlign: 'center' }}>
                            {each.velociade || '-'}
                          </TableData>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Accordion>
          </>
        )}
      </>
      {!loading && message && (
        <div style={{ margin: '10px auto' }}>
          <strong>{message}</strong>
        </div>
      )}
    </Tela>
  );
};

export default MapaPatio;
