import React, { FC, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import PontosGeral from './PontosGeral';
import PontosPatio from './PontosPatio/PontosPatio';

export const Pontos: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.PARAMETRO.SISTEMA] }], 'editar');
  }, [ADMIN, rolePermission]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Sistema</h2>
          <h6 className="subtitulo">{'Módulo Gerenciador -> Parâmetros -> Sistema'}</h6>
        </div>
        <div className="painel">
          <Tabs defaultActiveKey="pontosGeral" id="uncontrolled-tab-example">
            <Tab eventKey="pontosGeral" title="Pontos">
              <div style={{ marginTop: 12 }}>
                <PontosGeral />
              </div>
            </Tab>
            <Tab eventKey="pontosPatio" title="Pontos Pátio">
              <div style={{ marginTop: 12 }}>
                <PontosPatio />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
