/* eslint-disable */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ClientesType, SeguradorasType, CorretorasType } from '../../api/model/UsuariosType';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import Input from '../../components/Input';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Tela } from '../../components/Tela';
import instanceMaquina from '../../config/axiosMaquina';
import { STATUS } from '../../constants/SolicitacaoMonitoramento';
import { useToast } from '../../hooks/Toast';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import checaCPF from '../../util/checaCpf';
import { toZoned } from '../../util/date';
import { cpfMask, soLetras, soNumeros, validarPlaca } from '../../util/Validacoes/Validacoes';
import './styles.css';

export type EmpresaComboBox = {
  id: number;
  razaoSocial: string;
  ativo: boolean;
};

type UsuariosComboBox = {
  usuCdId: number;
  nome: string;
};

const ConsultarSMLancadas: FC = () => {
  const [dataInicio, setDataInicio] = useState('');
  const [dataInicioLancamento, setDataInicioLancamento] = useState('');
  const [dataInicioZ, setDataInicioZ] = useState('');
  const [dataInicioLancamentoZ, setDataInicioLancamentoZ] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [dataFimLancamento, setDataFimLancamento] = useState('');
  const [dataFimZ, setDataFimZ] = useState('');
  const [dataFimLancamentoZ, setDataFimLancamentoZ] = useState('');
  const [placa, setPlaca] = useState('');
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [valorCargaMinimo, setValorCargaMinimo] = useState<number | undefined>();
  const [valorCargaMaximo, setValorCargaMaximo] = useState<number | undefined>();
  const [status, setStatus] = useState<string[]>(STATUS);
  const [idSm, setIdSm] = useState<number | undefined>();
  const [usuarios, setUsuarios] = useState<UsuariosComboBox[]>([]);
  const [idUsuario, setIdUsuario] = useState<number>();
  const [empresa, setEmpresa] = useState<number | undefined>();
  const [empresaArr, setEmpresaArr] = useState<EmpresaComboBox[] | undefined>();
  const [seguradoraArr, setSeguradoraArr] = useState<SeguradorasType[] | undefined>();
  const [corretoraArr, setCorretoraArr] = useState<CorretorasType[] | undefined>();

  const [observacao, setObservacao] = useState('');
  const [romaneio, setRomaneio] = useState('');

  const [idCorretora, setIdCorretora] = useState<number>();
  const [idSeguradora, setIdSeguradora] = useState<number>();

  const [faturamentoNaoInformado, setFaturamentoNaoInformado] = useState(true);
  const [faturamentoMensalista, setFaturamentoMensalista] = useState(true);
  const [faturamentoViagemAvulsa, setFaturamentoViagemAvulsa] = useState(true);
  const [faturamentoNaoRastreado, setFaturamentoNaoRastreado] = useState(true);
  const [faturamentoDesabilitado, setFaturamentoDesabilitado] = useState(true);

  const [viagemIsca, setViagemIsca] = useState<string>('');
  const [escolta, setEscolta] = useState<string>('');

  const [cpfError, setCpfError] = useState('');
  const [valorCargaMaxError, setValorCargaMaxError] = useState('');
  const [dataFimError, setDataFimError] = useState('');

  const history = useHistory();
  const { addToast } = useToast();

  const { cnpj, usuarioId, role, clienteId } = getCookieSessionData().usuarioVO;
  const [loading, setLoading] = useState(false);
  const temFaturamento =
    faturamentoNaoInformado ||
    faturamentoMensalista ||
    faturamentoViagemAvulsa ||
    faturamentoNaoRastreado ||
    faturamentoDesabilitado;
  const periodoObrigatorio =
    !idSm &&
    !empresa &&
    !idCorretora &&
    !idSeguradora &&
    !placa &&
    !escolta &&
    !viagemIsca &&
    !valorCargaMinimo &&
    !valorCargaMaximo &&
    !status &&
    !idUsuario &&
    !observacao &&
    !romaneio &&
    !cpf &&
    !nome &&
    !temFaturamento;

  const buscar = async (event: any) => {
    event.preventDefault();

    if (
      cpfError ||
      valorCargaMaxError ||
      dataFimError ||
      (periodoObrigatorio && (!dataInicio || !dataFim))
    ) {
      addToast({
        title: 'Erro',
        description: 'Atenção aos campos.',
        type: 'error',
      });
      return;
    }

    if (
      cpfError ||
      valorCargaMaxError ||
      dataFimError ||
      (periodoObrigatorio && (!dataInicioLancamento || !dataFimLancamento))
    ) {
      addToast({
        title: 'Erro',
        description: 'Atenção aos campos.',
        type: 'error',
      });
      return;
    }

    try {
      history.push({
        pathname: `/listagem-sm-lancadas/1`,
        state: {
          dataInicioLancamento: dataInicioLancamentoZ,
          dataFimLancamento: dataFimLancamentoZ,
          dataInicio: dataInicioZ,
          dataFim: dataFimZ,
          placa,
          condutorCpf: cpf,
          condutorNome: nome,
          valorMaximo: valorCargaMaximo,
          valorMinimo: valorCargaMinimo,
          status,
          romaneio,
          observacao,
          viagemIsca,
          escolta,
          idUsuario: idUsuario || usuarioId,
          idSm: idSm ? parseInt(idSm?.toString(), 10) : '',
          idCliente: role === 'admin' ? empresa : clienteId,
          faturamento: temFaturamento,
          faturamentoNaoInformado,
          faturamentoMensalista,
          faturamentoViagemAvulsa,
          faturamentoNaoRastreado,
          faturamentoDesabilitado,
          cnpjEmbarcador: cnpj,
        },
      });
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro',
        description: 'Houve algum erro!',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (role === 'admin') {
      instanceMaquina.get('/usuario/gerenciador').then((response) => {
        setUsuarios(response.data);
      });
    }
  }, []);

  useEffect(() => {
    instanceMaquina
      .get('/cliente/grid-cadastro', {
        params: {
          ...(role === 'corretora' && { idUsuarioCorretora: usuarioId }),
          ...(role === 'seguradora' && { idUsuarioSeguradora: usuarioId }),
        },
      })
      .then((response) => {
        setEmpresaArr(response.data);
      });
  }, []);

  useEffect(() => {
    if (role === 'admin') {
      instanceMaquina.get('/seguradora').then((response) => {
        setSeguradoraArr(response.data);
      });
    }
  }, []);

  useEffect(() => {
    if (role === 'admin') {
      instanceMaquina.get('/corretora').then((response) => {
        setCorretoraArr(response.data);
      });
    }
  }, []);

  const handleValidaPlaca = (e: ChangeEvent<HTMLInputElement>) => {
    if (validarPlaca(e.target.value)) {
      setPlaca(e.target.value);
    }
  };

  const handleCheckboxChange = (e: any, statusName: string) => {
    if (e.target.checked) {
      setStatus((prevStatus) => [...prevStatus, statusName]);
    } else {
      setStatus((prevStatus) => prevStatus.filter((status) => status !== statusName));
    }
  };

  const filtraAtivo = (data: any) =>
    data.filter(function (el: any) {
      return el.ativo === true;
    });

  return (
    <Tela
      nome="Consultar Solicitação de Monitoramento Lançadas"
      caminho="Operacional > Consultas e Relatórios > SM lançadas > Consultar SM Lançadas"
      loading={loading}
      setLoading={setLoading}
    >
      <Form noValidate onSubmit={buscar}>
        {role === 'user' ? (
          <>
            <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatorio ? 'requiredField' : ''}>
                  Data Início
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                    if (e.target.value !== '') {
                      setDataInicioZ(toZoned(e.target.value));
                    }
                  }}
                  onBlur={() => {
                    if (dataFim && new Date(dataFim) < new Date(dataInicio)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  value={dataInicio}
                  type="date"
                  id="date"
                  name="date"
                  placeholder=""
                  required={periodoObrigatorio}
                />
                <Form.Control.Feedback type="invalid">
                  Campo Data Início não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatorio ? 'requiredField' : ''}>
                  Data Fim
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataFim(e.target.value);
                    if (e.target.value !== '') {
                      setDataFimZ(toZoned(e.target.value, false));
                    }
                  }}
                  onBlur={() => {
                    if (dataInicio && new Date(dataFim) < new Date(dataInicio)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  style={{ borderColor: dataFimError ? '#ff0000' : '#ced4da' }}
                  value={dataFim}
                  type="date"
                  id="dataFim"
                  name="dataFim"
                  placeholder=""
                  required={periodoObrigatorio}
                />
                {dataFimError && (
                  <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                    {dataFimError}
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Veículo</Form.Label>
            <Form.Group>
              <Form.Label>Placa Nº</Form.Label>
              <Form.Control
                value={placa.toUpperCase()}
                id="rota"
                name="rota"
                placeholder=""
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleValidaPlaca(e);
                }}
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle">Busca por Condutor</Form.Label>
            <Form.Row className="row-container-modal">
              <Input
                error={cpfError}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value && checaCPF(e.target.value) === false) {
                    setCpf(cpfMask(e.target.value));
                    setCpfError('CPF inválido');
                  } else {
                    setCpf(cpfMask(e.target.value));
                    setCpfError('');
                  }
                }}
                textInputTitle="CPF"
                value={cpf}
                type="string"
                id="cpf"
                name="cpf"
              />
              <Input
                onChange={(e) => setNome(soLetras(e.target.value))}
                value={nome}
                type="string"
                maxLength={256}
                id="nomeCondutor"
                name="nomeCondutor"
                textInputTitle="Nome"
              />
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Valor de Carga</Form.Label>
            <Form.Row className="row-container-modal">
              <Input
                onChange={(e) => {
                  if (
                    valorCargaMinimo &&
                    valorCargaMaximo &&
                    parseInt(e.target.value, 10) > valorCargaMaximo
                  ) {
                    setValorCargaMaxError(
                      'O valor da carga máxima não pode ser menor que o valor da carga mínima'
                    );
                  } else {
                    setValorCargaMaxError('');
                  }
                  setValorCargaMinimo(soNumeros(e.target.value));
                }}
                value={valorCargaMinimo}
                type="text"
                maxLength={12}
                id="valorCargaMinimo"
                name="valorCargaMinimo"
                textInputTitle="Valor de carga mínimo"
              />

              <Input
                onChange={(e) => {
                  if (valorCargaMinimo && parseInt(e.target.value, 10) < valorCargaMinimo) {
                    setValorCargaMaxError(
                      'O valor da carga máxima não pode ser menor que o valor da carga mínima'
                    );
                  } else {
                    setValorCargaMaxError('');
                  }
                  setValorCargaMaximo(soNumeros(e.target.value));
                }}
                value={valorCargaMaximo}
                type="text"
                maxLength={12}
                id="valorCargaMaximo"
                name="valorCargaMaximo"
                textInputTitle="Valor de carga máximo"
                error={valorCargaMaxError}
              />
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Viagem</Form.Label>
            <Form.Group>
              <Form.Label>Nº SM</Form.Label>
              <Form.Control
                value={idSm}
                type="text"
                maxLength={10}
                id="numeroSM"
                name="numeroSM"
                placeholder=""
                onChange={(e) => {
                  setIdSm(soNumeros(e.target.value));
                }}
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle">Busca por Situação</Form.Label>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              {STATUS.map((statusName, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={statusName}
                  className="ml-4 mt-2"
                  checked={status.includes(statusName)}
                  onChange={(e) => handleCheckboxChange(e, statusName)}
                />
              ))}
            </Form.Group>

            <Form.Row className="row-container-modal mb-3">
              <Form.Group className="row-modal">
                <Form.Label>Viagens com Escolta</Form.Label>
                <Form.Control
                  id="viagemEscolta"
                  name="viagemEscolta"
                  placeholder=""
                  onChange={(e) => {
                    setEscolta(e.target.value);
                  }}
                  as="select"
                >
                  <option value="">Selecione</option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label>Viagens com Isca</Form.Label>
                <Form.Control
                  name="viagemIsca"
                  placeholder=""
                  onChange={(e) => {
                    setViagemIsca(e.target.value);
                  }}
                  as="select"
                >
                  <option value="">Selecione</option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </>
        ) : role === 'corretora' || role === 'seguradora' ? (
          <>
            <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">Data Início</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                    if (e.target.value !== '') {
                      setDataInicioZ(toZoned(e.target.value));
                    }
                  }}
                  onBlur={() => {
                    if (dataFim && new Date(dataFim) < new Date(dataInicio)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  value={dataInicio}
                  type="date"
                  id="date"
                  name="date"
                  placeholder=""
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Campo Data Início não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label className="requiredField">Data Fim</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataFim(e.target.value);
                    if (e.target.value !== '') {
                      setDataFimZ(toZoned(e.target.value, false));
                    }
                  }}
                  onBlur={() => {
                    if (dataInicio && new Date(dataFim) < new Date(dataInicio)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  style={{ borderColor: dataFimError ? '#ff0000' : '#ced4da' }}
                  value={dataFim}
                  type="date"
                  id="dataFim"
                  name="dataFim"
                  placeholder=""
                  required
                />
                {dataFimError && (
                  <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                    {dataFimError}
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Veículo</Form.Label>
            <Form.Group>
              <Form.Label>Placa Nº</Form.Label>
              <Form.Control
                value={placa.toUpperCase()}
                id="placa"
                name="placa"
                placeholder=""
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleValidaPlaca(e);
                }}
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle">Busca por Condutor</Form.Label>
            <Form.Row className="row-container-modal">
              <Input
                error={cpfError}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value && checaCPF(e.target.value) === false) {
                    setCpf(cpfMask(e.target.value));
                    setCpfError('CPF inválido');
                  } else {
                    setCpf(cpfMask(e.target.value));
                    setCpfError('');
                  }
                }}
                textInputTitle="CPF"
                value={cpf}
                type="string"
                id="cpf"
                name="cpf"
              />
              <Input
                onChange={(e) => setNome(soLetras(e.target.value))}
                value={nome}
                type="string"
                maxLength={256}
                id="nomeCondutor"
                name="nomeCondutor"
                textInputTitle="Nome"
              />
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Valor de Carga</Form.Label>
            <Form.Row className="row-container-modal">
              <Input
                onChange={(e) => {
                  if (
                    valorCargaMinimo &&
                    valorCargaMaximo &&
                    parseInt(e.target.value, 10) > valorCargaMaximo
                  ) {
                    setValorCargaMaxError(
                      'O valor da carga máxima não pode ser menor que o valor da carga mínima'
                    );
                  } else {
                    setValorCargaMaxError('');
                  }
                  setValorCargaMinimo(soNumeros(e.target.value));
                }}
                value={valorCargaMinimo}
                type="text"
                maxLength={12}
                id="valorCargaMinimo"
                name="valorCargaMinimo"
                textInputTitle="Valor de carga mínimo"
              />

              <Input
                onChange={(e) => {
                  if (valorCargaMinimo && parseInt(e.target.value, 10) < valorCargaMinimo) {
                    setValorCargaMaxError(
                      'O valor da carga máxima não pode ser menor que o valor da carga mínima'
                    );
                  } else {
                    setValorCargaMaxError('');
                  }
                  setValorCargaMaximo(soNumeros(e.target.value));
                }}
                value={valorCargaMaximo}
                type="text"
                maxLength={12}
                id="valorCargaMaximo"
                name="valorCargaMaximo"
                textInputTitle="Valor de carga máximo"
                error={valorCargaMaxError}
              />
            </Form.Row>

            <Form.Label className="SMLancadasTitle">Busca por Situação</Form.Label>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              {STATUS.map((statusName, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={statusName}
                  className="ml-4 mt-2"
                  checked={status.includes(statusName)}
                  onChange={(e) => handleCheckboxChange(e, statusName)}
                />
              ))}
            </Form.Group>

            <Form.Label className="SMLancadasTitle">Busca por Viagem</Form.Label>
            <Form.Group>
              <Form.Label>Nº SM</Form.Label>
              <Form.Control
                value={idSm}
                type="text"
                maxLength={10}
                id="numeroSM"
                name="numeroSM"
                placeholder=""
                onChange={(e) => {
                  setIdSm(soNumeros(e.target.value));
                }}
              />
            </Form.Group>
            <Form.Label className="SMLancadasTitle">Busca por Empresa</Form.Label>
            <Form.Group>
              <Form.Label>EMPRESA</Form.Label>
              <Form.Control
                value={empresa}
                id="empresa"
                name="empresa"
                placeholder=""
                onChange={(e) => {
                  setEmpresa(Number(e.target.value));
                }}
                as="select"
              >
                <option>Selecione</option>
                {empresaArr &&
                  filtraAtivo(empresaArr).map((each: EmpresaComboBox) => (
                    <option key={each.id} value={each.id}>
                      {each.razaoSocial}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </>
        ) : role === 'admin' ? (
          <>
            <Form.Label className="SMLancadasTitle mb-3">Busca por Empresa</Form.Label>
            <Form.Group>
              <Form.Label>EMPRESA</Form.Label>
              <Form.Control
                value={empresa}
                id="empresa"
                name="empresa"
                placeholder=""
                onChange={(e) => {
                  setEmpresa(Number(e.target.value));
                }}
                as="select"
              >
                <option>Selecione</option>
                {empresaArr &&
                  filtraAtivo(empresaArr).map((each: EmpresaComboBox) => (
                    <option key={each.id} value={each.id}>
                      {each.razaoSocial}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Seguradora</Form.Label>
            <Form.Group>
              <Form.Label>Seguradora</Form.Label>
              <Form.Control
                value={idSeguradora}
                id="idSeguradora"
                name="idSeguradora"
                placeholder=""
                onChange={(e) => {
                  setIdSeguradora(Number(e.target.value));
                }}
                as="select"
              >
                <option>Selecione</option>
                {seguradoraArr &&
                  seguradoraArr.map((each: any) => (
                    <option key={each.segCdId} value={each.segCdId}>
                      {each.segTxNome}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Corretora</Form.Label>
            <Form.Group>
              <Form.Label>Corretora</Form.Label>
              <Form.Control
                value={idCorretora}
                id="idCorretora"
                name="idCorretora"
                placeholder=""
                onChange={(e) => {
                  setIdCorretora(Number(e.target.value));
                }}
                as="select"
              >
                <option>Selecione</option>
                {corretoraArr &&
                  corretoraArr.map((each: any) => (
                    <option key={each.corCdId} value={each.corCdId}>
                      {each.corTxNome}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">viagens previstas entre:</Form.Label>
            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatorio ? 'requiredField' : ''}>
                  Data Início
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                    if (e.target.value !== '') {
                      setDataInicioZ(toZoned(e.target.value));
                    }
                  }}
                  onBlur={() => {
                    if (dataFim && new Date(dataFim) < new Date(dataInicio)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  value={dataInicio}
                  type="date"
                  id="date"
                  name="date"
                  placeholder=""
                  required={periodoObrigatorio}
                />
                <Form.Control.Feedback type="invalid">
                  Campo Data Início não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatorio ? 'requiredField' : ''}>
                  Data Fim
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataFim(e.target.value);
                    if (e.target.value !== '') {
                      setDataFimZ(toZoned(e.target.value, false));
                    }
                  }}
                  onBlur={() => {
                    if (dataInicio && new Date(dataFim) < new Date(dataInicio)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  style={{ borderColor: dataFimError ? '#ff0000' : '#ced4da' }}
                  value={dataFim}
                  type="date"
                  id="dataFim"
                  name="dataFim"
                  placeholder=""
                  required={!idSm}
                />
                {dataFimError && (
                  <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                    {dataFimError}
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle mb-3">viagens lançadas entre:</Form.Label>
            <Form.Row className="row-container-modal">
              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatorio ? 'requiredField' : ''}>
                  Data Início
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataInicioLancamento(e.target.value);
                    if (e.target.value !== '') {
                      setDataInicioLancamentoZ(toZoned(e.target.value));
                    }
                  }}
                  onBlur={() => {
                    if (dataFimLancamento && new Date(dataFim) < new Date(dataInicioLancamento)) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  value={dataInicioLancamento}
                  type="date"
                  id="date"
                  name="date"
                  placeholder=""
                  required={periodoObrigatorio}
                />
                <Form.Control.Feedback type="invalid">
                  Campo Data Início não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label className={periodoObrigatorio ? 'requiredField' : ''}>
                  Data Fim
                </Form.Label>
                <Form.Control
                  onChange={(e) => {
                    e.target.value.length > 10 ? null : setDataFimLancamento(e.target.value);
                    if (e.target.value !== '') {
                      setDataFimLancamentoZ(toZoned(e.target.value, false));
                    }
                  }}
                  onBlur={() => {
                    if (
                      dataInicioLancamento &&
                      new Date(dataFimLancamento) < new Date(dataInicioLancamento)
                    ) {
                      setDataFimError('Data final não pode ser menor que a data inicial.');
                    } else {
                      setDataFimError('');
                    }
                  }}
                  style={{ borderColor: dataFimError ? '#ff0000' : '#ced4da' }}
                  value={dataFimLancamento}
                  type="date"
                  id="dataFim"
                  name="dataFim"
                  placeholder=""
                  required={!idSm}
                />
                {dataFimError && (
                  <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                    {dataFimError}
                  </p>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Veículo</Form.Label>
            <Form.Group>
              <Form.Label>Placa Nº</Form.Label>
              <Form.Control
                value={placa.toUpperCase()}
                id="rota"
                name="rota"
                placeholder=""
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleValidaPlaca(e);
                }}
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Viagem</Form.Label>
            <Form.Group>
              <Form.Label>Nº SM</Form.Label>
              <Form.Control
                value={idSm}
                type="text"
                maxLength={10}
                id="numeroSM"
                name="numeroSM"
                placeholder=""
                onChange={(e) => {
                  setIdSm(soNumeros(e.target.value));
                }}
              />
            </Form.Group>

            <Form.Row className="row-container-modal mb-3">
              <Form.Group className="row-modal">
                <Form.Label>Viagens com Escolta</Form.Label>
                <Form.Control
                  id="viagemEscolta"
                  name="viagemEscolta"
                  placeholder=""
                  onChange={(e) => {
                    setEscolta(e.target.value);
                  }}
                  as="select"
                >
                  <option value="">Selecione</option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="row-modal">
                <Form.Label>Viagens com Isca</Form.Label>
                <Form.Control
                  name="viagemIsca"
                  placeholder=""
                  onChange={(e) => {
                    setViagemIsca(e.target.value);
                  }}
                  as="select"
                >
                  <option value="">Selecione</option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Valor de Carga</Form.Label>
            <Form.Row className="row-container-modal">
              <Input
                onChange={(e) => {
                  if (
                    valorCargaMinimo &&
                    valorCargaMaximo &&
                    parseInt(e.target.value, 10) > valorCargaMaximo
                  ) {
                    setValorCargaMaxError(
                      'O valor da carga máxima não pode ser menor que o valor da carga mínima'
                    );
                  } else {
                    setValorCargaMaxError('');
                  }
                  setValorCargaMinimo(soNumeros(e.target.value));
                }}
                value={valorCargaMinimo}
                type="text"
                maxLength={12}
                id="valorCargaMinimo"
                name="valorCargaMinimo"
                textInputTitle="Valor de carga mínimo"
              />

              <Input
                onChange={(e) => {
                  if (valorCargaMinimo && parseInt(e.target.value, 10) < valorCargaMinimo) {
                    setValorCargaMaxError(
                      'O valor da carga máxima não pode ser menor que o valor da carga mínima'
                    );
                  } else {
                    setValorCargaMaxError('');
                  }
                  setValorCargaMaximo(soNumeros(e.target.value));
                }}
                value={valorCargaMaximo}
                type="text"
                maxLength={12}
                id="valorCargaMaximo"
                name="valorCargaMaximo"
                textInputTitle="Valor de carga máximo"
                error={valorCargaMaxError}
              />
            </Form.Row>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Status Viagem</Form.Label>
            <Form.Group>
              <Form.Label>Status Viagem</Form.Label>

              {STATUS.map((statusName, index) => (
                <Form.Check
                  key={index}
                  type="checkbox"
                  label={statusName}
                  className="ml-4 mt-2"
                  checked={status.includes(statusName)}
                  onChange={(e) => handleCheckboxChange(e, statusName)}
                />
              ))}
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Grupo de Usuários</Form.Label>
            <Form.Group>
              <Form.Label>Grupo de Usuários</Form.Label>
              <Form.Control
                onChange={(e) => setIdUsuario(Number(e.target.value))}
                value={idUsuario}
                as="select"
                id="usuId"
                name="usuId"
                placeholder=""
              >
                <option>Selecione</option>
                {usuarios.map((usuario, index) => (
                  <option key={index} value={usuario.usuCdId}>
                    {usuario.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Observações</Form.Label>
            <Form.Group>
              <Form.Label>Que contenha em observações</Form.Label>
              <Form.Control
                onChange={(e) => setObservacao(e.target.value)}
                value={observacao}
                type="text"
                maxLength={256}
                id="observacao"
                name="observacao"
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Busca por Romaneio</Form.Label>
            <Form.Group>
              <Form.Label>Que contenha em Romaneio</Form.Label>
              <Form.Control
                onChange={(e) => setRomaneio(e.target.value)}
                value={romaneio}
                type="text"
                maxLength={256}
                id="romaneio"
                name="romaneio"
              />
            </Form.Group>

            <Form.Label className="SMLancadasTitle mb-3">Faturamento Veículos</Form.Label>
            <Form.Check
              type="checkbox"
              label="Não Informado"
              className="ml-4 mt-2"
              checked={faturamentoNaoInformado}
              onChange={(e: any) => setFaturamentoNaoInformado(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Mensalista"
              className="ml-4 mt-2"
              checked={faturamentoMensalista}
              onChange={(e: any) => setFaturamentoMensalista(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Viagem Avulsa"
              className="ml-4 mt-2"
              checked={faturamentoViagemAvulsa}
              onChange={(e: any) => setFaturamentoViagemAvulsa(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Não Rastreado"
              className="ml-4 mt-2"
              checked={faturamentoNaoRastreado}
              onChange={(e: any) => setFaturamentoNaoRastreado(e.target.checked)}
            />
            <Form.Check
              type="checkbox"
              label="Desabilitado"
              className="ml-4 mt-2"
              checked={faturamentoDesabilitado}
              onChange={(e: any) => setFaturamentoDesabilitado(e.target.checked)}
            />
          </>
        ) : (
          <></>
        )}
        <ButtonsForm />
      </Form>
    </Tela>
  );
};

export default ConsultarSMLancadas;
