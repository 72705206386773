/* eslint-disable */

import { ChangeEvent, useEffect, useState, FC } from 'react';
import { Col, Form, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { EmbarcadorType } from './VincularTrasportadorGrid';
import { maskCep, maskCpnj, maskTel, validaNumero } from '../../util/Validacoes/Validacoes';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import instanceMaquina from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { ModelVinculoTransportador } from './VincularTrasportadorAdd';

type ModelValidationType = {
  isCnpjInvalid: boolean;
  isTelefoneInvalid: boolean;
  isTelefoneDoisInvalid: boolean;
  isTelefoneTresInvalid: boolean;
  isFormInvalid: boolean;
};

type EditProps = {
  showEdit: boolean;
  setShowEdit: (bool: boolean) => void;
  setShowView: (bool: boolean) => void;
  id: any;
  canDelete?: boolean;
  type: 'view' | 'edit';
  canEdit?: boolean;
};

const VincularTrasportadorEdit: FC<EditProps> = ({
  id,
  showEdit,
  type,
  setShowEdit,
  setShowView,
  canDelete,
  canEdit,
}) => {
  const { addToast } = useToast();
  const history = useHistory();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);

  const [model, setModel] = useState<ModelVinculoTransportador>({
    crt_tx_razao_social_trans: '',
    crt_tx_cnpj: '',
    crt_bl_gestao_patio_trans: false,
    cli_tx_codigo_legado: 0,
  });

  const [modelValidation, setModelValidation] = useState<ModelValidationType>({
    isCnpjInvalid: false,
    isTelefoneInvalid: false,
    isTelefoneDoisInvalid: false,
    isTelefoneTresInvalid: false,
    isFormInvalid: false,
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    async function loadData() {
      const response = await axios.get(`/get-vinculo-transportador-by-id/${id}`);
      setModel(response.data);
      if (type === 'view') {
        setDisabledInput(true);
      }
    }
    loadData();
  }, [id]); // GET Embarcador ListarPorId

  function updateModel(e: ChangeEvent<HTMLInputElement>, validation?: Function) {
    setModel((prevState: any) => {
      if (validation) {
        return {
          ...prevState,
          [e.target.name]: validation(e.target.value),
        };
      }
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handleCnpjValidation(cnpj: string) {
    if (cnpj.length == 0 || cnpj.length == 18) {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: false,
      });
    } else {
      setModelValidation({
        ...modelValidation,
        isCnpjInvalid: true,
      });
    }
  }

  async function handleDelete(id: number) {
    try {
      const response = await axios.delete(`/delete-vinculo-transportador/${id}`);

      addToast({
        title: 'Sucesso!',
        description: 'Vínculo Transportador excluído com sucesso.',
        type: 'success',
      });
      history.go(0);
    } catch (alert) {
      addToast({
        title: 'Erro!',
        description: 'Erro ao desativar o cadastro',
        type: 'error',
      });
    }
    setIsDeleteModalOpen(false);
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isAllowedByRole(['user'])) {
      if (
        !model.crt_tx_cnpj ||
        !model.crt_tx_razao_social_trans ||
        !model.cli_tx_codigo_legado ||
        modelValidation.isCnpjInvalid
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setModelValidation({
          ...modelValidation,
          isFormInvalid: true,
        });
        e.stopPropagation();
        return;
      }
    } else if (
      !model.crt_tx_cnpj ||
      !model.crt_tx_razao_social_trans ||
      modelValidation.isCnpjInvalid
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setModelValidation({
        ...modelValidation,
        isFormInvalid: true,
      });
      e.stopPropagation();
      return;
    }

    if (
      modelValidation.isTelefoneInvalid ||
      modelValidation.isTelefoneDoisInvalid ||
      modelValidation.isTelefoneTresInvalid
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos de Telefone',
        type: 'error',
      });
      setModelValidation({
        ...modelValidation,
        isFormInvalid: true,
      });
      e.stopPropagation();
      return;
    }

    const data = {
      ...model,
    };

    instanceMaquina
      .post(`/update-vinculo-transportador/${id}`, data)
      .then(() => {
        setIsDialogVisibleCadEfetuado(true);
        history.go(0);
      })
      .catch(() => {
        addToast({
          title: 'Erro!',
          description: 'Erro ao editar!',
          type: 'error',
        });
      });
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Vínculo de transportador editado com sucesso!"
        footer={
          <Button
            onClick={() => {
              setIsDialogVisibleCadEfetuado(false);
              setShowEdit(false);
            }}
          >
            OK
          </Button>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.goBack()}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Vinculo Transportador</h2>
          {isAllowedByRole(['user']) && (
            <h6 className="subtitulo">{'Cadastro > Vinculo Transportador'}</h6>
          )}
          {isAllowedByRole(['admin']) && (
            <h6 className="subtitulo">
              {'Cadastro > Gerenciamento de Clientes > Vinculo Transportador'}
            </h6>
          )}
          {type === 'edit' && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                marginLeft: '50%',
              }}
              onClick={() => {
                if (canDelete) {
                  setIsDeleteModalOpen(true);
                } else {
                  history.push('/acessonegado');
                }
              }}
              type="button"
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>

        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>

        <Form
          className="form-responsive"
          onSubmit={onSubmit}
          noValidate
          validated={modelValidation.isFormInvalid}
        >
          <Form.Check
            disabled={disabledInput}
            checked={model.crt_bl_gestao_patio_trans}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setModel({
                ...model,
                crt_bl_gestao_patio_trans: e.target.checked,
              });
            }}
            name="crt_bl_gestao_patio_trans"
            type="switch"
            id="custom-switch1"
            label="Pátio"
            style={{ marginBottom: '1rem' }}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCnpj">
              <Form.Label className="require">CNPJ Nº</Form.Label>
              <Form.Control
                type="text"
                name="cnpj"
                placeholder=""
                maxLength={18}
                minLength={18}
                required
                disabled={disabledInput}
                value={model.crt_tx_cnpj}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setModel({
                    ...model,
                    crt_tx_cnpj: e.target.value,
                  });
                }}
                onBlur={() => handleCnpjValidation(model.crt_tx_cnpj)}
                isInvalid={modelValidation.isCnpjInvalid}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRazaoSocial">
              <Form.Label className="require">Razão Social</Form.Label>
              <Form.Control
                type="text"
                name="razaoSocial"
                placeholder=""
                maxLength={256}
                required
                disabled={disabledInput}
                value={model.crt_tx_razao_social_trans}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setModel({
                    ...model,
                    crt_tx_razao_social_trans: e.target.value,
                  });
                }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group
            style={{ paddingRight: '0', paddingLeft: '0' }}
            md={3}
            as={Col}
            controlId="formGridcodReferencia"
          >
            <Form.Label className="require">Cód Legado</Form.Label>
            <Form.Control
              type="text"
              name="codReferencia"
              placeholder=""
              maxLength={256}
              required
              disabled={disabledInput}
              value={model.cli_tx_codigo_legado}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setModel({
                  ...model,
                  cli_tx_codigo_legado: Number(e.target.value),
                });
              }}
            />
          </Form.Group>

          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col} md={2}>
              <Button
                className="btn-cancelar"
                onClick={() => {
                  type == 'edit' ? setShowEdit(false) : setShowView(false);
                }}
              >
                Cancelar
              </Button>
            </Form.Group>
            {type === 'edit' && (
              <Form.Group as={Col} md={2}>
                {canEdit ? (
                  <Button className="btn-enviar" type="submit">
                    Confirmar
                  </Button>
                ) : (
                  <Button
                    className="btn-enviar"
                    type="button"
                    onClick={() => history.push('/acessonegado')}
                  >
                    Confirmar
                  </Button>
                )}
              </Form.Group>
            )}
          </Form.Row>
        </Form>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={isDeleteModalOpen}
        onHide={() => setIsDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro"{`${model.crt_tx_razao_social_trans}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => {
              if (canDelete) {
                handleDelete(id);
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default VincularTrasportadorEdit;
