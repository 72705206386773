/* eslint-disable */

import React, { FC, useState, useEffect } from 'react';
import { Form, Tabs, Tab, Col, Modal, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import axiosRequest from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import axios from '../../../config/axiosMaquina';
import {
  maskCep,
  maskCpnj,
  maskTel,
  unmaskTel,
  isDateLessThanBaseDate,
  validaNumero,
  validaEmail,
  validaNumeroCinco,
  validaEmailsEmLista,
} from '../../../util/Validacoes/Validacoes';
import { useToast } from '../../../hooks/Toast';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { MunicipiosType } from '../../../api/model/MunicipiosType';
import TabParticularidades, { ClienteParticularidadesDTO } from '../Tabs/TabParticularidades';
import TabServicosContratados, {
  ClienteServicosContratadosDTO,
} from '../Tabs/TabServicosContratados';
import TabControleTemperatura from '../Tabs/TabControleTemperatura';
import { CorretorasType, SeguradorasType } from '../../../api/model/UsuariosType';
import './styles.css';
import ModalAddSeguradora from '../../CadastroSeguradora/ModalAddSeguradora';
import ModalAddCorretora from '../../CadastroCorretora/ModalAddCorretora';
import TabControleVelocidade from '../Tabs/TabControleVelocidade';
import { getCookieSessionData } from '../../../services/cookieService';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { getFullDateNow } from '../../../util/format';
import instanceMaquina from '../../../config/axiosMaquina';
import { GerenteType } from '../../../Types/Gerente';
import { nowToZoned, toZoned } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import TabGerarSenha from '../Tabs/TabGerarSenha';
import { log } from 'console';

type ClienteDTO = {
  cnpj: string;
  codigoLegado: string;
  codigoBimmer: string;
  corretoraId?: number | null;
  dataCadastro: string;
  dataValidadeApolice: string;
  emailPrincipal: string;
  centroCusto: string;
  enderecoBairro: string;
  enderecoCep: string;
  enderecoCidade: string;
  enderecoComplemento: string;
  enderecoLogradouro: string;
  enderecoNumero: string;
  enderecoUf?: string | null;
  envioChecklistVencido: boolean;
  envioCnhVencida: boolean;
  envioFinalizacaoSm: boolean;
  envioInclusaoSm: boolean;
  envioNaoConformidade: boolean;
  envioPesquisaVencida: boolean;
  envioRetornoPesquisa: boolean;
  flagSituacaoCnpj: boolean;
  idGerente: number;
  logo: string;
  nomeFantasia: string;
  observacoes: string;
  razaoSocial: string;
  seguradoraId?: number | null;
  statusAtivo: boolean;
  telefonePrincipal: string;
  telefoneSecundario: string;
  vencimentoFatura?: number;
  envioInclusaoSat: boolean;
  envioInclusaoChecklist: boolean;
};

const ClienteCadastro: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();
  const { doesHavePermission: canInsertCorretora } = useRolePermission();
  const { doesHavePermission: canInsertSeguradora } = useRolePermission();
  const [loadingPermission, setLoadingPermission] = useState(false);

  const [canGeneratePassword, setCanGeneratePassword] = useState(false);

  const history = useHistory();
  const { activeKey }: any = useParams();
  const { id }: any = useParams();
  const { addToast } = useToast();
  const { usuarioId } = getCookieSessionData().usuarioVO;

  useEffect(() => {
    setLoadingPermission(true);

    axiosRequest
      .get(
        `${process.env.REACT_APP_API_URL}/profile-menu/list-functionalities/${usuarioId}?idMenuGrupo=3`
      )
      .then((res) => {
        const idMenuGrupo = res.data.functionalities.filter(
          (func: any) => func.idMenuGrupo === 3
        )[0];

        setCanGeneratePassword(idMenuGrupo.gerarSenha);
      })
      .catch(() => setCanGeneratePassword(false))
      .finally(() => setLoadingPermission(false));
  }, []);

  const [cliente, setCliente] = useState({} as ClienteDTO);
  const [wasCadastred, setWasCadastred] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [logo, setLogo] = useState('');
  const [newLogo, setNewLogo] = useState(false);
  const [link, setLink] = useState<any>();
  const [error, setError] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [isDialogVisibleCadOk, setIsDialogVisibleCadOk] = useState(false);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [flagEnvioInclusaoSm, setFlagEnvioInclusaoSm] = useState(false);
  const [flagEnvioFinalizacaoSm, setFlagEnvioFinalizacaoSm] = useState(false);
  const [flagEnvioRetornoPesquisa, setFlagEnvioRetornoPesquisa] = useState(false);
  const [flagEnvioNaoConformidade, setFlagEnvioNaoConformidade] = useState(false);
  const [flagEnvioChecklistVencido, setFlagEnvioChecklistVencido] = useState(false);
  const [flagEnvioCnhVencida, setFlagEnvioCnhVencida] = useState(false);
  const [flagEnvioPesquisaVencida, setFlagEnvioPesquisaVencida] = useState(false);
  const [flagEnvioInclusaoSat, setFlagEnvioInclusaoSat] = useState(false);
  const [flagEnvioInclusaoChecklist, setFlagEnvioInclusaoChecklist] = useState(false);
  const [erroCnpj, setErroCnpj] = useState(false);
  const [erroCodigoLegado, setErroCodigoLegado] = useState(false);
  const [erroCodigoBimmer, setErroCodigoBimmer] = useState(false);

  const [flagAtivo, setFlagAtivo] = useState(true);
  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaReceitaUf, setViaReceitaUf] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [corretoras, setCorretoras] = useState<CorretorasType[]>([]);
  const [seguradoras, setSeguradoras] = useState<SeguradorasType[]>([]);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isDialogVisibleRecuperaDados, setIsDialogVisibleRecuperaDados] = useState(false);
  const [dadosRecuperados, setDadosRecuperados] = useState(false);

  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const [cadastroRealizadoPart, setCadastroRealizadoPart] = useState(false);
  const [cadastroRealizadoServ, setCadastroRealizadoServ] = useState(false);
  const [cepMudou, setCepMudou] = useState(false);
  const [idParticularidades, setIdParticularidades] = useState();
  const [idServicos, setIdServicos] = useState();
  const [idClienteByPost, setIdClienteByPost] = useState(0);
  const [temperatura, setTemperatura] = useState<number>(0);
  const [velocidade, setVelocidade] = useState<number>(0);
  const [servicosContratados, setServicosContratados] = useState<ClienteServicosContratadosDTO>({
    cadastroConsulta: 0,
    clienteId: 0,
    cronogramaViagem: 0,
    mapaRastreamento: 0,
    monitoramentoVeiculo: 0,
    rotaPontos: 0,
    smsViagem: 0,
    wsPosicao: 0,
    controleTemperatura: 0,
    controleVelocidade: 0,
    patio: 0,
    painelLsc: 0,
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [showModalSeguradora, setShowModalSeguradora] = useState(false);
  const [showModalCorretora, setShowModalCorretora] = useState(false);
  const [erroDataApolice, setErroDataApolice] = useState(false);
  const [listaGerentes, setListaGerentes] = useState<GerenteType[]>([]);

  const [model, setModel] = useState<ClienteDTO>({
    cnpj: '',
    codigoLegado: '',
    codigoBimmer: '',
    corretoraId: null,
    dataCadastro: getFullDateNow(),
    dataValidadeApolice: '',
    emailPrincipal: '',
    centroCusto: '',
    enderecoBairro: '',
    enderecoCep: '',
    enderecoCidade: '',
    enderecoComplemento: '',
    enderecoLogradouro: '',
    enderecoNumero: '',
    enderecoUf: '',
    envioChecklistVencido: flagEnvioChecklistVencido,
    envioCnhVencida: flagEnvioCnhVencida,
    envioFinalizacaoSm: flagEnvioFinalizacaoSm,
    envioInclusaoSm: flagEnvioInclusaoSm,
    envioNaoConformidade: flagEnvioNaoConformidade,
    envioPesquisaVencida: flagEnvioPesquisaVencida,
    envioRetornoPesquisa: flagEnvioRetornoPesquisa,
    flagSituacaoCnpj: false,
    idGerente: 0,
    logo: '',
    nomeFantasia: '',
    observacoes: '',
    razaoSocial: '',
    seguradoraId: null,
    statusAtivo: flagAtivo,
    telefonePrincipal: '',
    telefoneSecundario: '',
    vencimentoFatura: 0,
    envioInclusaoSat: flagEnvioInclusaoSat,
    envioInclusaoChecklist: flagEnvioInclusaoChecklist,
  });

  const dataParticularidades: ClienteParticularidadesDTO = {
    cliCdId: null,
    cppBlBloqueioSmSat: 2,
    cppBlClienteEmbarcador: false,
    cppBlPermitirPesquisaAutomatica: false,
    cppBlCriticarAnaliseNaoMotoristaAgregado: true,
    cppBlUtilizaCentroCusto: true,
    cppBlCriticarAnaliseNaoMotoristaAutonomo: true,
    cppBlCriticarAnaliseNaoMotoristaFuncionario: true,
    cppBlCriticarAnaliseSmAgregado: true,
    cppBlCriticarAnaliseSmCondutorAgregado: true,
    cppBlCriticarAnaliseSmCondutorAutonomo: true,
    cppBlCriticarAnaliseSmCondutorFuncionario: true,
    cppBlCriticarAnaliseSmFrotaPropria: true,
    cppBlCriticarAnaliseSmTerceiro: true,
    cppBlExecutarRotaInternacional: false,
    cppBlHabilitarPesquisaAnttRntrc: false,
    cppBlIncluirCdCadPonto: false,
    cppBlIncluirRegionalCadPonto: false,
    cppBlPermitirEdicao: false,
    cppBlSolicitarFrota: false,
    cppBlSolicitarNumeroIdentificador: false,
    cppBlSolicitarNumeroRemessa: false,
    cppBlSolicitarOperacao: false,
    cppBlUtilizaApolicePropria: false,
    cppBlUtilizarRankingAjudanteFuncionario: false,
    cppBlUtilizarRankingCondutor: false,
    cppBlUtilizarRankingVeiculo: false,
    cppNuValidadeAnaliseCondutorAgregado: 180,
    cppNuValidadeAnaliseCondutorAutonomo: 0,
    cppNuValidadeAnaliseCondutorFuncionario: 180,
    cppNuValidadeAnaliseNaoMotoristaAgregado: 180,
    cppNuValidadeAnaliseNaoMotoristaAutonomo: 0,
    cppNuValidadeAnaliseNaoMotoristaFuncionario: 180,
    cppNuValidadeAnaliseVeiculoAgregado: 180,
    cppNuValidadeAnaliseVeiculoFrotaPropria: 180,
    cppNuValidadeAnaliseVeiculoTerceiro: 0,
    cppNuValidarChecklistPendente: 2,
    cppNuVeiculoAgregado: 0,
    cppNuVeiculoFrotaEspecial: 0,
    cppNuVeiculoFrotaPropria: 0,
    cppNuVeiculoTerceiro: 0,
    cppBlDadosEnderecoObrigatorio: false,
    cppBlPermitirPesquisaOutraEmpresa: false,
    cppBlHabilitaPesquisasConsultasVigentes: true,
    cppBlHabilitarPesquisaIntegrada: false,
    cppBlUtilizarRankingBau: false,
    cppBlUtilizarRankingCarreta: false,
  };

  const dataServicos: ClienteServicosContratadosDTO = {
    cadastroConsulta: 0,
    clienteId: null,
    cronogramaViagem: 0,
    mapaRastreamento: 0,
    monitoramentoVeiculo: 0,
    rotaPontos: 0,
    smsViagem: 0,
    wsPosicao: 0,
    controleTemperatura: 0,
    controleVelocidade: 0,
    patio: 0,
    painelLsc: 0,
  };

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.USUARIO.CORRETORA] }], 'inserir');
    rolePermission([{ ADMIN: [ADMIN.USUARIO.SEGURADORA] }], 'inserir');
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.CLIENTE] }], 'editar');
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.CLIENTE] }], 'excluir');
  }, [ADMIN, rolePermission]);

  const getServicosContratadosCliente = async () => {
    const response = await instanceMaquina.get(
      `/cliente/getServicosContratadosCliente?cliente=${id}`
    );
    setVelocidade(response.data.controleVelocidade);
    setTemperatura(response.data.controleTemperatura);
  };
  useEffect(() => {
    if (!id) return;
    getServicosContratadosCliente();
  }, [id]);

  useEffect(() => {
    const loadDadosCliente = async () => {
      if (!id) return;
      try {
        const response = await axios.get(`/cliente/${id}`);
        setModel(response.data);
        setFlagEnvioInclusaoSm(response.data.envioInclusaoSm);
        setFlagEnvioFinalizacaoSm(response.data.envioFinalizacaoSm);
        setFlagEnvioRetornoPesquisa(response.data.envioRetornoPesquisa);
        setFlagEnvioNaoConformidade(response.data.envioNaoConformidade);
        setFlagEnvioChecklistVencido(response.data.envioChecklistVencido);
        setFlagEnvioCnhVencida(response.data.envioCnhVencida);
        setFlagEnvioPesquisaVencida(response.data.envioPesquisaVencida);
        setFlagEnvioInclusaoSat(response.data.envioInclusaoSat);
        setLogo(response.data.logo);
        setFlagEnvioInclusaoChecklist(response.data.envioInclusaoChecklist);
        setFlagAtivo(response.data.statusAtivo);

        setIdParticularidades(response.data.idClienteParticularidades);
        setIdServicos(response.data.idClienteServicoContrato);
        console.log(model);
      } catch (err: any) {}
    };
    loadDadosCliente();
  }, []);

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      try {
        const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/1`);
        setUfs(response.data);
        // console.log(response.data);
      } catch (err: any) {
        // console.log(err.response);
      }
    };
    loadEstadosPorPais();
  }, []);

  function filterAtivo(each: any) {
    if (each.corBlAtivo) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const loadCorretoras = async () => {
      try {
        const response = await axios.get(`/corretora`);
        setCorretoras(response.data.filter(filterAtivo));
      } catch (err: any) {
        // console.log(err.response);
      }
    };
    loadCorretoras();
  }, [showModalCorretora]);

  function filterAtivo2(each: any) {
    if (each.segBlAtivo) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    const loadSeguradoras = async () => {
      try {
        const response = await axios.get(`/seguradora`);
        setSeguradoras(response.data.filter(filterAtivo2));
      } catch (err: any) {}
    };
    loadSeguradoras();
  }, [showModalSeguradora]);

  const loadMunicipiosPorEstado = async (estado: any) => {
    console.log(estado);
    setViaCepCidade('');
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
      // console.log(response.data);
    } catch (err: any) {
      // console.log(err.response);
    }
  };

  useEffect(() => {
    console.log(dadosRecuperados);

    if (dadosRecuperados) return;
    const buscarDadosPeloCnpj = async () => {
      if (id) {
      } else if (model.cnpj.length < 18) {
        setModel({
          ...model,
          razaoSocial: '',
          nomeFantasia: '',
          enderecoBairro: '',
          enderecoCidade: '',
          enderecoNumero: '',
          enderecoComplemento: '',
          enderecoLogradouro: '',
          enderecoCep: '',
          enderecoUf: '',
        });
      } else {
        const numeroCnpj = model.cnpj.toString().replace(/\.|-|\//gm, '');
        setLoadingCnpj(false);
        try {
          setLoadingCnpj(true);
          const response = await axios.get(`/cliente/receitaws-consultar-cnpj/${numeroCnpj}`);
          const results = response.data;

          const ufFiltered = ufs
            .filter((fil) => fil.sigla === response.data.uf)
            .find((each) => each.id);

          const codigoStringUf = ufFiltered?.id?.toString();
          console.log(codigoStringUf);

          setModel({
            ...model,
            razaoSocial: response.data.nome,
            nomeFantasia: response.data.fantasia,
            enderecoBairro: response.data.bairro,
            enderecoCidade: response.data.municipio,
            enderecoNumero: response.data.numero,
            enderecoComplemento: response.data.complemento,
            enderecoLogradouro: response.data.logradouro,
            enderecoCep: response.data.cep,
            enderecoUf: codigoStringUf,
          });
          setViaCepCidade(response.data.municipio);
          setViaReceitaUf(response.data.uf);
          console.log(results);
        } catch (err: any) {
          setLoadingCnpj(false);
          setModel({
            ...model,
            razaoSocial: '',
            nomeFantasia: '',
            enderecoBairro: '',
            enderecoCidade: '',
            enderecoNumero: '',
            enderecoComplemento: '',
            enderecoLogradouro: '',
            enderecoCep: '',
            enderecoUf: '',
          });
          console.log(err.response);
        }
      }
    };

    buscarDadosPeloCnpj();
  }, [model.cnpj]);

  useEffect(() => {
    // console.log(model.cnpj);
    if (model.cnpj.length === 18) {
      const validaCnpjExistente = async () => {
        try {
          const response = await axios.get(`/cliente/verificar-cnpj-cadastro?cnpj=${model.cnpj}`);
          if (response.data === true) {
            setErroCnpj(true);
            addToast({
              title: 'Erro!',
              description: 'CNPJ já cadastrado',
              type: 'error',
            });
            return;
          }
          setErroCnpj(false);
          // setModel({
          //   ...model,
          // });
        } catch (err: any) {
          console.log(err);
        }
      };
      validaCnpjExistente();
    }
  }, [model.cnpj]); // Valida CNPJ Existente

  const validaCodigoLegadoExistente = async (value: string) => {
    try {
      const valueToInt = parseInt(value);

      const response = await axios.get(`/cliente/verificar-codigo-legado?cdLegado=${valueToInt}`);
      if (response.data === true) {
        setErroCodigoLegado(true);
        addToast({
          title: 'Erro!',
          description: 'Código Legado já cadastrado',
          type: 'error',
        });
        setIsDialogVisibleRecuperaDados(true);
      } else {
        setErroCodigoLegado(false);
        setModel({
          ...model,
        });
      }
    } catch (err: any) {
      // console.log(err.response);
    }
  }; // Valida Codigo Legado Existente

  useEffect(() => {
    const buscarCep = () => {
      // console.log(model.enderecoCep.length);
      if (cepMudou) {
        if (model.enderecoCep?.length < 9) {
          setModel({
            ...model,
            enderecoCidade: '',
            enderecoLogradouro: '',
            enderecoBairro: '',
            enderecoUf: '',
          });
          setError(true);
          return;
        }

        setError(false);
        fetch(`https://viacep.com.br/ws/${model.enderecoCep}/json`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              setError(true);
              return;
            }

            setViaCepUf(data.uf);
            setViaCepCidade(data.localidade);
            const ufId = ufs
              .filter((uf) => uf.sigla === data.uf)
              .map((filteredUfId) => filteredUfId.id);
            // setIdCodigoIbge(ufId.toString());
            // console.log(ufId);
            // console.log(idCodigoIbge);
            // setCidade(data.localidade);
            const codIbgeFloat = data.ibge / 100000;
            const codIbgeFormatado = Math.trunc(codIbgeFloat);
            setViaCepIbgeCod(codIbgeFormatado);
            const listaMunPorEstado = municipios
              .filter((municipio) => municipio.idCodigoIbge === viaCepIbgeCod)
              .map((filteredMunicipio) => filteredMunicipio.nomeMunicipio);
            // console.log(listaMunPorEstado);
            // console.log(codIbgeFormatado);
            setModel({
              ...model,
              enderecoLogradouro: data.logradouro,
              enderecoBairro: data.bairro,
              enderecoCidade: data.localidade,
              enderecoUf: codIbgeFormatado.toString(),
            });
          })

          .catch((erro) => {
            // console.log('error', erro);
            setError(true);
          });
      } else {
      }
    };
    buscarCep();
  }, [model.enderecoCep]);

  const handleRegisterDados = async (e: any) => {
    e.preventDefault();
    console.log(model);
    console.log(erroCodigoBimmer);

    if (
      !model.codigoLegado ||
      !model.cnpj ||
      !model.razaoSocial ||
      !model.nomeFantasia ||
      !model.enderecoCep ||
      !model.enderecoLogradouro ||
      model.enderecoNumero === null ||
      !model.enderecoBairro ||
      !model.enderecoUf ||
      !model.enderecoCidade ||
      !model.emailPrincipal ||
      !model.telefonePrincipal
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }

    if (!isValidEmail) {
      addToast({
        title: 'Erro!',
        description: 'Email inválido!',
        type: 'error',
      });
      return;
    }

    if (
      model.telefonePrincipal.substring(0, 2).includes('00') ||
      model.telefoneSecundario.substring(0, 2).includes('00')
    ) {
      addToast({
        title: 'DDD Inválido!',
        description: 'Não é possível cadastrar um número de telefone com DDD "00".',
        type: 'error',
      });
      return;
    }

    if (erroCodigoBimmer) {
      addToast({
        title: 'Bimmer Inválido!',
        description: 'Não é possível cadastrar um número bimmer inválido',
        type: 'error',
      });
      return;
    }

    const data = {
      ...model,
      emailPrincipal: model.emailPrincipal?.replaceAll('\n', '') || '', // Safely handle null/undefined
      centroCusto: model.centroCusto?.replaceAll('\n', '') || '', // Safely handle null/undefined
      cnpj: model.cnpj?.replaceAll('.', '').replace('/', '').replace('-', '') || '',
      enderecoCep: model.enderecoCep?.replace('-', '') || '',
      telefonePrincipal: model.telefonePrincipal || '',
      telefoneSecundario: model.telefoneSecundario || '',
      envioChecklistVencido: flagEnvioChecklistVencido,
      envioCnhVencida: flagEnvioCnhVencida,
      envioFinalizacaoSm: flagEnvioFinalizacaoSm,
      envioInclusaoSm: flagEnvioInclusaoSm,
      envioNaoConformidade: flagEnvioNaoConformidade,
      logo,
      envioPesquisaVencida: flagEnvioPesquisaVencida,
      envioRetornoPesquisa: flagEnvioRetornoPesquisa,
      envioInclusaoChecklist: flagEnvioInclusaoChecklist,
      envioInclusaoSat: flagEnvioInclusaoSat,
      statusAtivo: flagAtivo,
      dataCadastro: nowToZoned(),
      dataValidadeApolice: model.dataValidadeApolice,
      idGerente: model.idGerente === 0 ? null : model.idGerente,
    };

    try {
      setLoading(true);
      if (id || dadosRecuperados) {
        await axios.put(
          `/cliente/atualizar/${id || model.codigoLegado}?idUsuario=${usuarioId}`,
          data
        );
        console.log(data);
        setLoading(false);
        setIsDialogVisibleCadOk(true);
        setWasCadastred(true);
      } else {
        const response = await axios.post('/cliente', data);
        console.log(data);
        setIdClienteByPost(response.data.codigoLegado);
        setCadastroRealizado(true);
        setLoading(false);
        setIsDialogVisibleCadOk(true);
        setWasCadastred(true);

        const responseParticularidades = await axios.post('/cliente-particularidades/salvar', {
          ...dataParticularidades,
          cliCdId: response.data.codigoLegado,
        });
        setIdParticularidades(responseParticularidades.data.cppCdId);

        const responseServicos = await axios.post('/cliente-servico-contratado/salvar', {
          ...dataServicos,
          clienteId: response.data.codigoLegado,
        });
        setIdServicos(responseServicos.data.cscCdId);
      }
    } catch (err: any) {
      console.log(err.response);
      console.log(JSON.stringify(data));

      if (err.response.data.detail === 'Código já existente.') {
        addToast({
          title: 'Erro!',
          description: 'Código já cadastrado',
          type: 'error',
        });
        e.stopPropagation();
        return;
      }

      if (err.response.data.message === 'CNPJ já cadastro') {
        addToast({
          title: 'Erro!',
          description: 'CNPJ já cadastrado',
          type: 'error',
        });
        setErroCnpj(true);
        e.stopPropagation();
        return;
      }
      if (err.response.data.message === 'Codigo legado já cadastrado') {
        addToast({
          title: 'Erro!',
          description: 'Código Legado já cadastrado',
          type: 'error',
        });
        setIsDialogVisibleRecuperaDados(true);
        setErroCodigoLegado(true);
        e.stopPropagation();
        return;
      }
      if (err.response.data.message?.includes('email')) {
        setIsDialogVisibleCadEfetuado(true);
        const codigoFormat = parseInt(model.codigoLegado, 10);
        setIdClienteByPost(codigoFormat);
      }
      setLoading(false);
      // alert('Erro ao cadastrar ');
      setCadastroRealizado(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await axios.post(`/cliente/delete-logico/${id}`);
      setExclusaoOK(true);
      setTimeout(function () {
        history.goBack();
      }, 1500);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro('Não foi possível excluir o cliente ');
    }
  };

  const fetchGerentes = async () => {
    try {
      const response = await axios.get('/gerente/listarGerentes');
      setListaGerentes(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGerentes();
  }, []);

  // useEffect(() => {
  //   if (logo && newLogo === false) {
  //     axios.get(`/foto/downloadFile/${logo}`).then((response) => {
  //       setLink(response.request.responseURL);
  //     });
  //   }
  // }, [logo]);

  const goParticularidades = () => {
    setIsDialogVisibleCadEfetuado(false);
    setIsDialogVisibleCadOk(false);
    setIsDialogVisibleMsgEmail(false);
    if (!id) {
      history.push('/clientes/listar/1');
    }
  };

  async function handleRecuperarPorCodigo(cod: string) {
    const response = await axios.get(`/cliente/reutilizar-cliente`, {
      params: {
        codigo: cod,
      },
    });

    setDadosRecuperados(true);
    setModel(response.data);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o cliente "{`${model.razaoSocial}`}"?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Cliente "{`${model.razaoSocial}`}" excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body
            style={{ fontSize: 20, alignSelf: 'center' }}
          >{`${mensagemErro}${model.razaoSocial}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoOK && (
          <Modal.Footer>
            <Button
              className="p-button-secondary"
              style={{ width: 100, marginRight: 10 }}
              onClick={handleClose}
              label="Não"
            />
            <Button
              style={{ width: 100, marginRight: 10 }}
              className="p-button-warning"
              onClick={() => handleDelete(id)}
              label="Sim"
            />
          </Modal.Footer>
        )}
      </Modal>

      <>
        <div className="card">
          <div className="titulo-container">
            <h2 className="titulo">Clientes</h2>
            <h6 className="subtitulo">{'Cadastro > Clientes > Cadastro'}</h6>

            {id ? (
              <button
                className="btn-desativar"
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  position: 'absolute',
                  right: '2%',
                }}
                onClick={() => {
                  if (canDelete) {
                    setExclusaoErro(false);
                    setExclusaoOK(false);
                    handleShow();
                  } else {
                    history.push('/acessonegado');
                  }
                }}
              >
                <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
              </button>
            ) : null}
          </div>
          <Dialog
            header={id ? 'Alteração realizada com sucesso!' : 'Cliente foi cadastrado com sucesso!'}
            footer={
              <>
                <Button label="OK" onClick={goParticularidades} />
              </>
            }
            visible={isDialogVisibleCadOk}
            style={{ width: '50vw' }}
            modal
            onHide={goParticularidades}
          />
          <Dialog
            header="Cliente foi cadastrado com sucesso!"
            footer={
              <>
                <Button label="OK" onClick={goParticularidades} />
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={goParticularidades}
          />

          <Dialog
            header="Não foi possível enviar email de Cliente. Verifique o email cadastrado."
            footer={<Button onClick={goParticularidades}>OK</Button>}
            visible={isDialogVisibleMsgEmail}
            style={{ width: '50vw' }}
            modal
            onHide={goParticularidades}
          />

          {loadingPermission ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <>
              {!id ? (
                <>
                  <Tabs
                    // defaultActiveKey="dadosPrincipais"
                    activeKey={activeKey}
                    id="controlled-tab-example"
                    className="mt-4"
                  >
                    <Tab eventKey="dadosPrincipais" title="Dados Principais">
                      <Form noValidate validated={isFormInvalid} onSubmit={handleRegisterDados}>
                        <h1
                          className="info-obrigatorio"
                          style={{ marginTop: '-10px', marginBottom: '10px' }}
                        >
                          *Informações obrigatórias
                        </h1>
                        <ProfilePicture cadastro setFoto={setLogo} foto={logo} name={model.cnpj}>
                          <Form.Row>
                            <Form.Group as={Col} className="mt-5">
                              <Form.Label className="required">Código</Form.Label>

                              <Form.Control
                                value={model.codigoLegado}
                                onChange={(e) => {
                                  if (validaNumero(e.target.value) || e.target.value.length === 0) {
                                    setModel({
                                      ...model,
                                      codigoLegado: e.target.value,
                                    });
                                  }
                                }}
                                onBlur={() => validaCodigoLegadoExistente(model.codigoLegado)}
                                id="codigoLegado"
                                name="codigoLegado"
                                placeholder=""
                                required
                                maxLength={16}
                                isInvalid={erroCodigoLegado && !dadosRecuperados}
                                disabled={id}
                              />
                              {erroCodigoLegado && !dadosRecuperados && (
                                <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                  Código Legado já existe.
                                </p>
                              )}
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Código Bimmer</Form.Label>

                              <Form.Control
                                value={model.codigoBimmer}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Atualiza o modelo e define o erro com base na validade do valor
                                  setModel((prevModel) => {
                                    const isValid = validaNumeroCinco(value);

                                    if (isValid) {
                                      setErroCodigoBimmer(false);
                                    } else {
                                      setErroCodigoBimmer(true);
                                    }
                                    return {
                                      ...prevModel,
                                      codigoBimmer: value,
                                    };
                                  });
                                }}
                                onBlur={() => {
                                  if (!validaNumeroCinco(model.codigoBimmer)) {
                                    setErroCodigoBimmer(true);
                                  } else {
                                    setErroCodigoBimmer(false);
                                  }
                                }}
                                id="codigoBimmer"
                                name="codigoBimmer"
                                maxLength={5}
                                isInvalid={erroCodigoBimmer}
                                pattern="\d{5}"
                              />

                              {erroCodigoBimmer && model.codigoBimmer.length > 0 && (
                                <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                  Código Bimmer inválido, insira exatamente 5 números.
                                </p>
                              )}
                            </Form.Group>
                          </Form.Row>
                        </ProfilePicture>

                        {/* <Content>
                    <FileProvider done={wasCadastred} foto={logo} setFoto={setLogo}>
                      <Upload />
                      <FileList />
                    </FileProvider>
                  </Content> */}
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">CNPJ</Form.Label>

                            <Form.Control
                              value={id ? maskCpnj(model.cnpj) : model.cnpj}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  cnpj: maskCpnj(e.target.value),
                                });
                              }}
                              id="cnpj"
                              name="cnpj"
                              placeholder=""
                              required
                              maxLength={18}
                              style={{ width: '49.8%' }}
                              isInvalid={erroCnpj}
                            />

                            {erroCnpj && (
                              <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                CNPJ já existe.
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Razão Social</Form.Label>

                            <Form.Control
                              value={model.razaoSocial === null ? '' : model.razaoSocial}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  razaoSocial: e.target.value,
                                });
                              }}
                              id="razaoSocial"
                              name="razaoSocial"
                              placeholder=""
                              required
                              maxLength={256}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Nome Fantasia</Form.Label>

                            <Form.Control
                              value={model.nomeFantasia === null ? '' : model.nomeFantasia}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  nomeFantasia: e.target.value,
                                });
                              }}
                              id="nomeFantasia"
                              name="nomeFantasia"
                              placeholder=""
                              required
                              maxLength={64}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">CEP</Form.Label>

                            <Form.Control
                              value={
                                model.enderecoCep === null
                                  ? ''
                                  : id
                                  ? maskCep(model.enderecoCep)
                                  : model.enderecoCep
                              }
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoCep: maskCep(e.target.value),
                                });
                                setCepMudou(true);
                              }}
                              id="cep"
                              name="cep"
                              placeholder=""
                              required
                              maxLength={9}
                              minLength={9}
                              style={{ width: '49.8%' }}
                            />
                            {error && (
                              <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                CEP Inválido
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Logradouro</Form.Label>

                            <Form.Control
                              value={
                                model.enderecoLogradouro === null ? '' : model.enderecoLogradouro
                              }
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoLogradouro: e.target.value,
                                });
                              }}
                              id="logradouro"
                              name="logradouro"
                              required
                              style={{ width: '100%' }}
                              maxLength={64}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label className="required">Número</Form.Label>

                            <Form.Control
                              value={model.enderecoNumero === null ? '' : model.enderecoNumero}
                              onChange={(e) => {
                                if (validaNumero(e.target.value) || e.target.value.length === 0) {
                                  setModel({
                                    ...model,
                                    enderecoNumero: e.target.value,
                                  });
                                } else {
                                }
                              }}
                              id="numero"
                              name="numero"
                              placeholder=""
                              required
                              style={{ width: '100%' }}
                              maxLength={8}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Complemento</Form.Label>

                            <Form.Control
                              value={
                                model.enderecoComplemento === null ? '' : model.enderecoComplemento
                              }
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  enderecoComplemento: e.target.value,
                                });
                              }}
                              id="complemento"
                              name="complemento"
                              placeholder=""
                              maxLength={64}
                              style={{ width: '100%' }}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label className="required">Bairro</Form.Label>

                            <Form.Control
                              value={model.enderecoBairro === null ? '' : model.enderecoBairro}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  enderecoBairro: e.target.value,
                                });
                              }}
                              id="bairro"
                              name="bairro"
                              placeholder=""
                              maxLength={64}
                              required
                              style={{ width: '100%' }}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">UF</Form.Label>

                            <Form.Control
                              value={model.enderecoUf ? model.enderecoUf : ''}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoUf: e.target.value,
                                });
                                loadMunicipiosPorEstado(e.target.value);
                              }}
                              id="idCodigoIbge"
                              name="idCodigoIbge"
                              required
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {ufs.map((uf) => (
                                <option value={uf.id} key={uf.id}>
                                  {uf.sigla}
                                </option>
                              ))}
                              <option
                                value={model.enderecoUf ? model.enderecoUf : ''}
                                key={model.enderecoUf}
                              >
                                {viaCepUf}
                              </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label className="required">Município</Form.Label>

                            <Form.Control
                              value={model.enderecoCidade}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoCidade: e.target.value,
                                });
                              }}
                              id="cidade"
                              name="cidade"
                              placeholder=""
                              required
                              as="select"
                              disabled={!model.enderecoUf}
                              style={{ width: '100%' }}
                            >
                              {viaCepCidade ? (
                                <option key={model.enderecoCidade} value={model.enderecoCidade}>
                                  {viaCepCidade}
                                </option>
                              ) : (
                                municipios.map((municipio) => (
                                  <option
                                    key={municipio.nomeMunicipio}
                                    value={municipio.nomeMunicipio}
                                  >
                                    {municipio.nomeMunicipio}
                                  </option>
                                ))
                              )}

                              {id && (
                                <option value={model.enderecoCidade}>{model.enderecoCidade}</option>
                              )}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Email Principal</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={model.emailPrincipal || ''}
                              id="emailPrincipal"
                              name="emailPrincipal"
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  emailPrincipal: e.target.value,
                                });
                              }}
                              onBlur={() => {
                                if (validaEmailsEmLista(model.emailPrincipal)) {
                                  setIsValidEmail(true);
                                } else {
                                  setIsValidEmail(false);
                                }
                              }}
                              maxLength={1048}
                            />
                            {!isValidEmail && (
                              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                                Email inválido
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioInclusaoSm}
                              onChange={(e) => {
                                setFlagEnvioInclusaoSm(!flagEnvioInclusaoSm);
                              }}
                              type="checkbox"
                              id="flagEnvioInclusaoSm"
                              label="Desabilitar envio inclusão SM"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioFinalizacaoSm}
                              onChange={(e) => {
                                setFlagEnvioFinalizacaoSm(!flagEnvioFinalizacaoSm);
                              }}
                              type="checkbox"
                              id="flagEnvioFinalizacaoSm"
                              label="Desabilitar envio finalização SM"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioRetornoPesquisa}
                              onChange={(event) => {
                                setFlagEnvioRetornoPesquisa(!flagEnvioRetornoPesquisa);
                              }}
                              type="checkbox"
                              id="flagEnvioRetornoPesquisa"
                              label="Desabilitar envio retorno pesquisa"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioNaoConformidade}
                              onChange={(event) => {
                                setFlagEnvioNaoConformidade(!flagEnvioNaoConformidade);
                              }}
                              type="checkbox"
                              id="flagEnvioNaoConformidade"
                              label="Desabilitar envio não conformidade"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioChecklistVencido}
                              onChange={(event) => {
                                setFlagEnvioChecklistVencido(!flagEnvioChecklistVencido);
                              }}
                              type="checkbox"
                              id="flagEnvioChecklistVencido"
                              label="Desabilitar envio checklist vencido"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioCnhVencida}
                              onChange={(event) => {
                                setFlagEnvioCnhVencida(!flagEnvioCnhVencida);
                              }}
                              type="checkbox"
                              id="flagEnvioCnhVencida"
                              label="Desabilitar envio CNH vencida"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioPesquisaVencida}
                              onChange={(event) => {
                                setFlagEnvioPesquisaVencida(!flagEnvioPesquisaVencida);
                              }}
                              type="checkbox"
                              id="flagEnvioPesquisaVencida"
                              label="Desabilitar envio pesquisa vencida"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioInclusaoChecklist}
                              onChange={(event) => {
                                setFlagEnvioInclusaoChecklist(!flagEnvioInclusaoChecklist);
                              }}
                              type="checkbox"
                              id="flagEnvioInclusaoChecklist"
                              label="Desabilitar envio inclusão checklist"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Group className="mt-2 ml-2">
                          <Form.Check
                            checked={flagEnvioInclusaoSat}
                            onChange={(event) => {
                              setFlagEnvioInclusaoSat(!flagEnvioInclusaoSat);
                            }}
                            type="checkbox"
                            id="flagEnvioInclusaoSat"
                            label="Desabilitar envio inclusão SAT"
                          />
                        </Form.Group>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Telefone Principal</Form.Label>

                            <Form.Control
                              value={maskTel(model.telefonePrincipal)}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  telefonePrincipal: unmaskTel(e.target.value),
                                });
                              }}
                              id="telefonePrincipal"
                              name="telefonePrincipal"
                              placeholder=""
                              required
                              isInvalid={!!model.telefonePrincipal.substring(0, 2).includes('00')}
                              style={{ width: '100 %' }}
                              maxLength={14}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Telefone Secundário</Form.Label>

                            <Form.Control
                              value={maskTel(model.telefoneSecundario)}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  telefoneSecundario: unmaskTel(e.target.value),
                                });
                              }}
                              id="telefoneSecundario"
                              name="telefoneSecundario"
                              placeholder=""
                              style={{ width: '100 %' }}
                              maxLength={14}
                              isInvalid={!!model.telefoneSecundario.substring(0, 2).includes('00')}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          {/* <Form.Group as={Col}>
                      <Form.Label>Gerente Conta</Form.Label>

                      <Form.Control
                        value={model.idGerente}
                        onChange={(e) => {
                          setModel({
                            ...model,
                            idGerente: e.target.value,
                          });
                        }}
                        id="gerente"
                        name="gerente"
                        placeholder=""
                        style={{ width: '100 %' }}
                        maxLength={128}
                      />
                    </Form.Group> */}
                          <Form.Group as={Col}>
                            <Form.Label>Gerente Conta</Form.Label>

                            <Form.Control
                              value={model.idGerente}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  idGerente: parseInt(e.target.value, 10),
                                });
                              }}
                              id="idGerente"
                              name="idGerente"
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {listaGerentes.map((g) => (
                                <option key={g.id} value={g.id}>
                                  {g.nome}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Vencimento Fatura</Form.Label>

                            <Form.Control
                              value={model.vencimentoFatura ? model.vencimentoFatura : ''}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  vencimentoFatura: parseInt(e.target.value, 10),
                                });
                              }}
                              id="vencimentoFatura"
                              name="vencimentoFatura"
                              placeholder=""
                              style={{ width: '100 %' }}
                              maxLength={4}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Corretora</Form.Label>

                            <Form.Control
                              value={model.corretoraId ? model.corretoraId : ''}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  corretoraId: parseInt(e.target.value, 10),
                                });
                              }}
                              id="corretora"
                              name="corretora"
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {corretoras.map((corretora) => (
                                <option key={corretora.corCdId} value={corretora.corCdId}>
                                  {corretora.corTxNome}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <i
                            style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20 }}
                            className="pi pi-plus-circle icone_adicionar"
                            onClick={() => {
                              if (canInsertCorretora) {
                                setShowModalCorretora(true);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          />
                          <ModalAddCorretora
                            showModalCorretora={showModalCorretora}
                            setShowModalCorretora={setShowModalCorretora}
                          />
                          <Form.Group as={Col}>
                            <Form.Label>Seguradora</Form.Label>

                            <Form.Control
                              value={model.seguradoraId ? model.seguradoraId : ''}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  seguradoraId: parseInt(e.target.value, 10),
                                });
                              }}
                              id="seguradora"
                              name="seguradora"
                              placeholder=""
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {seguradoras.map((seguradora) => (
                                <option key={seguradora.segCdId} value={seguradora.segCdId}>
                                  {seguradora.segTxNome}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <i
                            style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20 }}
                            className="pi pi-plus-circle icone_adicionar"
                            onClick={() => {
                              if (canInsertSeguradora) {
                                setShowModalSeguradora(true);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          />
                          <ModalAddSeguradora
                            showModalSeguradora={showModalSeguradora}
                            setShowModalSeguradora={setShowModalSeguradora}
                          />
                          {/* <Link to="/cadastro-seguradora">
                      <i
                        style={{ fontSize: '1.2em', marginTop: '40px' }}
                        className="pi pi-plus-circle icone_adicionar"
                      />
                    </Link> */}
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Validade Apólice</Form.Label>
                            <br />
                            <Form.Control
                              value={model.dataValidadeApolice}
                              onChange={(e: any) => {
                                if (e.target.value.length > 10) {
                                  return;
                                }
                                const validationDate = isDateLessThanBaseDate({
                                  date: e.target.value,
                                });
                                setErroDataApolice(validationDate);
                                setModel({
                                  ...model,
                                  dataValidadeApolice: e.target.value,
                                });
                              }}
                              id="validadeApolice"
                              name="validadeApolice"
                              type="date"
                              style={{ width: '100%' }}
                            />
                            {erroDataApolice && (
                              <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                Data de validade da apólice não pode ser menor do que a data atual.
                              </p>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Observações</Form.Label>

                            <Form.Control
                              value={model.observacoes}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  observacoes: e.target.value,
                                });
                              }}
                              id="observacoes"
                              name="observacoes"
                              placeholder=""
                              style={{ width: '100 %' }}
                              maxLength={1024}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Itens para Centro de Custo:</Form.Label>
                            <Form.Control
                              value={model.centroCusto || ''}
                              as="textarea"
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  centroCusto: e.target.value,
                                });
                              }}
                              id="centroCusto"
                              name="centroCusto"
                              placeholder=""
                              style={{ width: '100%' }}
                              maxLength={1048}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Group className="mt-2">
                          <Form.Check
                            checked={flagAtivo}
                            onChange={() => {
                              setFlagAtivo(!flagAtivo);
                            }}
                            type="switch"
                            id="isActive"
                            label="Ativo"
                          />
                        </Form.Group>

                        {loading ? (
                          <>
                            <Spinner
                              animation="border"
                              variant="warning"
                              style={{
                                display: 'flex',
                                marginLeft: '47.5%',
                                marginTop: '5%',
                                marginBottom: '5%',
                              }}
                            />
                            <ButtonsForm canEdit={canEdit} disabled={loading} />
                          </>
                        ) : (
                          <ButtonsForm canEdit={canEdit} disabled={false} />
                        )}
                      </Form>

                      <Dialog
                        header="Este cliente foi excluído anteriormente. Tem certeza que deseja reativar o cadastro dele?"
                        footer={
                          <>
                            <Button
                              label="Não"
                              onClick={() => setIsDialogVisibleRecuperaDados(false)}
                            />
                            <Button
                              label="Sim"
                              onClick={() => {
                                setIsDialogVisibleRecuperaDados(false);
                                handleRecuperarPorCodigo(model.codigoLegado);
                              }}
                            />
                          </>
                        }
                        visible={isDialogVisibleRecuperaDados}
                        style={{ width: '50vw' }}
                        modal
                        onHide={() => setIsDialogVisibleRecuperaDados(false)}
                      />
                    </Tab>

                    {/* <Tab eventKey="gerarSenha" title="Gerar Senha (WS)">
                <TabGerarSenha
                  idCliente={id}
                />
              </Tab> */}

                    <Tab eventKey="servicosContratados" title="Serviços Contratados">
                      <TabServicosContratados
                        setServicosContratados={setServicosContratados}
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                        fetchServicosContratados={getServicosContratadosCliente}
                      />
                    </Tab>
                    <Tab
                      eventKey="controleTemperatura"
                      title="Parâmetros Controle Temperatura"
                      disabled={servicosContratados.controleTemperatura !== 1}
                    >
                      <TabControleTemperatura
                        servicosContratados={servicosContratados}
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                      />
                    </Tab>
                    <Tab
                      eventKey="controleVelocidade"
                      title="Parâmetros Controle Velocidade"
                      disabled={servicosContratados.controleVelocidade !== 1}
                    >
                      <TabControleVelocidade
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                      />
                    </Tab>
                  </Tabs>
                </>
              ) : (
                <>
                  <Tabs
                    defaultActiveKey="dadosPrincipais"
                    id="controlled-tab-example"
                    className="mt-4"
                  >
                    <Tab eventKey="dadosPrincipais" title="Dados Principais">
                      <Form noValidate validated={isFormInvalid} onSubmit={handleRegisterDados}>
                        <h1
                          className="info-obrigatorio"
                          style={{ marginTop: '-10px', marginBottom: '10px' }}
                        >
                          *Informações obrigatórias
                        </h1>
                        {model.cnpj && (
                          <ProfilePicture setFoto={setLogo} foto={logo} name={model.cnpj}>
                            <Form.Row>
                              <Form.Group as={Col} className="mt-5">
                                <Form.Label className="required">Código</Form.Label>

                                <Form.Control
                                  value={model.codigoLegado}
                                  onChange={(e) => {
                                    if (
                                      validaNumero(e.target.value) ||
                                      e.target.value.length === 0
                                    ) {
                                      setModel({
                                        ...model,
                                        codigoLegado: e.target.value,
                                      });
                                    }
                                  }}
                                  onBlur={() => validaCodigoLegadoExistente(model.codigoLegado)}
                                  id="codigoLegado"
                                  name="codigoLegado"
                                  placeholder=""
                                  required
                                  maxLength={16}
                                  isInvalid={erroCodigoLegado && !dadosRecuperados}
                                  disabled={id}
                                />
                                {erroCodigoLegado && !dadosRecuperados && (
                                  <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                    Código Legado já existe.
                                  </p>
                                )}
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group as={Col}>
                                <Form.Label>Código Bimmer</Form.Label>

                                <Form.Control
                                  value={model.codigoBimmer}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    // Atualiza o modelo e define o erro com base na validade do valor
                                    setModel((prevModel) => {
                                      const isValid = validaNumeroCinco(value);

                                      if (isValid) {
                                        setErroCodigoBimmer(false);
                                      } else {
                                        setErroCodigoBimmer(true);
                                      }
                                      return {
                                        ...prevModel,
                                        codigoBimmer: value,
                                      };
                                    });
                                  }}
                                  onBlur={() => {
                                    if (!validaNumeroCinco(model.codigoBimmer)) {
                                      setErroCodigoBimmer(true);
                                    } else {
                                      setErroCodigoBimmer(false);
                                    }
                                  }}
                                  id="codigoBimmer"
                                  name="codigoBimmer"
                                  maxLength={5}
                                  isInvalid={erroCodigoBimmer}
                                  pattern="\d{5}"
                                />

                                {erroCodigoBimmer && model.codigoBimmer.length > 0 && (
                                  <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                    Código Bimmer inválido, insira exatamente 5 números.
                                  </p>
                                )}
                              </Form.Group>
                            </Form.Row>
                          </ProfilePicture>
                        )}

                        {/* {logo && newLogo === false ? (
                    <>
                      <a style={{ textDecoration: 'none', paddingBottom: 15 }} href={link}>
                        Clique para fazer o download da logo do cliente
                      </a>
                      <Button
                        style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                        title="Editar Foto"
                        onClick={() => {
                          if (canEdit) {
                            setNewLogo(true);
                          } else {
                            history.push('/acessonegado');
                          }
                        }}
                      >
                        <FiEdit size={22} color="black" />
                      </Button>
                    </>
                  ) : (
                    <Content>
                      <FileProvider done={wasCadastred} foto={logo} setFoto={setLogo}>
                        <Upload />
                        <FileList />
                      </FileProvider>
                    </Content>
                  )} */}
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">CNPJ</Form.Label>

                            <Form.Control
                              value={id ? maskCpnj(model.cnpj) : model.cnpj}
                              onChange={(e) => {
                                // if (e.target.value.length === 18) {
                                //   buscarDadosPeloCnpj(e.target.value);
                                // }

                                setModel({
                                  ...model,
                                  cnpj: maskCpnj(e.target.value),
                                });
                              }}
                              id="cnpj"
                              name="cnpj"
                              placeholder=""
                              required
                              maxLength={18}
                              style={{ width: '49.8%' }}
                              isInvalid={erroCnpj}
                              disabled
                            />
                            {erroCnpj && (
                              <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                CNPJ já existe.
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Razão Social</Form.Label>

                            <Form.Control
                              value={model.razaoSocial}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  razaoSocial: e.target.value,
                                });
                              }}
                              id="razaoSocial"
                              name="razaoSocial"
                              placeholder=""
                              required
                              maxLength={256}
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Nome Fantasia</Form.Label>

                            <Form.Control
                              value={model.nomeFantasia}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  nomeFantasia: e.target.value,
                                });
                              }}
                              id="nomeFantasia"
                              name="nomeFantasia"
                              placeholder=""
                              required
                              maxLength={64}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">CEP</Form.Label>

                            <Form.Control
                              value={id ? maskCep(model.enderecoCep) : model.enderecoCep}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoCep: maskCep(e.target.value),
                                });
                                setCepMudou(true);
                              }}
                              id="cep"
                              name="cep"
                              placeholder=""
                              required
                              maxLength={9}
                              minLength={9}
                              style={{ width: '49.8%' }}
                            />
                            {error && (
                              <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                CEP Inválido
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Logradouro</Form.Label>

                            <Form.Control
                              value={model.enderecoLogradouro}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoLogradouro: e.target.value,
                                });
                              }}
                              id="logradouro"
                              name="logradouro"
                              required
                              style={{ width: '100%' }}
                              maxLength={64}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label className="required">Número</Form.Label>

                            <Form.Control
                              value={model.enderecoNumero}
                              onChange={(e) => {
                                if (validaNumero(e.target.value) || e.target.value.length === 0) {
                                  setModel({
                                    ...model,
                                    enderecoNumero: e.target.value,
                                  });
                                } else {
                                }
                              }}
                              id="numero"
                              name="numero"
                              placeholder=""
                              required
                              style={{ width: '100%' }}
                              maxLength={8}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Complemento</Form.Label>

                            <Form.Control
                              value={model.enderecoComplemento}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  enderecoComplemento: e.target.value,
                                });
                              }}
                              id="complemento"
                              name="complemento"
                              placeholder=""
                              maxLength={64}
                              style={{ width: '100%' }}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label className="required">Bairro</Form.Label>

                            <Form.Control
                              value={model.enderecoBairro}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  enderecoBairro: e.target.value,
                                });
                              }}
                              id="bairro"
                              name="bairro"
                              placeholder=""
                              maxLength={64}
                              required
                              style={{ width: '100%' }}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">UF</Form.Label>

                            <Form.Control
                              value={model.enderecoUf ? model.enderecoUf : ''}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoUf: e.target.value,
                                });
                                loadMunicipiosPorEstado(e.target.value);
                              }}
                              id="idCodigoIbge"
                              name="idCodigoIbge"
                              required
                              as="select"
                              disabled={!!model.enderecoUf}
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {ufs.map((uf) => (
                                <option value={uf.id} key={uf.id}>
                                  {uf.sigla}
                                </option>
                              ))}
                              <option
                                value={model.enderecoUf ? model.enderecoUf : ''}
                                key={model.enderecoUf}
                              >
                                {viaCepUf}
                              </option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label className="required">Municipio</Form.Label>

                            <Form.Control
                              value={model.enderecoCidade}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  enderecoCidade: e.target.value,
                                });
                              }}
                              id="cidade"
                              name="cidade"
                              placeholder=""
                              required
                              as="select"
                              disabled={!!model.enderecoUf}
                              style={{ width: '100%' }}
                            >
                              {viaCepCidade ? (
                                <option key={model.enderecoCidade} value={model.enderecoCidade}>
                                  {viaCepCidade}
                                </option>
                              ) : (
                                municipios.map((municipio) => (
                                  <option
                                    key={municipio.nomeMunicipio}
                                    value={municipio.nomeMunicipio}
                                  >
                                    {municipio.nomeMunicipio}
                                  </option>
                                ))
                              )}

                              {id && (
                                <option value={model.enderecoCidade}>{model.enderecoCidade}</option>
                              )}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Email Principal</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={model.emailPrincipal || ''}
                              id="emailPrincipal"
                              name="emailPrincipal"
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  emailPrincipal: e.target.value,
                                });
                              }}
                              onBlur={() => {
                                if (validaEmailsEmLista(model.emailPrincipal)) {
                                  setIsValidEmail(true);
                                } else {
                                  setIsValidEmail(false);
                                }
                              }}
                              maxLength={1048}
                            />
                            {!isValidEmail && (
                              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                                Email inválido
                              </p>
                            )}
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioInclusaoSm}
                              onChange={(e) => {
                                setFlagEnvioInclusaoSm(!flagEnvioInclusaoSm);
                              }}
                              type="checkbox"
                              id="flagEnvioInclusaoSm"
                              label="Desabilitar envio inclusão SM"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioFinalizacaoSm}
                              onChange={(e) => {
                                setFlagEnvioFinalizacaoSm(!flagEnvioFinalizacaoSm);
                              }}
                              type="checkbox"
                              id="flagEnvioFinalizacaoSm"
                              label="Desabilitar envio finalização SM"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioRetornoPesquisa}
                              onChange={(event) => {
                                setFlagEnvioRetornoPesquisa(!flagEnvioRetornoPesquisa);
                              }}
                              type="checkbox"
                              id="flagEnvioRetornoPesquisa"
                              label="Desabilitar envio retorno pesquisa"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioNaoConformidade}
                              onChange={(event) => {
                                setFlagEnvioNaoConformidade(!flagEnvioNaoConformidade);
                              }}
                              type="checkbox"
                              id="flagEnvioNaoConformidade"
                              label="Desabilitar envio não conformidade"
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioChecklistVencido}
                              onChange={(event) => {
                                setFlagEnvioChecklistVencido(!flagEnvioChecklistVencido);
                              }}
                              type="checkbox"
                              id="flagEnvioChecklistVencido"
                              label="Desabilitar envio checklist vencido"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioCnhVencida}
                              onChange={(event) => {
                                setFlagEnvioCnhVencida(!flagEnvioCnhVencida);
                              }}
                              type="checkbox"
                              id="flagEnvioCnhVencida"
                              label="Desabilitar envio CNH vencida"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioPesquisaVencida}
                              onChange={(event) => {
                                setFlagEnvioPesquisaVencida(!flagEnvioPesquisaVencida);
                              }}
                              type="checkbox"
                              id="flagEnvioPesquisaVencida"
                              label="Desabilitar envio pesquisa vencida"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mt-2 ml-2">
                            <Form.Check
                              checked={flagEnvioInclusaoChecklist}
                              onChange={(event) => {
                                setFlagEnvioInclusaoChecklist(!flagEnvioInclusaoChecklist);
                              }}
                              type="checkbox"
                              id="flagEnvioInclusaoChecklist"
                              label="Desabilitar envio inclusão checklist"
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Group className="mt-2 ml-2">
                          <Form.Check
                            checked={flagEnvioInclusaoSat}
                            onChange={(event) => {
                              setFlagEnvioInclusaoSat(!flagEnvioInclusaoSat);
                            }}
                            type="checkbox"
                            id="flagEnvioInclusaoSat"
                            label="Desabilitar envio inclusão SAT"
                          />
                        </Form.Group>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label className="required">Telefone Principal</Form.Label>

                            <Form.Control
                              value={maskTel(model.telefonePrincipal)}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  telefonePrincipal: unmaskTel(e.target.value),
                                });
                              }}
                              id="telefonePrincipal"
                              name="telefonePrincipal"
                              placeholder=""
                              required
                              isInvalid={!!model.telefonePrincipal.substring(0, 2).includes('00')}
                              style={{ width: '100 %' }}
                              maxLength={14}
                            />
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Telefone Secundário</Form.Label>

                            <Form.Control
                              value={maskTel(model.telefoneSecundario)}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  telefoneSecundario: unmaskTel(e.target.value),
                                });
                              }}
                              id="telefoneSecundario"
                              name="telefoneSecundario"
                              placeholder=""
                              style={{ width: '100 %' }}
                              maxLength={14}
                              isInvalid={!!model.telefoneSecundario.substring(0, 2).includes('00')}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          {/* <Form.Group as={Col}>
                      <Form.Label>Gerente Conta</Form.Label>

                      <Form.Control
                        value={model.idGerente}
                        onChange={(e) => {
                          setModel({
                            ...model,
                            idGerente: e.target.value,
                          });
                        }}
                        id="gerente"
                        name="gerente"
                        placeholder=""
                        style={{ width: '100 %' }}
                        maxLength={128}
                      />
                    </Form.Group> */}
                          <Form.Group as={Col}>
                            <Form.Label>Gerente Conta</Form.Label>

                            <Form.Control
                              value={model.idGerente}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  idGerente: parseInt(e.target.value, 10),
                                });
                              }}
                              id="idGerente"
                              name="idGerente"
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {listaGerentes.map((g) => (
                                <option key={g.id} value={g.id}>
                                  {g.nome}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Vencimento Fatura</Form.Label>

                            <Form.Control
                              value={model.vencimentoFatura ? model.vencimentoFatura : ''}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  vencimentoFatura: parseInt(e.target.value, 10),
                                });
                              }}
                              id="vencimentoFatura"
                              name="vencimentoFatura"
                              placeholder=""
                              style={{ width: '100 %' }}
                              maxLength={4}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Corretora</Form.Label>

                            <Form.Control
                              value={model.corretoraId ? model.corretoraId : ''}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  corretoraId: parseInt(e.target.value, 10),
                                });
                              }}
                              id="corretora"
                              name="corretora"
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {corretoras.map((corretora) => (
                                <option key={corretora.corCdId} value={corretora.corCdId}>
                                  {corretora.corTxNome}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <i
                            style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20 }}
                            className="pi pi-plus-circle icone_adicionar"
                            onClick={() => {
                              if (canInsertCorretora) {
                                setShowModalCorretora(true);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          />
                          <ModalAddCorretora
                            showModalCorretora={showModalCorretora}
                            setShowModalCorretora={setShowModalCorretora}
                          />
                          {/* <Link to="/cadastro-corretora">
                      <i
                        style={{ fontSize: '1.2em', marginTop: '40px' }}
                        className="pi pi-plus-circle icone_adicionar"
                      />
                    </Link> */}

                          <Form.Group as={Col}>
                            <Form.Label>Seguradora</Form.Label>

                            <Form.Control
                              value={model.seguradoraId ? model.seguradoraId : ''}
                              onChange={(e: any) => {
                                setModel({
                                  ...model,
                                  seguradoraId: parseInt(e.target.value, 10),
                                });
                              }}
                              id="seguradora"
                              name="seguradora"
                              placeholder=""
                              as="select"
                              style={{ width: '100%' }}
                            >
                              <option value="" />
                              {seguradoras.map((seguradora) => (
                                <option key={seguradora.segCdId} value={seguradora.segCdId}>
                                  {seguradora.segTxNome}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <i
                            style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 20 }}
                            className="pi pi-plus-circle icone_adicionar"
                            onClick={() => {
                              if (canInsertSeguradora) {
                                setShowModalSeguradora(true);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          />
                          <ModalAddSeguradora
                            showModalSeguradora={showModalSeguradora}
                            setShowModalSeguradora={setShowModalSeguradora}
                          />
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Validade Apólice</Form.Label>
                            <br />
                            <Form.Control
                              value={model.dataValidadeApolice ? model.dataValidadeApolice : ''}
                              onChange={(e: any) => {
                                if (e.target.value.length > 10) {
                                  return;
                                }
                                const validationDate = isDateLessThanBaseDate({
                                  date: e.target.value,
                                });
                                setErroDataApolice(validationDate);
                                setModel({
                                  ...model,
                                  dataValidadeApolice: e.target.value,
                                });
                              }}
                              id="validadeApolice"
                              name="validadeApolice"
                              type="date"
                              style={{ width: '100%' }}
                            />
                            {erroDataApolice && (
                              <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                                Data de validade da apólice não pode ser menor do que a data atual.
                              </p>
                            )}
                          </Form.Group>

                          <Form.Group as={Col}>
                            <Form.Label>Observações</Form.Label>

                            <Form.Control
                              value={model.observacoes}
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  observacoes: e.target.value,
                                });
                              }}
                              id="observacoes"
                              name="observacoes"
                              placeholder=""
                              style={{ width: '100 %' }}
                              maxLength={1024}
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label>Itens para Centro de Custo:</Form.Label>
                            <Form.Control
                              value={model.centroCusto || ''}
                              as="textarea"
                              onChange={(e) => {
                                setModel({
                                  ...model,
                                  centroCusto: e.target.value,
                                });
                              }}
                              id="centroCusto"
                              name="centroCusto"
                              placeholder=""
                              style={{ width: '100%' }}
                              maxLength={1048}
                            />
                          </Form.Group>
                        </Form.Row>

                        <Form.Row>
                          <Form.Group as={Col}>
                            <hr />
                          </Form.Group>
                        </Form.Row>

                        <Form.Group className="mt-2">
                          <Form.Check
                            checked={flagAtivo}
                            onChange={(event) => {
                              setFlagAtivo(!flagAtivo);
                            }}
                            type="switch"
                            id="flagAtivo"
                            label="Ativo"
                          />
                        </Form.Group>

                        <ButtonsForm canEdit={canEdit} />
                      </Form>
                    </Tab>
                    <Tab eventKey="particularidades" title="Particularidades">
                      <TabParticularidades
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                        setCadastroRealizado={setCadastroRealizado}
                      />
                    </Tab>

                    <Tab
                      eventKey="gerarSenha"
                      title="Gerar Senha (WS)"
                      onEnter={() => {
                        if (!canGeneratePassword) {
                          history.push('/acessonegado');
                        }
                      }}
                    >
                      <TabGerarSenha idCliente={id} />
                    </Tab>

                    <Tab eventKey="servicosContratados" title="Serviços Contratados">
                      <TabServicosContratados
                        setServicosContratados={setServicosContratados}
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                        fetchServicosContratados={getServicosContratadosCliente}
                      />
                    </Tab>

                    <Tab
                      eventKey="controleTemperatura"
                      title="Parâmetros Controle Temperatura"
                      disabled={temperatura !== 1}
                    >
                      <TabControleTemperatura
                        servicosContratados={servicosContratados}
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                      />
                    </Tab>

                    <Tab
                      eventKey="controleVelocidade"
                      title="Parâmetros Controle Velocidade"
                      disabled={velocidade !== 1}
                    >
                      <TabControleVelocidade
                        canEdit={canEdit}
                        razaoSocial={model.razaoSocial}
                        idCliente={id}
                        cadastroRealizado={cadastroRealizado}
                        idParticularidades={idParticularidades}
                        idServicos={idServicos}
                        idClienteByPost={idClienteByPost}
                      />
                    </Tab>
                  </Tabs>
                </>
              )}
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default ClienteCadastro;
