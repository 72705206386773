/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/esm/Modal';
import { Spinner } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { useToast } from '../../hooks/Toast';
import Logo from '../../assets/logo_melhorresol.png';
import Banner from '../../assets/banner-login.png';
import { ReactComponent as MapImage } from '../../assets/undraw_map_1r69.svg';
import './styles.css';
import { authContext } from '../../context/AuthContext';
import { loginUser, doLogin, saveCookieSessionData } from '../../services/cookieService';
import { login as loginService } from '../../services/login';
import { noArroba } from '../../util/Validacoes/Validacoes';
import instanceMaquina from '../../config/axiosMaquina';

const Login: React.FC = () => {
  const { addToast } = useToast();
  const { setAuth } = useContext(authContext);
  const [login, setLogin] = useState<string>('');
  const [codigo, setCodigo] = useState<number | null>(null);
  const [senha, setSenha] = useState<string>('');
  const [ip, setIp] = useState<string>('');
  const [role, setRole] = useState<string>('user');
  const [notShow, setNotShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const messages = useRef<Toast>();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const handleShow2 = () => setShow2(true);
  const getUsuarioBloqueado = useCallback(async (codigoUsuario: number | null) => {
    if (codigoUsuario == null) {
      return false;
    }
    try {
      const response = await instanceMaquina.get(
        `/cliente/getServicosContratadosCliente?cliente=${codigoUsuario}`
      );

      if (
        response.data.cscNuMapaRastreamento === 2 ||
        response.data.cscNuCadastroConsulta === 2 ||
        response.data.cscNuMonitoramentoVeiculos === 2 ||
        response.data.cscNuRotaPorPontos === 2 ||
        response.data.cscNuSmsviagem === 2 ||
        response.data.cscNuWsposicoes === 2
      ) {
        handleShow2();
        return true;
      }
      return false;
    } catch (err: any) {
      return false;
    }
  }, []);

  const handleLogin = useCallback(async () => {
    try {
      let userIp = null;
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        userIp = data.ip;
      } catch {
        userIp = null;
      }

      if (role === 'user' && !codigo) {
        addToast({
          title: 'Erro',
          description: 'Código obrigatório!',
          type: 'error',
        });
        return;
      }

      setLoading(true);

      if (await getUsuarioBloqueado(codigo)) {
        setLoading(false);
        return;
      }

      const loginResponse = await loginService({
        codigo,
        login,
        role,
        senha,
        ip: userIp,
      });

      if (typeof loginResponse === 'string') {
        addToast({
          title: 'Erro',
          description: loginResponse,
          type: 'error',
        });
        setLoading(false);
        return;
      }

      if (loginResponse.userSession.usuarioVO.role === 'user' && typeof codigo !== 'number') {
        setLoading(false);
        return;
      }

      saveCookieSessionData(loginResponse.userSession);
      doLogin();
      handleShow();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [
    codigo,
    login,
    role,
    senha,
    getUsuarioBloqueado,
    saveCookieSessionData,
    doLogin,
    handleShow,
    addToast,
  ]);

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      // if ( event.code === 'Enter' ) {
      // if ( event.code === 'Enter' || ((key===13) && (e.location===3)) ) {
      if (event.keyCode === 13) {
        handleLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [login, senha, role, codigo, handleLogin]);

  return (
    <div className="p-grid screen-container m-0">
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Usuário ou Senha incorretos!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show2}
        onHide={handleClose2}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Favor entrar em contato com nosso setor administrativo (21) 3644-7000, ramal 9005
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{ width: '70%', margin: '0 auto' }}
        className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center pb-3 screen-left-container"
      >
        <img src={Logo} className="logo-image" alt="Logo" />
        <div className="container-title">
          <h1 className="login-title">Seja bem-vindo ao Golden LOG!</h1>
          <div className="line" />
        </div>
        <Toast
          ref={(el) => {
            if (el) messages.current = el;
          }}
        />

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              marginLeft: '20.5%',
              marginRight: '20%',
              marginTop: '10%',
            }}
          />
        ) : (
          <div className="w-100">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                marginLeft: '20%',
                marginRight: '20%',
                marginTop: '1rem',
              }}
            >
              <div
                style={{
                  marginInline: '5%',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="cb1" className="role-text">
                  Cliente:
                </label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <RadioButton
                    inputId="cb1"
                    name="tipoLogin"
                    value="user"
                    onChange={(e) => {
                      setRole(e.value);
                      setNotShow(false);
                    }}
                    checked={role === 'user'}
                  />
                </div>
              </div>
              <div
                style={{
                  marginInline: '5%',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="cb1" className="role-text">
                  GR:
                </label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <RadioButton
                    inputId="cb1"
                    name="tipoLogin"
                    value="admin"
                    onChange={(e) => {
                      setRole(e.value);
                      setNotShow(true);
                      setCodigo(null);
                    }}
                    checked={role === 'admin'}
                  />
                </div>
              </div>
            </div>
            {notShow === true ? (
              <></>
            ) : (
              <>
                <label htmlFor="codigo" className="codigo-text">
                  Código:
                </label>
                <div>
                  <InputText
                    style={{
                      background: '#FFFFFF0F',
                      color: '#fff',
                      marginLeft: '25%',
                    }}
                    className="codigo-box"
                    id="codigo-login"
                    type="number"
                    disabled={role === 'admin'}
                    value={codigo === null ? '' : codigo}
                    onChange={(e) => setCodigo(e.currentTarget.valueAsNumber)}
                  />
                </div>
              </>
            )}

            <label htmlFor="user" className="user-text">
              Usuário:
            </label>
            <div>
              <InputText
                style={{
                  background: '#FFFFFF0F',
                  color: '#fff',
                  marginLeft: '25%',
                }}
                className="user-box"
                id="login"
                value={login}
                keyfilter={/^[^@!]+$/}
                onChange={(e) => setLogin(noArroba(e.currentTarget.value))}
              />
            </div>

            <label htmlFor="password" className="password-text">
              Senha:
            </label>
            <div>
              <Password
                style={{
                  background: '#FFFFFF0F',
                  color: '#fff',
                  marginLeft: '25%',
                }}
                className="password-box"
                id="password"
                value={senha}
                feedback={false}
                onChange={(e) => setSenha(e.currentTarget.value)}
              />
            </div>

            {/* <div className="forgotten">
              <Link to="/forgotten" style={{ color: '#fff' }}>
                Esqueci minha senha
              </Link>
            </div> */}

            <Button
              type="submit"
              style={{
                background: '#FFC800',
                color: '#0B141F',
                fontWeight: 'bold',
                marginTop: '1.5rem',
                marginLeft: '30%',
              }}
              label="Entrar"
              className="login-button"
              onClick={handleLogin}
            />
          </div>
        )}
      </div>

      <div
        style={{ justifyContent: 'space-between' }}
        className="col-12 col-lg-6 screen-right-container d-flex flex-column align-items-center "
      >
        <div className="tracking-system-container m-0">
          <h1>Sistema de Gestão de GR e Logística</h1>
        </div>
        <div className="info-subtitle">
          <p>
            O sistema GoldenLog possui diversas ferramentas que visam trazer maior segurança,
            visibilidade e otimização operacional com recursos como: controle de velocidade,
            temperatura, fadiga do motorista, transit-time, entregas, dentre outros!
          </p>
        </div>

        <div className="advantages-container">
          <a
            className="link-banner"
            href="http://www.gservice.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Banner} className="logo-image-banner" alt="Banner" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;

// `
// :ProgressSpinner .p-progress-spinner-circle {
// animation: ui-progress-spinner-color;
// }

// @keyframes ui-progress-spinner-color {
//   100%,
//   0% {
//       stroke: #FFF;
//   }
//   40% {
//       stroke: #FFF;
//   }
//   66% {
//       stroke: #FFF;
//   }
//   80%,
//   90% {
//       stroke: #FFF;
//   }
// }
// }
// `;
