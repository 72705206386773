/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { AiFillClockCircle } from 'react-icons/ai';
import { RiAlertFill, RiTruckFill, RiTruckLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { Button, FormControl, Modal, Col } from 'react-bootstrap';
import { Button as Button2 } from 'primereact/button';
import ModalLSC from './modalTratativas';
import PopUpMapa from '../../components/PopUpMapa';
import ModalReagendamento from './modalReagendamento';
import '../ProgressaoViagens/styles.css';
import { Spinner } from '../../components/Spinner';
import { BarraViagem } from '../../components/BarraViagem';
import { PainelLSCType, ViagemTransitTime } from '../../Types/PainelLSCTypes';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { getFormatedData } from '../../util/format';
import { handleRota } from '../../util/getPontosLista';
import orderBy from 'lodash/orderBy';
import { obterDataHora } from '../../util/date';
import { SMMapa } from '../Mapa/Mapa';

type gridProps = {
  each: any;
  fetchData: () => void;
  canEdit?: boolean;
};

export function GridExpansivel({ each, fetchData, canEdit }: gridProps) {
  const [viagemReagendada, setViagemReagendada] = useState<ViagemTransitTime>();
  const [viagem, setViagem] = useState<ViagemTransitTime>();
  const [visible, setVisible] = useState(false);
  const [dadosMapa, setDadosMapa] = useState<SMMapa>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showReagendamento, setShowReagendamento] = useState(false);
  const [showMapa, setShowMapa] = useState(false);
  const [dialogConcluirTratativas, setDialogConcluirTratativas] = useState(false);
  const [dialogTratativaConcluida, setDialogTratativaConcluida] = useState(false);
  const [dialogTratativaErro, setDialogTratativaErro] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const history = useHistory();
  const { addToast } = useToast();

  const handleVisualizar = (id: number) => {
    history.push(`/editar-sm-emandamento/${id}`);
  };
  const handleStartTratativa = async () => {
    each.statustratamentosm = 'Em Tratamento';
    setAtualizar(atualizar + 1);
  };

  const expandir = async (idSm: number, reagendamento: boolean) => {
    setVisible(!visible);
    setLoading(true);
    if (!visible) {
      try {
        const response = await api.get(
          `/solicitacao-monitoramento/lsc-lista-pontos-reagendamento?idSm=${idSm}&idUsuario=${idUsuario}`
        );
        const response2 = await api.get(
          `/solicitacao-monitoramento/monitoramento-sm-mapa-mestre-detalhe?idSm=${idSm}&idUsuario=${idUsuario}`
        );
        console.log(response.data[0]);
        setDadosMapa(response2.data[0]);
        setViagem(response.data[0]);
        if (response.data[0].monitSmProgresViagPontoReagendadosVO.length && reagendamento) {
          setViagemReagendada({
            ...response.data[0],
            monitSmProgresViagPontoVO: orderBy(
              response.data[0].monitSmProgresViagPontoReagendadosVO,
              'numSeqPonto'
            ),
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  const abrirMapa = () => {
    setShowMapa(true);
  };

  const concluirTratativas = async () => {
    try {
      await api.put(
        `/solicitacao-monitoramento/concluir-tratamento?idLsc=${each.idlsc}&idUsuarioConclusao=${idUsuario}`
      );
      setDialogTratativaConcluida(true);
    } catch (error) {
      setDialogTratativaErro(true);
    }
  };

  return (
    <>
      {each && (
        <>
          <tr
            onClick={() => {
              expandir(each.idsm, each.reagendamento);
            }}
          >
            <td onClick={() => handleVisualizar(each.idsm)} id="link">
              {each.idsm}
            </td>
            <td id="esq">{each.razaosocialcli}</td>
            <td id="esq">{each.nomeembarcador}</td>
            <td>{each.placas}</td>
            <td>{each.proxponto}</td>
            <td id="esq">
              {each.previsaoproxponto
                ? getFormatedData(each.previsaoproxponto?.split(' ')[0]) +
                  ' ' +
                  each.previsaoproxponto?.split(' ')[1]?.slice(0, 5)
                : ''}
            </td>
            <td>{each.diantanciaproxponto ? `${each.diantanciaproxponto}km` : ''}</td>
            <td>{each.tempochegadaproxponto}</td>
            <td>
              {each.statusviagemsm}
              {each.statusviagemsm === 'Em Atraso' && (
                <RiAlertFill color="#E03B24" style={{ float: 'right' }} />
              )}
              {each.statusviagemsm === 'Parcialmente Atrasado' && (
                <AiFillClockCircle color="#F57C00" style={{ float: 'right' }} />
              )}
            </td>
            <td>
              {each.statustratamentosm}
              {each.statustratamentosm &&
                each.statustratamentosm.toLowerCase() === 'em tratamento' && (
                  <RiTruckFill color="#64A338" style={{ float: 'right' }} />
                )}
              {each.statustratamentosm && each.statustratamentosm === 'Pendente' && (
                <AiFillClockCircle color="#FFCC00" style={{ float: 'right' }} />
              )}
            </td>
            <td id="esq">{each.motivoatraso}</td>
          </tr>
          {/* Campo expansivel */}
          {visible ? (
            loading ? (
              <td colSpan={11} className="expand">
                <Spinner />
              </td>
            ) : (
              <>
                <td colSpan={11} className="expand">
                  <table className="table">
                    {/* Nome colunas */}
                    <thead>
                      <tr>
                        <th>Posição</th>
                        <th>Status</th>
                        <th>Baú</th>
                        <th>Operação</th>
                        <th>Localização</th>
                        <th>Rota</th>
                        <th>Progressão</th>
                        <th />
                        <th />
                        <th />
                      </tr>
                    </thead>
                    {viagem?.monitSmProgresViagPontoVO?.length && (
                      <>
                        <tbody>
                          <tr>
                            <td>{dadosMapa ? obterDataHora(dadosMapa?.dtHrUltimaPosicao) : ''}</td>
                            <td>{dadosMapa?.statusViagem}</td>
                            <td>{dadosMapa?.bau}</td>
                            <td>{dadosMapa?.operacao}</td>
                            <td id="esq">{dadosMapa?.localizacao}</td>
                            <td
                              id="esq"
                              title={handleRota(
                                dadosMapa?.listMonitoramentoSmMapaPontoVO,
                                dadosMapa?.rota || ''
                              )}
                            >
                              {handleRota(
                                dadosMapa?.listMonitoramentoSmMapaPontoVO,
                                dadosMapa?.rota || ''
                              ).length > 20
                                ? `${handleRota(
                                    dadosMapa?.listMonitoramentoSmMapaPontoVO,
                                    dadosMapa?.rota || ''
                                  )
                                    .slice(0, 30)
                                    .trim()}...`
                                : handleRota(
                                    dadosMapa?.listMonitoramentoSmMapaPontoVO,
                                    dadosMapa?.rota || ''
                                  )}
                            </td>
                            <td id="link" onClick={() => abrirMapa()}>
                              {viagem.percPercorrido}%
                            </td>
                            <td style={{ width: '5px' }}>
                              <Button
                                id="buttons"
                                onClick={() => {
                                  if (canEdit) {
                                    setShowReagendamento(true);
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                              >
                                Reagendar
                              </Button>
                            </td>
                            <td style={{ width: '5px' }}>
                              <Button
                                id="buttons"
                                onClick={() => {
                                  if (canEdit) {
                                    setShowModal(true);
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                              >
                                Tratar
                              </Button>
                            </td>
                            <td style={{ width: '5px' }}>
                              <Button
                                id="buttonConcluir"
                                onClick={() => {
                                  if (canEdit) {
                                    setDialogConcluirTratativas(true);
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                              >
                                Concluir
                              </Button>
                            </td>
                          </tr>
                          <BarraViagem viagem={viagem} />
                          <PopUpMapa
                            showModal={showMapa}
                            setShowModal={setShowMapa}
                            data={each}
                            viagem={viagem}
                          />

                          {viagemReagendada?.monitSmProgresViagPontoVO?.length && (
                            <>
                              <td colSpan={8}>
                                <strong>Viagem Reagendada</strong>
                              </td>
                              <BarraViagem viagem={viagemReagendada} />
                            </>
                          )}
                          <ModalReagendamento
                            showModal={showReagendamento}
                            setShowModal={setShowReagendamento}
                            idSm={each.idsm}
                          />
                        </tbody>
                      </>
                    )}
                  </table>
                </td>
              </>
            )
          ) : null}
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus
            show={dialogConcluirTratativas}
            onHide={() => {
              setDialogConcluirTratativas(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
              Deseja concluir as tratativas desta SM?
            </Modal.Body>
            <Modal.Footer>
              <Button2
                className="p-button-secondary"
                style={{ width: 100, marginRight: 10 }}
                onClick={() => {
                  setDialogConcluirTratativas(false);
                }}
                label="Não"
              />
              <Button2
                style={{ width: 100, marginRight: 10 }}
                className="p-button-warning"
                label="Sim"
                onClick={() => {
                  concluirTratativas();
                  setDialogConcluirTratativas(false);
                }}
              />
            </Modal.Footer>
          </Modal>
          <Dialog
            header="Tratamento concluida com sucesso!"
            footer={
              <Button
                onClick={() => {
                  setDialogTratativaConcluida(false);
                  fetchData();
                }}
              >
                Ok
              </Button>
            }
            visible={dialogTratativaConcluida}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setDialogTratativaConcluida(false);
              fetchData();
            }}
          />
          <Dialog
            header="Erro ao concluir tratamento!"
            footer={
              <Button
                onClick={() => {
                  setDialogTratativaErro(false);
                }}
              >
                Ok
              </Button>
            }
            visible={dialogTratativaErro}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setDialogTratativaErro(false);
            }}
          />
          <ModalLSC
            showModal={showModal}
            setShowModal={setShowModal}
            data={each}
            fetchData={fetchData}
            handleStartTratativa={handleStartTratativa}
          />
        </>
      )}
    </>
  );
}
