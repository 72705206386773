/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import axios from '../../../config/axiosMaquina';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import { ListagemCliente } from '../../../api/Types/listagemResumoTypes';
import { soNumeros } from '../../../util/Validacoes/Validacoes';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { dataConverter } from '../../../util/date';

function FormularioAvisosClientes() {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [clientes, setClientes] = useState<ListagemCliente[]>([]);
  const [mensagem, setMensagem] = useState('');
  const [titulo, setTitulo] = useState('');
  const [permanencia, setPermanencia] = useState<number>();
  const [ativo, setAtivo] = useState(true);
  const [disponivel, setDisponivel] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isNull, setIsNull] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        setListaClientes(response.data);
      });
    };
    loadData();
  }, []);

  const meusClientes = () => {
    // Certifique-se de que clientes não está vazio
    if (clientes.length === 0) {
      return null;
    }

    // Se clientes e listaClientes têm o mesmo tamanho, retornar todos os IDs de clientes
    if (clientes.length === listaClientes.length) {
      return listaClientes.map((each: any) => each.id);
    }

    // Retornar IDs dos clientes
    const result = clientes.map((each: any) => each.id);
    return result;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    let temp: any = meusClientes();

    if (permanencia && permanencia <= 0) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Dias de permanência deve ser maior que zero',
      });
      return;
    }

    const dados = {
      titulo,
      mensagem,
      disponivel: dataConverter(new Date(disponivel).toISOString()),
      clientes: temp,
      permanencia,
      ativo,
    };

    setLoading(true);
    console.log(dados);
    console.log(temp);

    axios
      .post('/aviso-cliente/cadastrar', dados)
      .then(() => {
        setIsDialogCadOK(true);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      });
  };

  const validaCampoNull = (event: any) => {
    event.preventDefault();
    if (event.target.value.length > 0) {
      setMensagem(event.target.value);
      setIsNull(false);
    }
    setMensagem(event.target.value);
    setIsNull(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row', minWidth: 667 }}>
      <MenuLateral />

      <Dialog
        header="Aviso cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/avisos-clientes');
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Aviso Clientes</h2>
          <h6 className="subtitulo">{'Avisos > Aviso Clientes > Cadastro'}</h6>
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <Form validated={isNull} onSubmit={handleSubmit}>
            <h6 style={{ color: 'red', fontSize: 12 }}>* Campos Obrigatórios</h6>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label>EMPRESAS</Form.Label>

                <MultiSelect
                  style={{
                    width: '100%',
                  }}
                  value={clientes}
                  selectedItemsLabel={`${clientes[0]?.razaoSocial},${clientes[1]?.razaoSocial}...`}
                  options={listaClientes}
                  display="chip"
                  placeholder="Selecione"
                  onChange={(event: any) => {
                    setClientes(event.target.value);
                  }}
                  optionLabel="razaoSocial"
                  filter
                  className="multiselect-custom"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">TÍTULO</Form.Label>
                <Form.Control
                  value={titulo}
                  name="titulo"
                  type="text"
                  required
                  title="Campo Obrigatório"
                  onChange={(event: any) => setTitulo(event.target.value)}
                  maxLength={64}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">MENSAGEM</Form.Label>
                <Form.Control
                  value={mensagem}
                  onChange={validaCampoNull}
                  id="mensagem"
                  required
                  as="textarea"
                  title="Campo Obrigatório"
                  rows={3}
                  maxLength={500}
                />
                <Form.Control.Feedback type="invalid">
                  Campo mensagem não pode ser nulo.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">DISPONIBILIZAR Á PARTIR DE</Form.Label>
                <Form.Control
                  onChange={(event: any) => setDisponivel(event.target.value)}
                  id="disponivel"
                  name="disponivel"
                  title="Se não selecionada uma data, será definida como padrão a data de hoje."
                  type="date"
                />
              </Form.Group>
              <Form.Group className="row-modal">
                <Form.Label className="requiredField">DIAS DE PERMANÊNCIA</Form.Label>
                <Form.Control
                  value={permanencia}
                  id="diasPermanencia"
                  name="diasPermanencia"
                  type="text"
                  maxLength={3}
                  required
                  isInvalid={permanencia && permanencia <= 0 ? true : false}
                  title="Campo Obrigatório"
                  onChange={(event: any) => setPermanencia(soNumeros(event.target.value))}
                />
                <Form.Control.Feedback type="invalid">
                  Campo não pode ser igual a 0.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <ButtonsForm />
          </Form>
        )}
      </div>
    </div>
  );
}

export default FormularioAvisosClientes;
