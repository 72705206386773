/* eslint-disable */

import { Button } from 'primereact/button';
import ReactExport from 'react-data-export';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Col, Form, Spinner } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import orderBy from 'lodash/orderBy';
import { cpfMask, maskCep, maskCpnj } from '../../util/Validacoes/Validacoes';
import axios from '../../config/axiosMaquina';
import Pagination from '../../components/Pagination/Pagination';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import EmbarcadorEdit from './EmbarcadorEdit';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { EmbarcadorViewNew } from './novo-fluxo-embarcador/EmbarcadorViewNew';
import EmbarcadorEditNew from './novo-fluxo-embarcador/EmbarcadorEditNew';
import { CorretorasType, SeguradorasType } from '../../api/model/UsuariosType';

export type EmbarcadorType = {
  cnpj: string;
  enderecoBairro: string;
  enderecoCep: string;
  enderecoCidade: string;
  enderecoComplemento: string;
  enderecoLogradouro: string;
  enderecoNumero: string;
  idEmbarcador: number;
  seguradoraId: number;
  corretoraId: number;
  razaoSocial: string;
  statusAtivo: boolean;
  telefone: string;
  telefoneDois: string;
  telefoneTres: string;
  contato: string;
  contatoDois: string;
  contatoTres: string;
  uf?: number;
  idCliente: number;
  razaoSocialCliente: string;
  corretoraNome?: string;
  seguradoraNome?: string;
};

export type EmbarcadorResumoType = {
  idEmbarcador: number;
  uf: number;
  cep: string;
  cidade: string;
  cnpj: string;
  razaoSocial: string;
};

export type RazaoSocialType = {
  id: number;
  razaoSocial: string;
};

export default function EmbarcadorGrid() {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const [data, setData] = useState<EmbarcadorResumoType[]>();
  const [dataAdmin, setDataAdmin] = useState<EmbarcadorType[]>();
  const [searchData, setSearchData] = useState<EmbarcadorResumoType[]>();
  const [listaEmbarcadorByCliente, setListaEmbarcadorByCliente] =
    useState<EmbarcadorResumoType[]>();
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [idEdit, setIdEdit] = useState(0);

  const history = useHistory();
  const [filtroAtivo, setFiltroAtivo] = useState(false);
  const [cnpj, setCnpj] = useState('');
  const [corretoraId, setCorretoraId] = useState<null | number>(null);
  const [seguradoraId, setSeguradoraId] = useState<null | number>(null);
  const [razaoSocial, setRazaoSocial] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [nomeEmpresa, setNomeEmpresa] = useState<string | undefined>();
  const [nomeEmbarcador, setNomeEmbarcador] = useState<string | undefined>();
  const [filters, setFilters] = useState({
    cnpj: '',
    razaoSocialCliente: '',
    razaoSocial: '',
    corretoraId: null,
    seguradoraId: null,
  });

  const { page }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditNew, setShowEditNew] = useState(false);
  const [showView, setShowView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [totalRegistrosSearch, setTotalRegistrosSearch] = useState(0);
  const [corretoras, setCorretoras] = useState<CorretorasType[]>([]);
  const [seguradoras, setSeguradoras] = useState<SeguradorasType[]>([]);

  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentEmbarcadorUser = searchTerm
    ? searchData?.slice(indexOfFirstPost, indexOfLastPost)
    : data?.slice(indexOfFirstPost, indexOfLastPost);

  const currentEmbarcadorAdmin = searchTerm
    ? searchData?.slice(indexOfFirstPost, indexOfLastPost)
    : dataAdmin?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/admin/listar/embarcadores/${pageNumber}`);
  };

  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  const location: {
    state: {
      nome: string;
    };
  } = useLocation();
  const { state } = location;

  useEffect(() => {
    const loadCorretoras = async () => {
      try {
        const response = await axios.get(`/corretora`);
        setCorretoras(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };

    const loadSeguradoras = async () => {
      try {
        const response = await axios.get(`/seguradora`);
        setSeguradoras(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    loadCorretoras();
    loadSeguradoras();
  }, []);

  useEffect(() => {
    getData(filters);
  }, [filters.razaoSocialCliente]);

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE],
          ADMIN: [ADMIN.CADASTRO.EMBARCADOR],
        },
      ],
      'inserir'
    );
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE],
          ADMIN: [ADMIN.CADASTRO.EMBARCADOR],
        },
      ],
      'editar'
    );
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.REGRAS_DE_APOLICE],
          ADMIN: [ADMIN.CADASTRO.EMBARCADOR],
        },
      ],
      'excluir'
    );
  }, [TRANSPORTADOR, ADMIN, rolePermission]);

  useEffect(() => {
    if (!dataAdmin) {
      return;
    }
    setExcelGeneration([
      {
        xSteps: 1,
        ySteps: 0,
        columns: [
          {
            title: 'Embarcadores ',
            widthPx: 1060,
            style: { font: { sz: '24', bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          { title: 'Empresa', width: { wch: 30 } },
          { title: 'CPF / CNPJ', width: { wch: 20 } },
          { title: 'Nome / Razão Social Embarcador', width: { wch: 30 } },
          { title: 'Corretora', width: { wch: 20 } },
          { title: 'Seguradora', width: { wch: 20 } },
        ],
        data: dataAdmin.map((each) => [
          { value: each.razaoSocialCliente },
          { value: each.cnpj.length === 11 ? cpfMask(each.cnpj) : maskCpnj(each.cnpj) },
          { value: each.razaoSocial ? each.razaoSocial : '' },
          { value: each.corretoraNome ? each.corretoraNome : '' },
          { value: each.seguradoraNome ? each.seguradoraNome : '' },
        ]),
      },
    ]);
  }, [dataAdmin]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAllowedByRole(['admin']) && empresa) {
        try {
          const response = await axios.get(`/embarcador/grid-Embarcador-Cliente`, {
            params: {
              idCliente: empresa,
            },
          });
          console.log(nomeEmpresa);

          setListaEmbarcadorByCliente(orderBy(response.data, 'razaoSocial'));
          setTotalRegistros(response.data.length);
        } catch (err: any) {
          console.log(err);
        }
      } else if (idCliente) {
        setLoading(true);
        try {
          const response = await axios.get(
            `/embarcador/grid-Embarcador-Cliente?idCliente=${idCliente}`
          );
          console.log(idCliente);

          if (!totalRegistros) {
            const count = await axios.get(
              `/embarcador/count-Grid-Embarcador-Cliente?idCliente=${idCliente}`
            );
            console.log(idCliente);

            setTotalRegistros(count.data);
          }
          setTotalRegistros(response.data.length);
          setData(response.data);
          setLoading(false);
        } catch (err: any) {
          console.log(err);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [idCliente, itensPerPage, page, empresa]);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchTerm) {
        history.push(`/admin/listar/embarcadores/${page}`);
        return;
      }
      if (isAllowedByRole(['admin'])) {
        setLoading(true);
        try {
          const response = await axios.get(
            `/embarcador/search-Embarcador-Cliente?idCliente=${empresa}&keyword=${searchTerm}`
          );
          setSearchData(response.data);
          setTotalRegistrosSearch(response.data.length);
          setLoading(false);
        } catch (err: any) {
          console.log(err);
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const response = await axios.get(
            `/embarcador/search-Embarcador-Cliente?idCliente=${idCliente}&keyword=${searchTerm}`
          );
          setSearchData(response.data);
          setTotalRegistrosSearch(response.data.length);
          setLoading(false);
        } catch (err: any) {
          console.log(err);
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [page, itensPerPage, idCliente, searchTerm, empresa]);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
      });
    };
    loadData();
  }, []); // LISTA DE CLIENTES COMBO EMPRESA

  function nomeClienteSelecionado(empresa: number) {
    const getNome = listaClientes
      .filter((fil) => fil.id === empresa)
      .find((each) => each.razaoSocial);
    setNomeEmpresa(getNome?.razaoSocial);
  }

  function nomeEmbarcadorSelecionado(embarcador: number) {
    setNomeEmbarcador(
      listaEmbarcadorByCliente?.find((fil: any) => fil.idEmbarcador === embarcador)?.razaoSocial
    );
  }

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]); // PAGES

  interface IFilters {
    cnpj: string;
    razaoSocialCliente: string;
    razaoSocial: string;
    corretoraId: number | null;
    seguradoraId: number | null;
  }

  function getData(filters: IFilters) {
    const fetchData = async () => {
      setLoading(true);

      const data = {
        ...filters,
        razaoSocialCliente: nomeEmpresa,
        razaoSocial: nomeEmbarcador,
      };
      try {
        const filteredParams = Object.entries(data)
          .filter(([_, value]) => value !== undefined && value !== null && value !== '')
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {} as Record<string, any>);

        const response = await axios.get('/embarcador/search-embarcador', {
          params: filteredParams,
        });

        setDataAdmin(response.data);
        console.log(response.data);
      } catch (err: any) {
        console.error('Erro ao buscar dados:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }

  function handleNavigateToEdit(id: number) {
    setShowEdit(true);
    setIdEdit(id);
  }

  function handleNavigateToEdit2(id: number) {
    setShowEditNew(true);
    setIdEdit(id);
  }

  function handleNavigateToView(id: number) {
    setShowView(true);
    setIdEdit(id);
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  function onSubmit() {
    console.log('submit form');
  }

  return (
    <>
      {showEdit ? (
        <EmbarcadorEditNew
          id={idEdit}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          canEdit={canEdit}
          canDelete={canDelete}
          empresa={isAllowedByRole(['admin']) && empresa}
        />
      ) : showView ? (
        <EmbarcadorViewNew
          canEdit={canEdit}
          canInsert={canInsert}
          id={idEdit}
          showEdit={showView}
          setShowView={setShowView}
        />
      ) : showEditNew ? (
        <EmbarcadorViewNew
          canEdit={canEdit}
          canInsert={canInsert}
          id={idEdit}
          showEdit={showEditNew}
          setShowView={setShowEditNew}
        />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <MenuLateral />
          <div className="card">
            <div className="titulo-container">
              <h2 className="titulo">Regras de Apólice</h2>
              {isAllowedByRole(['user']) && (
                <h6 className="subtitulo">{'Cadastro > Regras de Apólice'}</h6>
              )}
              {isAllowedByRole(['admin']) && (
                <h6 className="subtitulo">
                  {'Cadastro > Gerenciamento de Clientes > Regras de Apólice'}
                </h6>
              )}
            </div>

            {isAllowedByRole(['admin']) && (
              <div className="painel">
                <Form
                  onSubmit={onSubmit}
                  style={{
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  <Form.Row
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '-5px',
                    }}
                  >
                    {/* Lista de Clientes */}
                    <Form.Group as={Col} controlId="formGridEmpresa">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Empresa</Form.Label>
                        <Dropdown
                          value={filters.razaoSocialCliente}
                          options={listaClientes}
                          onChange={(e: any) => {
                            nomeClienteSelecionado(e.target.value);
                            setEmpresa(e.target.value);
                            setFilters({
                              ...filters,
                              razaoSocialCliente: e.target.value,
                            });
                          }}
                          optionLabel="razaoSocial"
                          optionValue="id"
                          filter
                          filterBy="razaoSocial"
                          placeholder="Selecione"
                          className="p-dropdown"
                          showClear
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>
                    </Form.Group>
                    {/* Lista de Embarcadores do cliente Selecionado */}
                    <Form.Group as={Col} controlId="formGridRazaoSocial">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>Razão Social</Form.Label>
                        <Dropdown
                          value={filters.razaoSocial}
                          options={listaEmbarcadorByCliente}
                          onChange={(e: any) => {
                            setRazaoSocial(e.target.value);
                            nomeEmbarcadorSelecionado(e.target.value);
                            setFilters({
                              ...filters,
                              razaoSocial: e.target.value,
                            });
                          }}
                          optionLabel="razaoSocial"
                          optionValue="idEmbarcador"
                          filter
                          filterBy="razaoSocial"
                          placeholder="Selecione"
                          className="p-dropdown"
                          showClear
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridCnpj">
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label>CNPJ Nº</Form.Label>
                        <Dropdown
                          value={filters.cnpj}
                          options={listaEmbarcadorByCliente}
                          onChange={(e: any) => {
                            setCnpj(e.target.value);
                            setFilters({
                              ...filters,
                              cnpj: e.target.value,
                            });
                          }}
                          optionLabel="cnpj"
                          optionValue="cnpj"
                          filter
                          filterBy="cnpj"
                          placeholder="Selecione"
                          className="p-dropdown"
                          showClear
                          style={{
                            width: '100%',
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Form.Row>

                  {!empresa ? null : (
                    <div className="search-create mb-4 ml-2" style={{ marginTop: '22px' }}>
                      <span className="p-input-icon-left">
                        <input
                          type="text"
                          placeholder="Pesquise"
                          className="p-inputtext p-component"
                          id="search-input"
                          style={{ width: '240px' }}
                          value={searchTerm}
                          onChange={onSearchChange}
                        />
                        <i className="pi pi-search" />
                      </span>
                    </div>
                  )}
                </Form>
                <Form.Row
                  style={{
                    width: '52%',
                  }}
                >
                  <Form.Group as={Col}>
                    <Form.Label>Corretora</Form.Label>

                    <Form.Control
                      value={filters.corretoraId ? filters.corretoraId : ''}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          corretoraId: e.target.value,
                        });
                      }}
                      id="corretoraId"
                      name="corretoraId"
                      as="select"
                      style={{ width: '100%' }}
                    >
                      <option value="">Selecione</option>
                      {corretoras.map((corretora) => (
                        <option key={corretora.corCdId} value={corretora.corCdId}>
                          {corretora.corTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Seguradora</Form.Label>

                    <Form.Control
                      value={filters.seguradoraId ? filters.seguradoraId : ''}
                      onChange={(e: any) => {
                        setFilters({
                          ...filters,
                          seguradoraId: e.target.value,
                        });
                      }}
                      id="seguradoraId"
                      name="seguradoraId"
                      placeholder=""
                      as="select"
                      style={{ width: '100%' }}
                    >
                      <option value="">Selecione</option>
                      {seguradoras.map((seguradora) => (
                        <option key={seguradora.segCdId} value={seguradora.segCdId}>
                          {seguradora.segTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={2} controlId="formGridButton">
                    <Button
                      className="btn-enviar"
                      style={{ height: '50%', display: 'flex', marginTop: '30px' }}
                      type="button"
                      onClick={() => {
                        setFiltroAtivo(true);
                        getData(filters);
                      }}
                    >
                      Filtrar
                    </Button>
                  </Form.Group>
                </Form.Row>
              </div>
            )}

            {isAllowedByRole(['user']) && (
              <div className="search-create mb-4">
                {canInsert ? (
                  <Link to="/embarcador/add" className="btn-create">
                    <i
                      style={{
                        fontSize: '1em',
                        color: '#000',
                        textDecoration: 'none',
                      }}
                      className="pi pi-plus"
                    />
                  </Link>
                ) : (
                  <Link to="/acessonegado" className="btn-create">
                    <i
                      style={{
                        fontSize: '1em',
                        color: '#000',
                        textDecoration: 'none',
                      }}
                      className="pi pi-plus"
                    />
                  </Link>
                )}
                <span className="p-input-icon-left">
                  <input
                    type="text"
                    placeholder="Pesquise"
                    className="p-inputtext p-component"
                    id="search-input"
                    style={{ width: '240px' }}
                    value={searchTerm}
                    onChange={onSearchChange}
                  />
                  <i className="pi pi-search" />
                </span>
              </div>
            )}

            <div className="painel">
              <div className="table-responsive" style={{ flex: 1 }}>
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="warning"
                    style={{
                      display: 'flex',
                      marginLeft: '47.5%',
                      marginTop: '5%',
                      marginBottom: '5%',
                    }}
                  />
                ) : isAllowedByRole(['user']) ? (
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        <th>Código</th>
                        <th>CNPJ</th>
                        <th>Razão Social </th>
                        <th>CEP</th>
                        <th>Cidade</th>
                        <th>UF</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentEmbarcadorUser &&
                        currentEmbarcadorUser.map((each, index) => (
                          <tr key={index}>
                            <td>{each.idEmbarcador}</td>
                            {isAllowedByRole(['admin']) && <td>{each.razaoSocial}</td>}
                            <td>{each.cnpj ? maskCpnj(each.cnpj) : ''}</td>
                            <td>{each.razaoSocial}</td>
                            <td>{each.cep ? maskCep(each.cep) : ''}</td>
                            <td>{each.cidade}</td>
                            <td>{each.uf}</td>
                            <td>
                              <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="p-button p-button-info p-button-text p-button-sm"
                                onClick={() => handleNavigateToEdit(each.idEmbarcador)}
                              >
                                <i
                                  style={{ color: '#000' }}
                                  className="pi pi-pencil"
                                  title="Editar Regras de Apólice"
                                />
                              </Button>
                              <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="p-button p-button-info p-button-text p-button-sm"
                                onClick={() => handleNavigateToView(each.idEmbarcador)}
                              >
                                <i
                                  style={{ color: '#000' }}
                                  className="pi pi-eye"
                                  title="Visualizar Limites de Transporte"
                                />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <table className="table">
                    <thead className="thead">
                      <tr>
                        <th>Código</th>
                        <th>Empresa</th>
                        <th>CNPJ</th>
                        <th>Razão Social Embarcador</th>
                        <th>Corretora</th>
                        <th>Seguradora</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentEmbarcadorAdmin &&
                        currentEmbarcadorAdmin.map((each: any, index: any) => (
                          <tr key={index}>
                            <td>{each.idEmbarcador ? each.idEmbarcador : 'null'}</td>
                            <td>{nomeEmpresa || each.razaoSocial}</td>
                            <td>{each.cnpj ? maskCpnj(each.cnpj) : ''}</td>
                            <td>{each.razaoSocial}</td>
                            <td>{each.corretoraNome}</td>
                            <td>{each.seguradoraNome}</td>
                            <td style={{ width: 190 }}>
                              <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="p-button p-button-info p-button-text p-button-sm"
                                onClick={() => handleNavigateToEdit(each.idEmbarcador)}
                              >
                                <i
                                  style={{ color: '#000' }}
                                  className="pi pi-pencil"
                                  title="Editar Regras de Apólice"
                                />
                              </Button>
                              {/* <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="p-button p-button-info p-button-text p-button-sm"
                                onClick={() => handleNavigateToView(each.idEmbarcador)}
                              >
                                <i
                                  style={{ color: '#000' }}
                                  className="pi pi-book"
                                  title="Limites de Transporte"
                                />
                              </Button> */}

                              <Button
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                }}
                                className="p-button p-button-info p-button-text p-button-sm"
                                onClick={() => handleNavigateToEdit2(each.idEmbarcador)}
                              >
                                <i
                                  style={{ color: '#000' }}
                                  className="pi pi-book"
                                  title="Limites de Transporte"
                                />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {dataAdmin && (
                  <div id="gerar-xls">
                    <ExcelFile element={<Button>Exportar para XLS</Button>} filename="Embarcador">
                      <ExcelSheet dataSet={excelGeneration} name="Embarcadores" />
                    </ExcelFile>
                  </div>
                )}
                {page && (
                  <Pagination
                    itensPerPage={itensPerPage}
                    setItensPerPage={setItensPerPage}
                    totalPosts={searchTerm ? totalRegistrosSearch : totalRegistros}
                    paginate={paginate}
                    currentPage={page ?? 0}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
